import { Rect } from "./rect";
import { Handle } from "./handle";
import { Point } from "./point";

/**
 * Cursors
 *    0 = 'nw-resize';
      1 = 'n-resize';
      2 = 'ne-resize';
      3 = 'w-resize';
      4 = 'e-resize';
      5 = 'sw-resize';
      6 = 's-resize';
      7 = 'se-resize';
      8 = 'move';
      default = 'auto'

    // 0  1  2
    // 3  8  4
    // 5  6  7
 */
export class SelectionHandle extends Handle {
  public point: Point;

  constructor(id: number, point: Point) {
    super(id);
    this.point = point;
  }

  public renderCursor(mouseDown: boolean) {
    switch (this.id) {
      case 0:
        return 'nw-resize';
      case 1:
        return 'n-resize';
      case 2:
        return 'ne-resize';
      case 3:
        return 'w-resize';
      case 4:
        return 'e-resize';
      case 5:
        return 'sw-resize';
      case 6:
        return 's-resize';
      case 7:
        return 'se-resize';
      case 8:
        return 'move';
      default:
        return 'auto';
    }
  }

  resize(x: number, y: number) {
    //console.log('resize', this);
  }

  public render(context: any, rect: Rect) {
    context.save();
    context.rect(this.point.x, this.point.y, (1 / rect.width) * 10, (1 / rect.height) * 10);
    context.fillStyle = "white";
    context.lineWidth = 1;
    context.fill();
    context.restore();
  }
}