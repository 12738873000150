const WIDTH = 10;
const SPACE = 5;
const BARHEIGHT = 50;

export class Progress {
  private offset: number = 0;
  private then: any = 0;

  constructor() { }

  public render(context: any) {
    if (this.then == undefined) {
      this.then = performance.now();
      this.offset = 0;
    }

    let now = performance.now();
    let delta = now - this.then;

    context.save();
    context.beginPath();
    context.fillStyle = 'rgba(255,255,255,0.75)';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.restore();

    context.save();
    context.beginPath();

    let top = context.canvas.height / 2;
    let left = (context.canvas.width / 2) - (SPACE * 9 + WIDTH * 9) / 2;

    context.fillStyle = "#007bff";
    context.fillRect(left + SPACE * 1 + WIDTH * 1, top, WIDTH, this.getHeight(0));
    context.fillRect(left + SPACE * 2 + WIDTH * 2, top, WIDTH, this.getHeight(1));
    context.fillRect(left + SPACE * 3 + WIDTH * 3, top, WIDTH, this.getHeight(2));
    context.fillRect(left + SPACE * 4 + WIDTH * 4, top, WIDTH, this.getHeight(3));
    context.fillRect(left + SPACE * 5 + WIDTH * 5, top, WIDTH, this.getHeight(4));
    context.fillRect(left + SPACE * 6 + WIDTH * 6, top, WIDTH, this.getHeight(5));
    context.fillRect(left + SPACE * 7 + WIDTH * 7, top, WIDTH, this.getHeight(6));
    context.fillRect(left + SPACE * 8 + WIDTH * 8, top, WIDTH, this.getHeight(7));
    context.fillRect(left + SPACE * 9 + WIDTH * 9, top, WIDTH, this.getHeight(8));

    context.font = "18px Arial";
    context.fillStyle = "#007bff";
    context.fillText("analyzing image", left + 15, top + 20);

    context.restore();

    if (delta > 20) {
      this.offset += 10;
      this.then = now;
    }
  }

  getHeight(index) {
    return -Math.abs(Math.cos(((this.offset + index * (BARHEIGHT / 5)) * Math.PI / 180)) * BARHEIGHT);
  }
}