import { MatSnackBar } from '@angular/material';
var SnackBarService = /** @class */ (function () {
    function SnackBarService(snackBar) {
        this.snackBar = snackBar;
    }
    SnackBarService.prototype.show = function (message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.snackBar.open(message, args[0], args[1]);
    };
    return SnackBarService;
}());
export { SnackBarService };
