<app-header></app-header>
<div *ngIf="hasCollections(); else noCollections" class="container" style="max-width: 1600px; padding: 25px">
  <h3>Board view</h3>
  <h6>Select a board to start tagging</h6>
  <div *ngFor="let item of operationContext.groupedCollections | keyvalue" class="col-md-3" style="margin-top: 25px;">
    <mat-expansion-panel class="mat-elevation-z0 panel-taglist" (click)="togglePanel(item.key)"
      [expanded]="isExpanded(item.key)">
      <mat-expansion-panel-header style="height: 40px">
        <mat-panel-title>
          <div class="label-wrapper">
            <mat-icon>description</mat-icon>
            &nbsp;
            <h5>{{item.key}} [{{ getItemCount(item.key) }}]</h5>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div *ngFor="let collection of item.value" class="col-md-3" style="margin-top: 25px;">
          <div class="card" (click)="select(collection)">
            <mat-icon *ngIf="collection?.state == 3" class="status-icon gray-icon" title="State not updated">
              brightness_1
            </mat-icon>
            <mat-icon *ngIf="collection?.state == 2" class="status-icon green-icon" title="All images are completed">
              brightness_1</mat-icon>
            <mat-icon *ngIf="collection?.state == 1" class="status-icon yellow-icon"
              title="Not all images are completed">
              brightness_1</mat-icon>
            <mat-icon *ngIf="collection?.state == 0" class="status-icon red-icon" title="No images are tagged yet">
              brightness_1</mat-icon>
            <div class="card-img-top2">
              <div class="inspirationImage" [ngStyle]="{'background-image': 'url(' + getImage(collection) + ')'}"></div>
            </div>
            <div class="card-body">
              <h5>
                {{collection?.name}}
              </h5>
              <div class="body-text">
                <p>
                  {{collection?.description}}
                </p>
                <span *ngIf="collection.id != -1">
                  {{collection?.collectionCount}} images in board
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>

<ng-template #noCollections>
  <div *ngIf="collectionsLoaded()" class="collections-center">
    <h4>
      No collections found
    </h4>
  </div>
</ng-template>