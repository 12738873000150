export class Context {
  private $drawingCanvas = null;
  private $drawingContext = null;

  constructor(width: number, height: number, canvas?: HTMLCanvasElement) {
    if (canvas) {
      this.$drawingCanvas = canvas;
    } else {
      this.$drawingCanvas = document.createElement('canvas');
    }

    this.$drawingContext = this.$drawingCanvas.getContext("2d");
    this.$drawingCanvas.width = width;
    this.$drawingCanvas.height = height;
  }

  get context(): CanvasRenderingContext2D {
    return this.$drawingContext;
  }

  get canvas(): HTMLCanvasElement {
    return this.$drawingCanvas;
  }
}