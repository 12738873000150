import { OnInit } from '@angular/core';
import { ImageItem } from '../../models/image.item';
import { Router, ActivatedRoute } from '@angular/router';
import { OperationContext } from '../../services/operation.context';
import { DataService } from '../../services/data.service';
var SearchResultComponent = /** @class */ (function () {
    function SearchResultComponent(router, operationContext, route, dataService) {
        this.router = router;
        this.operationContext = operationContext;
        this.route = route;
        this.dataService = dataService;
        this.result = [];
        this.$imagesLoaded = false;
    }
    SearchResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.result = [];
            _this.$imagesLoaded = false;
            var previousQuery = sessionStorage.getItem('query');
            _this.dataService.searchKeyword(params.query || previousQuery)
                .then(function (result) {
                for (var i = 0; i < result.length; i++) {
                    var imageItem = ImageItem.create(result[i]);
                    _this.result.push(imageItem);
                }
                _this.$imagesLoaded = true;
            });
        });
    };
    SearchResultComponent.prototype.select = function (image) {
        this.operationContext.clearSelection();
        this.operationContext.selectedImage = image;
        sessionStorage.removeItem("collection");
        this.operationContext.selectedCollection = null;
        this.router.navigate(['tagging']);
    };
    SearchResultComponent.prototype.getImage = function (image) {
        return image.thumbnail;
    };
    SearchResultComponent.prototype.getRatio = function (image) {
        return (image.width / image.height).toFixed(2);
    };
    Object.defineProperty(SearchResultComponent.prototype, "hasImages", {
        get: function () {
            return this.result && this.result.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResultComponent.prototype, "imagesLoaded", {
        get: function () {
            return this.$imagesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    return SearchResultComponent;
}());
export { SearchResultComponent };
