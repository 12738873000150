var ProductItem = /** @class */ (function () {
    function ProductItem(id, name, description, descriptionEN, imageUrl, score, selected, type) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.descriptionEN = descriptionEN;
        this.imageUrl = imageUrl;
        this.score = score;
        this.selected = selected;
        this.type = type;
    }
    ProductItem.create = function (data) {
        var product = Object.create(ProductItem.prototype);
        Object.assign(product, {
            name: data.name,
            description: data.description,
            descriptionEN: data.descriptionEN || false,
            id: data.id,
            imageUrl: data.imageUrl,
            score: data.score || 0,
            selected: data.selected || false,
            type: data.type || "main",
        });
        return product;
    };
    ProductItem.prototype.clone = function () {
        var product = Object.create(ProductItem.prototype);
        Object.assign(product, {
            name: this.name,
            description: this.description,
            descriptionEN: this.descriptionEN,
            id: this.id,
            imageUrl: this.imageUrl,
            score: this.score || 0,
            selected: this.selected || false,
            type: this.type || "main",
        });
        return product;
    };
    ProductItem.prototype.toJSON = function () {
        return this.id.toString();
    };
    Object.defineProperty(ProductItem.prototype, "thumbnail", {
        get: function () {
            if (!this.imageUrl) {
                return "/assets/na.png";
            }
            return this.imageUrl;
        },
        enumerable: true,
        configurable: true
    });
    ProductItem.prototype.toString = function () {
        if (this.name && this.id) {
            return this.name + ", " + this.id;
        }
        return this.id;
    };
    return ProductItem;
}());
export { ProductItem };
