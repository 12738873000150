import { Box } from "./box";
import { Dot } from "./dot";
import { Rect } from "./rect";
import { Point } from "./point";
import { ProductItem } from "../../models/product.item";
import { Helpers } from "../../helpers/common";

export class TagItem {
  public $isModified: boolean;
  public $isSelected: boolean;
  public $isVisible: boolean;
  public prd: ProductItem;
  public thumbnail: string;
  public box: Box;
  public dot: Dot;
  public identifiedProducts: ProductItem[];
  private $callback;

  constructor(args: any) {
    this.box = new Box(
      new Rect(args.x, args.y, args.width, args.height),
      args.color,
      this
    );
    this.dot = new Dot(
      new Point(args.x + args.width, args.y + args.height),
      this
    );
    this.$isModified = false;
    this.$isSelected = false;
    this.$isVisible = true;
    this.prd = new ProductItem("", "", "", "", "", 0, false);
    this.thumbnail = "";
    this.$callback = null;
    this.identifiedProducts = [];
  }

  get isVisible(): boolean {
    return this.$isVisible;
  }

  set isVisible(value: boolean) {
    this.$isVisible = value;
  }

  get isModified(): boolean {
    return this.$isModified;
  }

  set isModified(value: boolean) {
    this.$isModified = value;
  }

  get isSelected(): boolean {
    return this.$isSelected;
  }

  set isSelected(value: boolean) {
    this.$isSelected = value;
  }

  focus(callback) {
    if (callback === undefined) {
      if (this.$callback) {
        this.$callback();
      }

      return;
    }

    this.$callback = callback;
  }

  public update(left: number, top: number, width: number, height: number) {
    this.box.updateDimension(left, top, width, height);
    this.dot.updatePosition(left + width / 2, top + height / 2);
  }

  public move(x: number, y: number) {
    this.dot.move(x, y);
    this.box.move(x, y);
  }

  public boundingBox(): Rect {
    return this.box.boundingBox();
  }

  public render(context: any, renderBox: boolean) {
    if (renderBox) {
      this.box.render(context);
    }

    if (this.boundingBox().width >= 20 || this.boundingBox().height >= 20) {
      this.dot.render(context);
    }
  }

  // toJSON is automatically used by JSON.stringify
  public toJSON(): TagItemJSON {
    return {
      box: this.box.toJSON(),
      dot: this.dot.toJSON(),
      prd: this.prd.toJSON(),
      t: this.prd.type === "prop" ? "p" : "m",
    };
  }

  // fromJSON is used to convert an serialized version
  // of the TagItem to an instance of the class
  static fromJSON(json: TagItemJSON[]): TagItem[] {
    // create an instance of the TagItem class
    let items = [];

    for (let i = 0; i < json.length; i++) {
      let tagItem: TagItem = TagItem.create(json[i]);
      items.push(tagItem);
    }

    return items;
  }

  clone(): TagItem {
    let clone: TagItem = Object.create(TagItem.prototype);
    let box: Box = Object.create(Box.prototype);
    let dot: Dot = Object.create(Dot.prototype);
    let prd: ProductItem = Object.create(ProductItem.prototype);

    let rect = new Rect(
      this.box.boundingBox().left,
      this.box.boundingBox().top,
      this.box.boundingBox().width,
      this.box.boundingBox().height
    );

    let point = new Point(this.dot.location.x, this.dot.location.y);

    Object.assign(box, {
      rect: rect,
      color: this.box.getColor(),
      parentItem: this,
    });

    Object.assign(dot, {
      point: point,
      parentItem: clone,
    });

    Object.assign(clone, {
      prd: Object.assign(prd, {
        id: this.prd.id,
        name: this.prd.name,
        description: this.prd.description,
        imageUrl: this.prd.imageUrl,
        score: this.prd.score,
        selected: this.prd.selected,
        type: this.prd.type,
      }),
      isSelected: this.$isSelected,
      isVisible: this.$isVisible,
      isModified: this.$isModified,
      thumbnail: this.thumbnail,
      box: box,
      dot: dot,
    });

    return clone;
  }

  static create(data): TagItem {
    let tagItem: TagItem = Object.create(TagItem.prototype);
    let box: Box = Object.create(Box.prototype);
    let dot: Dot = Object.create(Dot.prototype);
    let prd: ProductItem = Object.create(ProductItem.prototype);
    let color = Helpers.getColor();

    let rect = new Rect(
      parseFloat(data.box.l),
      parseFloat(data.box.t),
      parseFloat(data.box.w),
      parseFloat(data.box.h)
    );

    let point = new Point(parseFloat(data.dot.x), parseFloat(data.dot.y));

    Object.assign(box, {
      rect: rect,
      color: color,
      parentItem: tagItem,
    });

    Object.assign(dot, {
      point: point,
      parentItem: tagItem,
    });

    Object.assign(tagItem, {
      prd: Object.assign(prd, {
        id: data.prd === undefined ? "" : data.prd,
        name: "",
        description: "",
        imageUrl: "/assets/na.png",
        score: data.score || 0,
        selected: data.selected || false,
      }),
      isSelected: false,
      isModified: false,
      isVisible: true,
      thumbnail: "",
      box: box,
      dot: dot,
      identifiedProducts: data.identifiedProducts || [],
    });

    return tagItem;
  }
}
