var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, AfterViewInit, SecurityContext, ElementRef, } from "@angular/core";
import { OperationContext } from "../../services/operation.context";
import { TagItem } from "../../models/renderable/tag.item";
import { ProductItem } from "../../models/product.item";
import { FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { debounceTime } from "rxjs/operators";
import { DataService } from "../../services/data.service";
var ITEM_HEIGHT = 111;
var MAX_HEIGHT = ITEM_HEIGHT * 3;
var HEADER_HEIGHT = 30;
var PADDING = 5;
var KEY_SPACE = 32;
var KEY_UP = 38;
var KEY_DOWN = 40;
var sleep = function (time) { return new Promise(function (resolve) { return setTimeout(resolve, time); }); };
var ɵ0 = sleep;
var SearchComponent = /** @class */ (function () {
    function SearchComponent(operationContext, sanitizer, dataService) {
        this.operationContext = operationContext;
        this.sanitizer = sanitizer;
        this.dataService = dataService;
        this.suggestions = [];
        this.init = false;
        this.isOpen = false;
        this.width = 0;
        this.wasInside = false;
        this.title = "Suggested products";
        this.isLoading = false;
    }
    SearchComponent.prototype.onResize = function (event) {
        this.resize();
    };
    SearchComponent.prototype.clickInside = function ($event) {
        this.wasInside = true;
    };
    SearchComponent.prototype.clickout = function () {
        if (!this.wasInside) {
            this.isOpen = false;
        }
        this.wasInside = false;
    };
    SearchComponent.prototype.handleKeydown = function (event) {
        if (!this.isOpen) {
            return;
        }
        switch (event.keyCode) {
            case KEY_SPACE: {
                break;
            }
            case KEY_UP: {
                break;
            }
            case KEY_DOWN: {
                break;
            }
        }
    };
    SearchComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.inputController = new FormControl(this.tagItem.prd);
                        this.isLoading = true;
                        this.inputController.setValue(this.tagItem.prd);
                        this.suggestions = this.clone(this.options);
                        this.inputController.valueChanges
                            .pipe(debounceTime(500))
                            .subscribe(function (data) {
                            if (!data) {
                                _this.tagItem.prd = null;
                            }
                            _this.getProducts(data);
                        });
                        if (!this.operationContext.isAIEnabled) return [3 /*break*/, 5];
                        return [4 /*yield*/, sleep(1000)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.operationContext.selectedImage.old) return [3 /*break*/, 4];
                        console.log("Awaiting new image loading");
                        return [4 /*yield*/, sleep(500)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 2];
                    case 4:
                        this.identifyTag(this.operationContext.selectedImage.thumbnail, this.tagItem, this.operationContext.selectedImage["canvasWidth"], this.operationContext.selectedImage["canvasHeight"]).then(function (id) {
                            _this.lookup(id);
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        this.lookup(this.tagItem.prd.id);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    SearchComponent.prototype.ngAfterViewInit = function () {
        this.resize();
        this.init = true;
        if (this.tagItem.isModified) {
            this.focus();
        }
    };
    SearchComponent.prototype.clone = function (productItems) {
        var newList = [];
        productItems.map(function (item) {
            newList.push(item.clone());
        });
        return newList;
    };
    SearchComponent.prototype.lookup = function (id) {
        var _this = this;
        this.isLoading = false;
        this.getProductDetailsPIA(id, function (result) { return __awaiter(_this, void 0, void 0, function () {
            var name, parts, resultItem_1, product, aIProduct;
            return __generator(this, function (_a) {
                name = this.operationContext.selectedImage.name;
                if (name.includes("-crop")) {
                    parts = name.split("-crop");
                    name = parts[0];
                }
                if (result.length > 0) {
                    resultItem_1 = result[0];
                    product = this.suggestions.find(function (productItem) {
                        return productItem.id == resultItem_1.id;
                    });
                    if (product !== undefined) {
                        product.selected = true;
                        this.tagItem.prd = product;
                    }
                    else {
                        aIProduct = this.tagItem.identifiedProducts.find(function (productItem) {
                            return productItem.id == resultItem_1.id;
                        });
                        if (aIProduct !== undefined) {
                            aIProduct.selected = true;
                            this.tagItem.prd = aIProduct;
                        }
                        else {
                            resultItem_1.selected = true;
                            this.tagItem.prd = resultItem_1;
                            this.tagItem.identifiedProducts.push(resultItem_1);
                        }
                    }
                }
                return [2 /*return*/];
            });
        }); });
    };
    SearchComponent.prototype.identifyTag = function (url, tag, width, height) {
        return __awaiter(this, void 0, void 0, function () {
            var rect, left, top, right, bottom, box;
            var _this = this;
            return __generator(this, function (_a) {
                rect = tag.box.boundingBox();
                left = rect.left - PADDING < 0 ? 0 : (rect.left - PADDING) / width;
                top = rect.top - PADDING < 0 ? 0 : (rect.top - PADDING) / height;
                right = rect.left + rect.width + PADDING * 2 > width
                    ? 1.0
                    : (rect.left + rect.width + PADDING * 2) / width;
                bottom = rect.top + rect.height + PADDING * 2 > height
                    ? 1.0
                    : (rect.top + rect.height + PADDING * 2) / height;
                box = {
                    left: left.toFixed(3),
                    top: top.toFixed(3),
                    right: right.toFixed(3),
                    bottom: bottom.toFixed(3),
                };
                return [2 /*return*/, this.dataService
                        .identify(url, [box])
                        .then(function (items) { return __awaiter(_this, void 0, void 0, function () {
                        var id, product, identifiedProducts;
                        var _this = this;
                        return __generator(this, function (_a) {
                            id = tag.prd.id;
                            if (!id) {
                                product = ProductItem.create({
                                    name: "PLEASE SELECT",
                                    description: "No selection has been made",
                                    id: "",
                                    imageUrl: "",
                                    score: 0,
                                    selected: true,
                                });
                                tag.prd = product;
                                id = tag.prd.id;
                            }
                            if (tag.identifiedProducts.length == 0) {
                                identifiedProducts = items[0].identifiedResult;
                                identifiedProducts.map(function (item) {
                                    var globalId = item.articleType.toLowerCase() + "," + item.itemNoGlobal;
                                    _this.getProductDetailsPIA(globalId, function (result) {
                                        if (result.length > 0) {
                                            var productItem = result[0];
                                            productItem.score = item.score;
                                            var suggestion = _this.suggestions.find(function (aiItem) {
                                                return aiItem.id == item.art;
                                            });
                                            if (suggestion) {
                                                suggestion.score = productItem.score;
                                            }
                                            _this.insertSorted(tag.identifiedProducts, productItem, function (a, b) {
                                                return b.score - a.score;
                                            });
                                        }
                                    });
                                });
                            }
                            return [2 /*return*/, id];
                        });
                    }); })
                        .catch(function (error) {
                        console.log(error);
                    })];
            });
        });
    };
    SearchComponent.prototype.getScore = function (distance) {
        var x = distance * 10;
        return Math.min(10, Math.max(Math.round(x / 0.5) * 0.5, 0));
    };
    SearchComponent.prototype.insertSorted = function (array, element, comparator) {
        for (var i = 0; i < array.length && comparator(array[i], element) < 0; i++) { }
        array.splice(i, 0, element);
    };
    SearchComponent.prototype.isSelected = function (option) {
        return option.selected;
    };
    SearchComponent.prototype.resize = function () {
        var _this = this;
        setTimeout(function () {
            _this.width = _this.input.nativeElement.offsetWidth;
        }, 0);
    };
    SearchComponent.prototype.getWidth = function () {
        return this.width;
    };
    SearchComponent.prototype.getHeight = function () {
        if (this.suggestions.length === 0 &&
            this.tagItem.identifiedProducts.length === 0) {
            return 0;
        }
        if (this.suggestions.length + this.tagItem.identifiedProducts.length > 3) {
            return HEADER_HEIGHT + MAX_HEIGHT;
        }
        return (HEADER_HEIGHT +
            ITEM_HEIGHT *
                (this.suggestions.length || this.tagItem.identifiedProducts.length) +
            2);
    };
    SearchComponent.prototype.getMarginTop = function () {
        if (this.formControl.nativeElement.offsetTop +
            this.getHeight() +
            HEADER_HEIGHT >
            window.innerHeight) {
            return -411;
        }
        return -15;
    };
    SearchComponent.prototype.search = function (param, callback) {
        if (callback === undefined) {
            return;
        }
        if (callback) {
            this.operationContext
                .searchSPR(param)
                .then(callback)
                .catch(function (exc) {
                console.log("searchSPR", exc);
            });
        }
    };
    SearchComponent.prototype.getProductDetailsPIA = function (param, callback) {
        var _this = this;
        if (callback === undefined) {
            return;
        }
        if (callback) {
            this.operationContext
                .searchPIA(param)
                .then(callback)
                .catch(function (exc) {
                console.log("searchPIA", exc);
                _this.isLoading = false;
            });
        }
    };
    SearchComponent.prototype.focus = function () {
        var _this = this;
        if (!this.init) {
            return;
        }
        setTimeout(function () {
            _this.input.nativeElement.focus({
                preventScroll: true,
            });
        }, 0);
    };
    SearchComponent.prototype.getProducts = function (text) {
        var _this = this;
        if (text == "") {
            this.tagItem.prd = new ProductItem("", "", "", "", "", 0, false);
            this.tagItem.isModified = true;
            var name_1 = this.operationContext.selectedImage.name;
            if (name_1.includes("-crop")) {
                var parts = name_1.split("-crop");
                name_1 = parts[0];
            }
            this.title = "Suggested products";
            this.suggestions = this.clone(this.options);
            return;
        }
        var sanitized = this.sanitizer.sanitize(SecurityContext.HTML, text);
        this.search(sanitized, function (result) {
            _this.title = "Search results";
            _this.suggestions = result;
            _this.isOpen = true;
        });
    };
    SearchComponent.prototype.getImage = function (product) {
        return product.thumbnail;
    };
    SearchComponent.prototype.select = function (item) {
        this.tagItem.prd.selected = false;
        this.tagItem.prd = item;
        this.tagItem.isModified = true;
        this.tagItem.prd.selected = true;
        this.isOpen = false;
    };
    SearchComponent.prototype.hasItems = function () {
        return ((this.suggestions && this.suggestions.length > 0) ||
            (this.tagItem.identifiedProducts &&
                this.tagItem.identifiedProducts.length > 0));
    };
    SearchComponent.prototype.toggle = function (event) {
        this.isOpen = !this.isOpen;
        var dropdown = event.currentTarget.querySelector(".dropdown-container");
        if (dropdown === undefined || !dropdown) {
            return;
        }
        var selected = this.suggestions.find(function (productItem) {
            return productItem.selected;
        });
        if (selected) {
            var idx = this.suggestions.indexOf(selected);
            dropdown.scrollTop = idx * ITEM_HEIGHT;
        }
        else {
            selected = this.tagItem.identifiedProducts.find(function (productItem) {
                return productItem.selected;
            });
            if (selected) {
                var idx = this.tagItem.identifiedProducts.indexOf(selected);
                dropdown.scrollTop =
                    this.options.length * ITEM_HEIGHT + HEADER_HEIGHT + idx * ITEM_HEIGHT;
            }
        }
    };
    return SearchComponent;
}());
export { SearchComponent };
export { ɵ0 };
