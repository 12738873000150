import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { OperationContext, State } from "../../services/operation.context";
import { CropItem } from "../../models/renderable/crop.item";
import { HittestInfo, Hittester } from "../../helpers/hittester";
import { CropSelection } from "../../models/renderable/crop.selection";
import { DefaultSelection } from "../../models/renderable/default.selection";
import { ResizeSelection } from "../../models/renderable/resize.selection";
import { Dot } from "../../models/renderable/dot";
import { TagItem } from "../../models/renderable/tag.item";
import { Handle } from "../../models/renderable/handle";
import { Box } from "../../models/renderable/box";
import { MemoryContext } from "../../helpers/memory.context";
import { Context } from "../../helpers/context";
import { DrawingContext } from "../../helpers/drawing.context";
import { ImageItem } from "../../models/image.item";
import { DrawSelection } from "../../models/renderable/draw.selection";
import { Helpers } from "../../helpers/common";
import { ErrorMessage } from "../../models/error.message";
import { ImageHelper } from "../../helpers/image.helper";
import { DataService } from "../../services/data.service";
import { Progress } from "../../models/renderable/louise2.progress";
import { ProductItem } from "../../models/product.item";

const LEFT_BUTTON = 0x01;

const IDLE = 0x00;
const DRAWING = 0x01;
const SELECTION = 0x02;
const CROPPING = 0x03;
const RESIZING = 0x04;
const EXITING = 0x05;
const LOUISE2 = 0x06;

@Component({
  selector: "renderer",
  templateUrl: "./renderer.component.html",
  styleUrls: ["./renderer.component.css"],
})
export class RendererComponent implements OnDestroy {
  @ViewChild("imgCanvas") imgCanvas: any;

  private downX = 0;
  private downY = 0;
  private currentX = 0;
  private currentY = 0;
  private prevX = 0;
  private prevY = 0;
  private img = null;
  private state = IDLE;
  private hittestContext = null;
  private drawingContext = null;
  private animationId = 0;
  private newItem = null;
  private imageData = null;
  private progress = null;

  private lastTimestamp = 0;
  private fps = 60;
  private timestep = 1000 / this.fps;

  constructor(
    private operationContext: OperationContext,
    private dataService: DataService
  ) {
    this.img = new Image();
    this.progress = new Progress();
    this.img.onload = async (event) => {
      await this.updateImage();

      if (this.operationContext.isAIEnabled) {
        this.state = LOUISE2;
        this.dataService
          .detect(this.img.src)
          .then((items) => {
            if (!items) {
              this.state = IDLE;
              return;
            }

            for (let item of items) {
              let tagItem = TagItem.create({
                box: {
                  l: item.box.left,
                  t: item.box.top,
                  w: item.box.width,
                  h: item.box.height,
                },
                dot: {
                  x: item.box.width / 2 + item.box.left,
                  y: item.box.height / 2 + item.box.top,
                },
              });

              let matchedProduct =
                this.operationContext.selectedImage.imageTags.find((item) => {
                  let box = item.box.boundingBox();
                  let newBox = tagItem.boundingBox();

                  return (
                    Math.round(box.left) === newBox.left &&
                    Math.round(box.top) === newBox.top &&
                    Math.round(box.width) === newBox.width &&
                    Math.round(box.height) === newBox.height
                  );
                });

              if (matchedProduct === undefined) {
                this.operationContext.selectedImage.imageTags.push(tagItem);
                tagItem.isModified = true;
              }
            }

            this.state = IDLE;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.hittestContext = new MemoryContext(this.img.width, this.img.height);
      this.drawingContext = new DrawingContext(
        this.img.width,
        this.img.height,
        this.imgCanvas.nativeElement
      );
      this.animationId = window.requestAnimationFrame(() =>
        this.render(0, this.drawingContext)
      );
      this.operationContext.state = State.IDLE;
    };

    this.img.onerror = (event) => {
      this.updateImage();
      this.operationContext.memoryContext = null;
      this.operationContext.error = new ErrorMessage(
        ErrorMessage.LOADERROR,
        "Media not found",
        new Error("").stack
      );
      this.operationContext.state = State.IDLE;
    };
  }

  ngOnDestroy() {
    this.state = EXITING;
    this.operationContext.memoryContext = null;
    this.stopAnimation();
  }

  initMemoryContext(id) {
    this.operationContext.memoryContext = new Promise<MemoryContext>(
      (resolve, reject) => {
        this.operationContext
          .getDownloadImageUrl(id)
          .then((url) => {
            return ImageHelper.download(url);
          })
          .then((result) => {
            return ImageHelper.createMemoryContext(result);
          })
          .then((context) => {
            resolve(context);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );

    if (this.operationContext.memoryContext instanceof Promise) {
      this.operationContext.memoryContext.catch((error) => {
        console.log(error);
      });
    }
  }

  update(imageItem: ImageItem) {
    this.operationContext.state = State.LOADING;
    this.operationContext.memoryContext = null;
    this.initMemoryContext(imageItem.id);

    this.operationContext
      .getImage(imageItem.id)
      .then((imageData) => {
        this.imageData = imageData;
        this.img.src = imageItem.thumbnail;
      })
      .catch((error) => {
        this.operationContext.error = new ErrorMessage(
          ErrorMessage.LOADERROR,
          "Media not found",
          error
        );
        this.operationContext.state = State.IDLE;
      });
  }

  stopAnimation() {
    window.cancelAnimationFrame(this.animationId);
  }

  async updateImage() {
    this.stopAnimation();
    this.operationContext.selectedImage.old = true;
    let image: ImageItem = ImageItem.create(this.imageData);
    await this.loadOptions(image).catch((err) => console.log(err));

    image["canvasWidth"] = this.img.width;
    image["canvasHeight"] = this.img.height;

    this.operationContext.clearSelection();

    try {
      image.parseTags();
    } catch (exc) {
      console.log("Error parsing imagetags", exc.message);
    }

    this.operationContext.selectedImage = image;
  }

  loadOptions(image: ImageItem): Promise<any> {
    let name = image.name;

    if (name.includes("-crop")) {
      let parts = name.split("-crop");
      name = parts[0];
    }

    return this.operationContext
      .getProductSuggestions(name)
      .then(async (result) => {
        if (result === undefined || !result) {
          return;
        }

        await Promise.all(
          result.map(async (item) => {
            return this.dataService
              .searchPIA(item.id)
              .toPromise()
              .then((result) => {
                let rawProduct = result.products[0];

                if (rawProduct) {
                  let product: ProductItem = ProductItem.create({
                    ...rawProduct,
                    type: item.type,
                  });
                  image.options.push(product);
                }
              })
              .catch((err) => {
                console.log("getProductSuggestions", err);
              });
          })
        );

        return Promise.resolve();
      });
  }

  loadError() {
    return (
      this.operationContext.error &&
      this.operationContext.error.code == ErrorMessage.LOADERROR
    );
  }

  start(event: any): void {
    if (!event.target) {
      return;
    }

    if (!this.drawingContext) {
      return;
    }

    if (event.touches && event.touches.length > 1) {
      return;
    }

    let box = event.target.getBoundingClientRect();
    let scaleX = this.img.width / box.width;
    let scaleY = this.img.height / box.height;
    this.downX =
      ((event.clientX || event.targetTouches[0].clientX) - box.left) * scaleX;
    this.downY =
      ((event.clientY || event.targetTouches[0].clientY) - box.top) * scaleY;
    this.currentX = this.downX;
    this.currentY = this.downY;
    this.prevX = this.currentX;
    this.prevY = this.currentY;
    this.newItem = null;

    let items: any[] = this.operationContext.selectedImage.imageTags;

    if (this.operationContext.selection) {
      items = [this.operationContext.selection, ...items];
    }

    if (this.operationContext.isCropEnabled) {
      items = [this.operationContext.selection, this.operationContext.cropItem];
    }

    let hittestinfo: HittestInfo = Hittester.hittest(
      this.hittestContext,
      this.downX,
      this.downY,
      items
    );

    if ((event.buttons & LEFT_BUTTON) == LEFT_BUTTON || event.targetTouches) {
      if (hittestinfo.element) {
        //we are inside the bounds of an element
        if (hittestinfo.element instanceof Handle) {
          this.operationContext.selection.currentHandle = hittestinfo.element;
          this.operationContext.selection.currentHandle.rect =
            this.operationContext.selection.selectedItem.boundingBox();
          this.state = RESIZING;
          return;
        } else if (hittestinfo.element instanceof CropItem) {
          this.operationContext.selection = new CropSelection(
            hittestinfo.element,
            this.operationContext
          );
          this.drawingContext.canvas.style.cursor =
            hittestinfo.element.renderCursor(true);
          this.state = CROPPING;
          return;
        } else if (hittestinfo.element instanceof Dot) {
          this.operationContext.selection = new DefaultSelection(
            hittestinfo.element
          );
          this.drawingContext.canvas.style.cursor =
            hittestinfo.element.renderCursor(true);
          this.state = SELECTION;
          return;
        } else if (hittestinfo.element instanceof Box) {
          this.operationContext.selection = new ResizeSelection(
            hittestinfo.element
          );
          this.state = SELECTION;
          return;
        }
      }
    }

    if (this.operationContext.isCropEnabled) {
      return;
    }

    this.newItem = new TagItem({
      x: this.downX,
      y: this.downY,
      width: this.currentX - this.downX,
      height: this.currentY - this.downY,
      color: Helpers.getColor(),
    });

    this.operationContext.selection = new DrawSelection(this.newItem.box);
    this.state = DRAWING;
  }

  move(event: any): void {
    let pressed = event.buttons == 1 || event.targetTouches != undefined;

    if (!pressed) {
      this.state = IDLE;
    }

    if (!event.target) {
      return;
    }

    if (!this.drawingContext) {
      return;
    }

    if (event.touches !== undefined && event.touches.length > 1) {
      return;
    }

    event.preventDefault();

    let box = event.target.getBoundingClientRect();
    let scaleX = this.img.width / box.width;
    let scaleY = this.img.height / box.height;
    this.currentX =
      ((event.clientX || event.targetTouches[0].clientX) - box.left) * scaleX;
    this.currentY =
      ((event.clientY || event.targetTouches[0].clientY) - box.top) * scaleY;

    let items: any[] = this.operationContext.selectedImage.imageTags;

    if (this.operationContext.selection) {
      items = [this.operationContext.selection, ...items];

      if (this.operationContext.isCropEnabled) {
        items = [
          this.operationContext.selection,
          this.operationContext.cropItem,
        ];
      }

      let selectedItem = this.operationContext.selection.selectedItem;

      if (this.state == DRAWING) {
        selectedItem
          .parent()
          .update(
            this.downX,
            this.downY,
            this.currentX - this.downX,
            this.currentY - this.downY
          );
      } else if (this.state == SELECTION) {
        if (selectedItem instanceof Dot) {
          selectedItem.move(
            this.prevX - this.currentX,
            this.prevY - this.currentY
          );
        } else {
          selectedItem
            .parent()
            .move(this.prevX - this.currentX, this.prevY - this.currentY);
        }
      } else if (this.state == CROPPING) {
        this.operationContext.cropItem.move(
          this.prevX - this.currentX,
          this.prevY - this.currentY,
          event.getModifierState("Shift")
        );
      } else if (this.state == RESIZING) {
        this.operationContext.selection.resize(
          this.hittestContext,
          this.currentX,
          this.currentY
        );
      }
    }

    if (pressed) {
      if (this.operationContext.selection) {
        this.operationContext.selection.selectedItem.renderCursor(pressed);
      }
    } else {
      let hittestinfo: HittestInfo = Hittester.hittest(
        this.hittestContext,
        this.currentX,
        this.currentY,
        items
      );

      if (hittestinfo.element) {
        this.drawingContext.canvas.style.cursor =
          hittestinfo.element.renderCursor(pressed);
      } else {
        this.drawingContext.canvas.style.cursor = "crosshair";
      }
    }

    this.prevX = this.currentX;
    this.prevY = this.currentY;
  }

  end(event: any) {
    if (!event.target) {
      return;
    }

    if (this.state == DRAWING) {
      if (this.newItem.box.boundingBox().width >= 20) {
        this.operationContext.selectedImage.imageTags.push(this.newItem);

        if (this.operationContext.selection.selectedItem.canResize()) {
          this.operationContext.selection = new ResizeSelection(
            this.operationContext.selection.selectedItem
          );
        }
      } else {
        this.operationContext.clearSelection();
      }
    }

    if (this.operationContext.selection) {
      this.drawingContext.canvas.style.cursor =
        this.operationContext.selection.selectedItem.renderCursor(false);
    } else {
      this.drawingContext.canvas.style.cursor = "crosshair";
    }

    this.state = IDLE;
  }

  render(timestamp, ctx: Context) {
    if (this.state == EXITING || this.operationContext.state == State.LOADING) {
      return;
    }

    this.animationId = window.requestAnimationFrame((timestamp) =>
      this.render(timestamp, ctx)
    );

    if (timestamp - this.lastTimestamp < this.timestep) {
      return;
    }

    this.lastTimestamp = timestamp;
    this.draw(ctx);
  }

  draw(ctx: Context) {
    ctx.context.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.context.drawImage(this.img, 0, 0, ctx.canvas.width, ctx.canvas.height);

    // AI is loading, render progress
    if (this.state == LOUISE2) {
      this.progress.render(ctx.context);
      return;
    }

    for (
      let i = this.operationContext.selectedImage.imageTags.length - 1;
      i >= 0;
      i--
    ) {
      if (this.operationContext.selectedImage.imageTags === undefined) {
        continue;
      }

      let item: TagItem = this.operationContext.selectedImage.imageTags[i];

      if (!item.isVisible) {
        if (
          this.operationContext.selection &&
          (this.operationContext.selection instanceof ResizeSelection ||
            this.operationContext.selection instanceof DefaultSelection) &&
          this.operationContext.selection.selectedItem.parent() === item
        ) {
          this.operationContext.clearSelection();
        }

        continue;
      }

      if (item.isSelected) {
        this.operationContext.selection = new ResizeSelection(item.box);
        item.isSelected = false;
      }

      item.render(
        ctx.context,
        !this.operationContext.isCropEnabled && this.operationContext.showBoxes
      );
    }

    if (this.operationContext.selection) {
      this.operationContext.selection.render(ctx.context);
    }
  }
}
