import { TagItem } from "./tag.item";
import { Rect } from "./rect";
import { Point } from "./point";

const RADIUS: number = 10;
const PADDING: number = 5;

export class Dot {
  private parentItem: TagItem;
  private point: Point;
  private offset: number = 0;
  private then: any = 0;

  constructor(point: Point, parent: TagItem) {
    this.parentItem = parent;
    this.point = point;
  }

  public render(context: CanvasRenderingContext2D) {
    if (this.then == undefined) {
      this.then = performance.now();
      this.offset = 0;
    }

    let now = performance.now();
    let delta = now - this.then;
    context.save();

    context.beginPath();

    let grd = context.createRadialGradient(
      this.point.x - 2,
      this.point.y - 2,
      2,
      this.point.x - 2,
      this.point.y - 2,
      RADIUS + Math.cos((this.offset * Math.PI) / 180) * 1.5
    );
    grd.addColorStop(0, "white");
    grd.addColorStop(1, "#f0f0f0");
    context.fillStyle = grd;
    context.arc(
      this.point.x,
      this.point.y,
      RADIUS + Math.cos((this.offset * Math.PI) / 180) * 1.5,
      0,
      2 * Math.PI
    );

    context.shadowColor = "#333";
    context.shadowBlur = 5;
    context.shadowOffsetX = 3;
    context.shadowOffsetY = 3;

    context.fill();
    context.restore();

    if (delta > 40) {
      this.offset += 10;
      this.then = now;
    }
  }

  public parent(): TagItem {
    return this.parentItem;
  }

  public canResize() {
    return false;
  }

  public get location(): Point {
    return this.point;
  }

  public boundingBox(context: CanvasRenderingContext2D): Rect {
    return new Rect(
      this.point.x - RADIUS - PADDING,
      this.point.y - RADIUS - PADDING,
      2 * RADIUS + 2 * PADDING,
      2 * RADIUS + 2 * PADDING
    );
  }

  public move(x: number, y: number) {
    this.point.x -= x;
    this.point.y -= y;

    this.parentItem.isModified = true;
  }

  public updatePosition(x: number, y: number) {
    this.point.x = x;
    this.point.y = y;

    this.parentItem.isModified = true;
  }

  public toJSON() {
    return {
      x: this.point.x,
      y: this.point.y,
    };
  }

  renderCursor(mouseDown: boolean) {
    if (mouseDown) {
      return "-webkit-grabbing";
    } else {
      return "grab";
    }
  }
}
