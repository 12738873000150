export class ErrorMessage {
  static OK = 0x00;
  static INFO = 0x01;
  static ERROR = 0x02;
  static LOADERROR = 0x03;
  static LOG = 0x04;

  code = ErrorMessage.OK;
  message = "";
  details = "";

  constructor(code: number, message: string, details: string) {
    this.code = code;
    this.message = message;
    this.details = details;
  }

  toJSON() {
    return { statuscode: this.code.toString(), message: this.message, details: this.details };
  }

  get isOK(): boolean {
    return this.code == ErrorMessage.OK;
  }
}