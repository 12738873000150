import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { OperationContext } from '../../services/operation.context';
import { TagItem } from '../../models/renderable/tag.item';
import { ProductItem } from '../../models/product.item';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'tagslist',
  templateUrl: './tagslist.component.html',
  styleUrls: ['./tagslist.component.css']
})
export class TagsListComponent {
  @Input() tagItem: TagItem;
  @Input() options: ProductItem[] = [];

  constructor(public operationContext: OperationContext) {}
}