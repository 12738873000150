const UNDEF = 0xFF00;
const SOI = 0xFFD8; // Start of image
const EOF = 0xFFD9; // End of image
const SOF0 = 0xFFC0; // Frame header 0, Start Of Frame
const SOF1 = 0xFFC1; // Frame header 1, Start Of Frame
const SOF2 = 0xFFC2; // Frame header 2, Start Of Frame
const SOS = 0xFFDA; // Scan header, Start Of Scan
const DHT = 0xFFD4;
const DQT = 0xFFDB;
const DRI = 0xFFDD;
const APP0 = 0xFFE0;
const APP1 = 0xFFE1;
const APP2 = 0xFFE2;
const APP3 = 0xFFE3;
const APP4 = 0xFFE4;
const APP5 = 0xFFE5;
const APP6 = 0xFFE6;
const APP7 = 0xFFE7;
const APP8 = 0xFFE8;
const APP9 = 0xFFE9;
const APP10 = 0xFFEA;
const APP11 = 0xFFEB;
const APP12 = 0xFFEC;
const APP13 = 0xFFED;
const APP14 = 0xFFEE;
const APP15 = 0xFFEF;
const COM = 0xFFFE; // COM header
const PAD = 0xFFFF;

/**
 * 
 */
export class ImageMetadata {
  private data: Uint8Array;
  //private header = "data:image/jpeg;base64,";
  private offset = 0;
  private blocks = [];

  constructor(buffer: Uint8Array) {
    this.data = buffer;
  }

  /*arrayToBase64(array) {

    //var b64encoded = btoa(String.fromCharCode.apply(null, array));

    return btoa(new Uint8Array(array).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, ''));
  }*/

  decode() {
    let uint16 = this.readMarker();

    // check if valid jpeg
    if (uint16 != SOI) {
      throw new Error("Not a valid JPEG");
    }

    uint16 = this.readMarker();

    while (uint16 != EOF) {
      if (this.offset >= this.data.length) {
        throw new Error("Error parsing imagefile");
      }

      switch (uint16) {
        case UNDEF:
          break;

        case SOS:
          /* APP data should be placed before SOF0 but to be compatible with older 
           * XMP implementations extend to check up to SOS.
           * No need to look further than that according to XMP spec part 2 section 1.1.3 JPEG */
          return this.blocks;

        case APP0:
          let app0Block = this.readBlock();

          if (this.isJFIF(app0Block)) {
            this.addBlock(uint16, app0Block);
          }

          break;

        case APP1:
          let app1Block = this.readBlock();

          if (this.isEXIF(app1Block) || this.isXMP(app1Block)) {
            this.addBlock(uint16, app1Block);
          }

          break;

        case COM:
          let comBlock = this.readBlock();
          this.addBlock(uint16, comBlock)

        case PAD:
          // A marker can be preceded by any number of 0xFF
          if (this.data[this.offset] !== 0xFF) {
            this.offset--;
          }
          break;

        default:
          this.readBlock();
          break;
      }

      uint16 = this.readMarker();
    }

    return null;
  }

  isJFIF(block) {
    let data = block.data;

    return data[0] === 0x4A && data[1] === 0x46 && data[2] === 0x49 &&
      data[3] === 0x46 && data[4] === 0;
  }

  isEXIF(block) {
    let data = block.data;

    return data[0] === 0x45 && data[1] === 0x78 && data[2] === 0x69 &&
      data[3] === 0x66 && data[4] === 0 && data[5] === 0;
  }

  isXMP(block) {
    let array = block.data.slice(0, 29);

    return String.fromCharCode.apply(String, array) == 'http://ns.adobe.com/xap/1.0/\0';
  }

  addBlock(id, block) {
    this.blocks.push(
      {
        id: id,
        length: block.length,
        data: block.data
      });
  }

  splitString(base64URL) {
    const BASE64_MARKER = ';base64,';
    const parts = base64URL.split(BASE64_MARKER);

    if (parts.length != 2) {
      throw new Error('Error creating metadata');
    }

    parts[0] += BASE64_MARKER;

    return parts;
  }

  insertMetadata(blocks) {
    let merge = [];
    let metadata = this.decode();

    if (!metadata) {
      throw new Error('Error decoding metadata');
    }

    blocks.map((block) => {
      merge.push([(block.id >> 8) & 0xFF, (block.id & 0xFF), (block.length >> 8) & 0xFF, (block.length & 0xFF), ...Array.from(block.data)]);
    });

    for (let i = 0; i < metadata.length; i++) {
      let block = metadata[i];

      if (this.isJFIF(block)) {
        let header = this.data.subarray(0, block.data.length + 6);
        let footer = this.data.subarray(block.data.length + 6, this.data.length + 6);

        const out = new Uint8Array(header.length + footer.length + merge.reduce((sum, l) => sum + l.length, 0));

        let counter = 0;

        out.set(header, counter);
        counter += header.length;

        for (let i = 0; i < merge.length; i++) {
          out.set(merge[i], counter);
          counter += merge[i].length;
        }

        out.set(footer, counter);

        return out;
      }
    }

    return null;
  }

  /**
 * 
 * @param base64URL 
 */
  extractMetadata() {
    let blocks = [];

    try {
      blocks = this.decode();
    } catch (error) {
      console.log(error);
    }

    /*if (blocks) {
      blocks.map((block) => {
        let str = new TextDecoder().decode(block.data.subarray(0, block.data.length));

        console.log(block.id, str)
      });
    }*/

    return blocks;
  }

  readMarker() {
    let value = (this.data[this.offset] << 8) | this.data[this.offset + 1];
    this.offset += 2;
    return value;
  }

  readBlock() {
    let length = this.readMarker();
    let array = this.data.subarray(this.offset, this.offset + length - 2);
    this.offset += array.length;

    return {
      data: array,
      length: length,
      offset: this.offset
    };
  }
}