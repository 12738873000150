var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ActivatedRoute } from "@angular/router";
import { OperationContext, State } from "../../services/operation.context";
import { ErrorMessage } from "../../models/error.message";
import { ModalConfirmationService } from "../../services/confirmation.service";
import { Rect } from "../../models/renderable/rect";
import { ImageHelper } from "../../helpers/image.helper";
var KEY_S = 83;
var KEY_Z = 90;
var KEY_PAGE_UP = 33;
var KEY_PAGE_DOWN = 34;
var TaggingComponent = /** @class */ (function () {
    function TaggingComponent(route, operationContext, modalService) {
        this.route = route;
        this.operationContext = operationContext;
        this.modalService = modalService;
        this.inspirationalParentCheck = false;
        this.inspirationalCropCheck = false;
        this.isLoadingIDAMData = false;
    }
    TaggingComponent.prototype.handleKeydown = function (event) {
        if (this.operationContext.state != State.IDLE) {
            return;
        }
        switch (event.keyCode) {
            case KEY_S: {
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.save(event);
                }
                break;
            }
            case KEY_Z: {
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.undo();
                }
                break;
            }
            case KEY_PAGE_UP: {
                event.preventDefault();
                this.moveDown();
                break;
            }
            case KEY_PAGE_DOWN: {
                event.preventDefault();
                this.moveUp();
                break;
            }
        }
    };
    TaggingComponent.prototype.cancel = function () { };
    TaggingComponent.prototype.undo = function () { };
    TaggingComponent.prototype.onLayoutNameChange = function (name) {
        console.log("Setting layoutName to", name);
        this.layoutName = name;
        this.operationContext.layoutName = name;
    };
    TaggingComponent.prototype.onInputChange = function () {
        console.log("Setting layoutName to", this.layoutName);
        this.operationContext.layoutName = this.layoutName;
    };
    TaggingComponent.prototype.moveDown = function () {
        console.log;
        var item = this.operationContext.selection.selectedItem.parent();
        var index = this.operationContext.selectedImage.imageTags.indexOf(item);
        console.log("DOWN");
        if (index >= 1) {
            this.moveItem(index - 1, index);
        }
    };
    TaggingComponent.prototype.moveUp = function () {
        var item = this.operationContext.selection.selectedItem.parent();
        var index = this.operationContext.selectedImage.imageTags.indexOf(item);
        console.log("UP");
        if (index < this.operationContext.selectedImage.imageTags.length - 1) {
            this.moveItem(index + 1, index);
        }
    };
    TaggingComponent.prototype.moveItem = function (to, from) {
        this.operationContext.selectedImage.moveTag(to, from);
    };
    TaggingComponent.prototype.sortTagsByArea = function () {
        this.operationContext.selectedImage.sortTagsByAreaSize();
    };
    TaggingComponent.prototype.hasTags = function () {
        return this.operationContext.selectedImage.imageTags.length > 0;
    };
    TaggingComponent.prototype.save = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.operationContext.state = State.SAVING;
                this.canSave()
                    .then(function (canSave) {
                    if (canSave) {
                        // debugger
                        return _this.operationContext.saveTags();
                    }
                    return Promise.reject();
                })
                    .then(function (result) {
                    _this.operationContext.selectedImage.dateLoaded = Date.now() + 30000;
                    _this.operationContext.selectedImage.clearIsModified();
                    _this.operationContext.selection = null;
                    _this.operationContext.error = result;
                })
                    .catch(function (error) {
                    _this.operationContext.error = error;
                })
                    .finally(function () {
                    _this.operationContext.state = State.IDLE;
                });
                return [2 /*return*/];
            });
        });
    };
    TaggingComponent.prototype.hasImage = function () {
        return this.operationContext.selectedImage;
    };
    TaggingComponent.prototype.isSaving = function () {
        return this.operationContext.state == State.SAVING;
    };
    TaggingComponent.prototype.canClick = function () {
        var loadState = this.operationContext.state === State.IDLE;
        var error = this.operationContext.error === null;
        return loadState || error;
    };
    TaggingComponent.prototype.loadIDAMData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("load");
                        this.isLoadingIDAMData = true;
                        return [4 /*yield*/, this.operationContext.loadIDAMProductTags()];
                    case 1:
                        _a.sent();
                        this.isLoadingIDAMData = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TaggingComponent.prototype.canLoadIDAMData = function () {
        // console.log("check", this.operationContext.selectedImage.imageTags);
        return (this.operationContext.selectedImage.imageTags.length === 0 &&
            this.operationContext.state === State.IDLE &&
            this.isLoadingIDAMData === false);
    };
    TaggingComponent.prototype.loadError = function () {
        return (this.operationContext.error &&
            this.operationContext.error.code == ErrorMessage.LOADERROR);
    };
    TaggingComponent.prototype.toggleBoxes = function () {
        this.operationContext.showBoxes = !this.operationContext.showBoxes;
    };
    TaggingComponent.prototype.toggle = function () {
        if (this.operationContext.selectedImage === undefined) {
            return;
        }
        this.operationContext.isCropEnabled = !this.operationContext.isCropEnabled;
    };
    TaggingComponent.prototype.getDimension = function () {
        var fullHeight = this.operationContext.selectedImage.height;
        var fullWidth = this.operationContext.selectedImage.width;
        var height = this.operationContext.selectedImage["canvasHeight"];
        var rect = this.operationContext.cropItem.boundingBox();
        var scale = fullHeight / height;
        var newWidth = rect.width * scale;
        var newHeight = rect.height * scale;
        var crop = new Rect(0, 0, newWidth, newHeight);
        var alignedCrop = ImageHelper.createAlignedCrop(crop, this.operationContext.cropRatio, fullWidth, fullHeight);
        // return Math.abs(newWidth).toFixed(0) + " x " + Math.abs(newHeight).toFixed(0);
        return alignedCrop.width + " x " + alignedCrop.height;
    };
    TaggingComponent.prototype.getRatio = function () {
        var fullHeight = this.operationContext.selectedImage.height;
        var height = this.operationContext.selectedImage["canvasHeight"];
        var rect = this.operationContext.cropItem.boundingBox();
        var scale = fullHeight / height;
        var newWidth = rect.width * scale;
        var newHeight = rect.height * scale;
        return (newWidth / newHeight).toFixed(2);
    };
    TaggingComponent.prototype.canSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var image, dateModified, dateLoaded, result, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.operationContext.getImage(this.operationContext.selectedImage.id)];
                    case 1:
                        image = _a.sent();
                        if (!image) {
                            throw new ErrorMessage(ErrorMessage.ERROR, "Something went wrong", "");
                        }
                        dateModified = new Date(image["dateModified"]);
                        dateLoaded = new Date(this.operationContext.selectedImage.dateLoaded);
                        if (!(dateModified > dateLoaded)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.modalService.confirm({
                                title: "Asset modified",
                                otherData: "Do you want to overwrite someone elses changes?",
                            })];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, !result];
                    case 3:
                        if (!(this.operationContext.selectedImage.imageTags &&
                            this.operationContext.selectedImage.imageTags.filter(function (tag) { return tag.prd.id === ""; }).length > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.modalService.confirm({
                                title: "Unselected items",
                                otherData: "You have boxes for which no product is selected.\nThis may SEVERELY hinder users of shoppable images.\n\nSave anyway?",
                            })];
                    case 4:
                        result = _a.sent();
                        return [2 /*return*/, !result];
                    case 5: return [2 /*return*/, true];
                }
            });
        });
    };
    return TaggingComponent;
}());
export { TaggingComponent };
