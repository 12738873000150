import { ISelectionHandle } from "./handle";
import { CropSelectionHandle } from "./crop.handle";
import { Selection } from "./selection";
import { MemoryContext } from "../../helpers/memory.context";
import { Point } from "./point";
import { OperationContext } from '../../services/operation.context';
import Fraction from "fraction.js";

export class CropSelection extends Selection {
  private $selectionHandles: ISelectionHandle[];
  private cropContext: MemoryContext;
  private scaleX: number = 0;
  private scaleY: number = 0;
  private operationContext: OperationContext;

  constructor(item: any, operationContext: OperationContext) {
    super(item);

    this.operationContext = operationContext;
    this.$selectionHandles = [];
    this.$selectionHandles.push(new CropSelectionHandle(0, new Point(0, 0)));
    this.$selectionHandles.push(new CropSelectionHandle(1, new Point(1, 0)));
    this.$selectionHandles.push(new CropSelectionHandle(2, new Point(0, 1)));
    this.$selectionHandles.push(new CropSelectionHandle(3, new Point(1, 1)));

    this.cropContext = new MemoryContext(item.width, item.height);
  }

  public get selectionHandles(): ISelectionHandle[] {
    return this.$selectionHandles;
  }

  renderContext(context: CanvasRenderingContext2D, point: Point, scaleX: number, scaleY: number) {
    context.save();
    context.beginPath();
    if (this.operationContext.cropRatio !== "free") {
      context.transform(
        this.currentHandle.rect.height *
          new Fraction(this.operationContext.cropRatio).valueOf(),
        0,
        0,
        this.currentHandle.rect.height,
        this.currentHandle.rect.left,
        this.currentHandle.rect.top
      );
    } else {
      context.transform(this.currentHandle.rect.width, 0, 0, this.currentHandle.rect.height, this.currentHandle.rect.left, this.currentHandle.rect.top);     
    }
    context.translate(point.x, point.y);
    context.scale(scaleX, scaleY);
  }

  resize(memoryContext: MemoryContext, x: number, y: number) {
    let context = memoryContext.context as any;
    this.scaleX = (this.currentHandle.rect.left - x + this.currentHandle.rect.width) / this.currentHandle.rect.width;
    this.scaleY = (this.currentHandle.rect.top - y + this.currentHandle.rect.height) / this.currentHandle.rect.height;

    switch (this.currentHandle.id) {
      case 0: { // Upper left corner
        this.renderContext(context, new Point(1, 1), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = matrix[3] || matrix['d'];
        let w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : matrix[0] || matrix['a'];

        context.restore();

        if (this.currentHandle.rect.left + this.currentHandle.rect.width - w < 0) {
          break;
        }

        this.selectedItem.update(
          this.currentHandle.rect.left + this.currentHandle.rect.width - w,
          this.currentHandle.rect.top + this.currentHandle.rect.height - h,
          w,
          h);

        break;
      }

      case 1: { // Upper right corner
        this.renderContext(context, new Point(0, 1), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = matrix[3] || matrix['d'];
        let w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : this.currentHandle.rect.width - (matrix[0] || matrix['a']);

        context.restore();

        if (this.currentHandle.rect.left + w > this.cropContext.canvas.width) {
          break;
        }

        this.selectedItem.update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top + this.currentHandle.rect.height - h,
          w,
          h);
        break;
      }

      case 2: { // Lower left corner
        this.renderContext(context, new Point(1, 0), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = this.currentHandle.rect.height - (matrix[3] || matrix['d']);
        let w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : matrix[0] || matrix['a'];

        context.restore();

        if (this.currentHandle.rect.left + this.currentHandle.rect.width - w < 0) {
          break;
        }

        this.selectedItem.update(
          this.currentHandle.rect.left + this.currentHandle.rect.width - w,
          this.currentHandle.rect.top,
          w,
          h);
        break;
      }

      case 3: { // Lower right corner
        this.renderContext(context, new Point(0, 0), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = this.currentHandle.rect.height - (matrix[3] || matrix['d']);
        let w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : this.currentHandle.rect.width - (matrix[0] || matrix['a']);

        context.restore();

        if (this.currentHandle.rect.left + w > this.cropContext.canvas.width) {
          break;
        }

        this.selectedItem.update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top,
          w,
          h);
        break;
      }

    }
  }

  public renderHandle(context, handle) {
    let box = this.selectedItem.boundingBox();

    context.save();
    context.beginPath();
    context.transform(box.width, 0, 0, box.height, box.left, box.top);
    handle.render(context, box);
    context.restore();
  }

  public renderCenter(context) {
    let box = this.selectedItem.boundingBox();

    context.save();

    context.beginPath();
    context.transform(box.width, 0, 0, box.height, box.left, box.top);
    context.save();

    context.beginPath();
    context.lineWidth = Math.abs(1 / box.width);
    context.moveTo(0.5, 0.5);
    context.lineTo(0.5, 0.5 + (1 / box.height) * CropSelectionHandle.LENGTH);
    context.strokeStyle = 'white';
    context.stroke();

    context.beginPath();
    context.lineWidth = Math.abs(1 / box.width);
    context.moveTo(0.5, 0.5);
    context.lineTo(0.5, 0.5 - (1 / box.height) * CropSelectionHandle.LENGTH);
    context.strokeStyle = 'white';
    context.stroke();

    context.beginPath();
    context.lineWidth = Math.abs(1 / box.height);
    context.moveTo(0.5, 0.5);
    context.lineTo(0.5 + (1 / box.width) * CropSelectionHandle.LENGTH, 0.5);
    context.strokeStyle = 'white';
    context.stroke();

    context.beginPath();
    context.lineWidth = Math.abs(1 / box.height);
    context.moveTo(0.5, 0.5);
    context.lineTo(0.5 - (1 / box.width) * CropSelectionHandle.LENGTH, 0.5);
    context.strokeStyle = 'white';
    context.stroke();

    context.restore();
    context.restore();
  }

  public renderDimming(context) {
    let ctx = this.cropContext.context;

    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, context.canvas.width, context.canvas.height);
    ctx.fillStyle = 'rgba(255,255,255,0.9)';
    ctx.fillRect(0, 0, context.canvas.width, context.canvas.height);
    ctx.clearRect(this.selectedItem.rect.left, this.selectedItem.rect.top, this.selectedItem.rect.width, this.selectedItem.rect.height);
    ctx.restore();

    context.drawImage(this.cropContext.canvas, 0, 0, this.selectedItem.width, this.selectedItem.height);
  }

  public renderSafeZone(context) {
    let ctx = this.cropContext.context;

    let safeMarginX = this.selectedItem.rect.width / (375 / 40);
    let safeMarginTop = this.selectedItem.rect.height / (667 / 50);
    let safeMarginBottom = this.selectedItem.rect.height / (667 / 70);

    let safeMarginSquareX = this.selectedItem.rect.width / (375 / 120);
    let safeMarginSquareY = this.selectedItem.rect.height / (667 / 120);

    ctx.save();
    ctx.beginPath();

    ctx.fillStyle = 'white';
    this.renderRoundedRect(
      ctx,
      this.selectedItem.rect.left + safeMarginX,
      this.selectedItem.rect.top + safeMarginTop,
      this.selectedItem.rect.width - safeMarginX * 2,
      this.selectedItem.rect.height - safeMarginBottom - safeMarginTop,
      1
    );

    ctx.fill();
    ctx.clearRect(
      this.selectedItem.rect.left,
      this.selectedItem.rect.top,
      safeMarginSquareX,
      safeMarginSquareY,
    );
    ctx.globalCompositeOperation = 'xor';
    ctx.fillStyle = 'rgba(0,0,0,0.4)';

    ctx.fillRect(this.selectedItem.rect.left, this.selectedItem.rect.top, this.selectedItem.rect.width, this.selectedItem.rect.height);
    ctx.restore();

    context.globalCompositeOperation = 'multiply';
    context.drawImage(this.cropContext.canvas, 0, 0, this.selectedItem.width, this.selectedItem.height);
    context.globalCompositeOperation = 'source-over';
  }

  public render(context: any) {
    if (!this.selectedItem) {
      return;
    }

    this.renderDimming(context);
    this.renderSafeZone(context);

    for (let i = 0; i < this.$selectionHandles.length; i++) {
      let handle = this.$selectionHandles[i];
      this.renderHandle(context, handle);
    }

    this.renderCenter(context);
  }

  renderRoundedRect(context, x, y, w, h, r) {
    if (w < 2 * r && r < 13) r = Math.abs(w / 2);
    if (h < 2 * r && r < 13) r = Math.abs(h / 2);

    context.beginPath();
    context.moveTo(x + r, y);
    context.arcTo(x + w, y, x + w, y + h, r);
    context.arcTo(x + w, y + h, x, y + h, r);
    context.arcTo(x, y + h, x, y, r);
    context.arcTo(x, y, x + w, y, r);
    context.closePath();
  }
}
