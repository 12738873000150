<div *ngIf="operationContext.selectedImage">
  <div
    class="tagslist-grid"
    *ngFor="
      let tagItem of operationContext.selectedImage.imageTags;
      let i = index
    "
  >
    <div class="tagListNumbering">{{ i + 1 }}</div>
    <tagslistitem
      [tagItem]="tagItem"
      [options]="operationContext.selectedImage.options"
    ></tagslistitem>
  </div>
</div>
