export const environment = {
  production: true,
  debug: false,
  url: "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1",
  autologout: true,
  timeout: 15,
  cropRatio: "9:16",
  cropRatios: ["16:9", "9:16", "3:4", "4:3", "1:1", "free"],
  bynderImageCDN: "https://ikea.getbynder.com/m",
  akamaiImageCDN: "https://www.ikea.com/ext/ingkadam/m",
  ladanUrl: "https://run-api-4extyex3gq-ew.a.run.app",
  adalConfig: {
    production: true,
    expireOffsetSeconds: 900,
    tenant: "720b637a-655a-40cf-816a-f22f40755c2c",
    clientId: "40490ae5-a8ad-4060-9a0d-b50ab5574122",
    redirectUri: window.location.origin,
    endpoints: {
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/collections":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/media":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/collection":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/search":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/content":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/asset":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/ai/detect":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/search/bynder":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://t2wg8qzia2.execute-api.eu-north-1.amazonaws.com/prod/api/v1/ai/identify":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
      "https://run-api-4extyex3gq-ew.a.run.app/api/idam/asset-products/":
        "40490ae5-a8ad-4060-9a0d-b50ab5574122",
    },
  },
};
