<div
  class="item-container"
  [ngClass]="isInCropRegion() ? 'not-dimmed' : 'dimmed'"
  (keyup)="tab($event)"
  draggable="true"
  (dragstart)="drag($event)"
  (drop)="drop($event)"
  (dragover)="allowDrop($event)"
>
  <div
    class="item-img-top"
    (click)="select()"
    [ngStyle]="{
      'border-color': '' + getColor(tagItem) + '',
      'background-color': '' + getColor(tagItem) + ''
    }"
  >
    <mat-icon *ngIf="tagItem.isModified" class="white-icon"
      >add_circle</mat-icon
    >
  </div>
  <div
    class="item-body"
    (click)="select()"
    draggable="true"
    (dragstart)="$event.preventDefault(); $event.stopPropagation()"
  >
    <search [tagItem]="tagItem" [options]="options" #searchItem></search>
  </div>
  <mat-icon *ngIf="tagItem.prd.type == 'main'" class="icon">starts</mat-icon>
  <mat-icon (click)="delete()" class="icon">delete</mat-icon>
  <mat-icon *ngIf="tagItem.isVisible" (click)="toggleVisibility()" class="icon"
    >visibility</mat-icon
  >
  <mat-icon *ngIf="!tagItem.isVisible" (click)="toggleVisibility()" class="icon"
    >visibility_off</mat-icon
  >
</div>
