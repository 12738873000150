var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DataService } from "./data.service";
import { ImageItem } from "../models/image.item";
import { ProductItem } from "../models/product.item";
import { CollectionItem, CollectionState } from "../models/collection.item";
import { CropItem } from "../models/renderable/crop.item";
import { CropSelection } from "../models/renderable/crop.selection";
import { ImageHelper } from "../helpers/image.helper";
import { ErrorMessage } from "../models/error.message";
import { Rect } from "../models/renderable/rect";
import { AdalService } from "adal-angular4";
import { OverlayService } from "./overlay.service";
import { Helpers } from "../helpers/common";
import { SnackBarService } from "./snackbar.service";
import { environment } from "../../environments/environment";
import { LogoutService } from "./logout.service";
import Fraction from "fraction.js";
import { TagItem } from "../models/renderable/tag.item";
export var State;
(function (State) {
    State[State["IDLE"] = 1] = "IDLE";
    State[State["LOADING"] = 2] = "LOADING";
    State[State["SAVING"] = 3] = "SAVING";
})(State || (State = {}));
/**
 * Global Singelton service class that can be injected in all components
 * Creates link to the data service and holds global states such as selections
 */
var OperationContext = /** @class */ (function () {
    function OperationContext(dataService, adal, overlay, snackBar, logout) {
        this.dataService = dataService;
        this.adal = adal;
        this.overlay = overlay;
        this.snackBar = snackBar;
        this.logout = logout;
        this.$images = null;
        this.$groupedCollections = null;
        this.$collections = null;
        this.$collectionCache = {};
        this.$selectedCollection = null;
        this.$selectedImage = null;
        this.$tagSelection = null;
        this.$cropItem = null;
        this.$errorMessage = null;
        this.$showBoxes = true;
        this.$imagesLoaded = false;
        this.$collectionsLoaded = false;
        this.$aiEnabled = false;
        this.$cropRatio = "9:16";
        this.$state = State.IDLE;
        this.inspirationalParentCheck = false;
        this.inspirationalCropCheck = false;
        this.$stateFilter = [true, true, true, true, true];
        this.$showBoxes = localStorage.getItem("showBoxes") === "1" ? true : false;
        this.$aiEnabled = localStorage.getItem("aiEnabled") === "1" ? true : false;
        if (localStorage.getItem("stateFilter")) {
            this.$stateFilter = localStorage
                .getItem("stateFilter")
                .split("")
                .map(function (c) { return c === "1"; });
        }
        this.layoutName = "";
        var r = localStorage.getItem("cropRatioFraction");
        if (r !== null) {
            this.$cropRatio = r;
        }
        this.loadCollections();
    }
    Object.defineProperty(OperationContext.prototype, "imagesLoaded", {
        /**
         *
         */
        get: function () {
            return this.$imagesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "collectionsLoaded", {
        /**
         *
         */
        get: function () {
            return this.$collectionsLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "isModified", {
        /**
         *
         */
        get: function () {
            return ((this.selectedImage && this.selectedImage.isModified) ||
                this.isCropEnabled ||
                this.inspirationalParentCheck ||
                this.inspirationalCropCheck);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "showBoxes", {
        /**
         *
         */
        get: function () {
            return this.$showBoxes;
        },
        /**
         *
         */
        set: function (value) {
            localStorage.setItem("showBoxes", value ? "1" : "0");
            this.$showBoxes = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "cropRatio", {
        /**
         *
         */
        get: function () {
            return this.$cropRatio;
        },
        /**
         *
         */
        set: function (value) {
            localStorage.setItem("cropRatioFraction", "" + value);
            this.$cropRatio = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "memoryContext", {
        /**
         *
         */
        get: function () {
            return this.$memoryContext;
        },
        /**
         *
         */
        set: function (value) {
            this.$memoryContext = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "state", {
        /**
         *
         */
        get: function () {
            return this.$state;
        },
        /**
         *
         */
        set: function (value) {
            var _this = this;
            this.$state = value;
            switch (this.$state) {
                case State.IDLE: {
                    this.overlay.hide();
                    break;
                }
                case State.LOADING: {
                    setTimeout(function () { return _this.overlay.show(); });
                    break;
                }
                case State.SAVING: {
                    setTimeout(function () { return _this.overlay.show(); });
                    break;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "isAIEnabled", {
        /**
         *
         */
        get: function () {
            return this.$aiEnabled;
        },
        /**
         *
         */
        set: function (value) {
            localStorage.setItem("aiEnabled", value ? "1" : "0");
            this.$aiEnabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "stateFilter", {
        get: function () {
            return this.$stateFilter;
        },
        set: function (value) {
            localStorage.setItem("stateFilter", value.map(function (e) { return (e ? "1" : "0"); }).join(""));
            this.$stateFilter = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "isCropEnabled", {
        /**
         *
         */
        get: function () {
            return this.selection instanceof CropSelection;
        },
        /**
         *
         */
        set: function (value) {
            if (this.selectedImage === undefined) {
                return;
            }
            if (!this.selectedImage) {
                return;
            }
            if (this.isCropEnabled) {
                this.selection = null;
            }
            else {
                this.createCropItem();
                this.selection = new CropSelection(this.cropItem, this);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "cropItem", {
        /**
         *
         */
        get: function () {
            return this.$cropItem;
        },
        /**
         *
         */
        set: function (value) {
            this.$cropItem = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "images", {
        /**
         * Get all images
         */
        get: function () {
            return this.$images;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "groupedCollections", {
        /**
         *
         */
        get: function () {
            return this.$groupedCollections;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "collections", {
        /**
         * Get all collections
         */
        get: function () {
            return this.$collections;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "selectedImage", {
        /**
         * The currently select image
         */
        get: function () {
            return this.$selectedImage;
        },
        /**
         * The currently select image
         */
        set: function (image) {
            this.$selectedImage = image;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "selection", {
        /**
         * The image slider selection
         */
        get: function () {
            return this.$tagSelection;
        },
        /**
         * The image slider selection
         */
        set: function (selection) {
            this.$tagSelection = selection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "error", {
        /**
         *
         */
        get: function () {
            return this.$errorMessage;
        },
        /**
         * The image slider selection
         */
        set: function (error) {
            this.$errorMessage = error;
            if (!this.$errorMessage) {
                return;
            }
            switch (this.$errorMessage.code) {
                case ErrorMessage.OK:
                case ErrorMessage.INFO:
                    break;
                default:
                    if (environment.debug) {
                        console.log("ERROR", this.$errorMessage.code, this.$errorMessage.details);
                    }
            }
            if (this.$errorMessage.code == ErrorMessage.LOADERROR ||
                this.$errorMessage.code == ErrorMessage.LOG) {
                return;
            }
            this.snackBar.show(this.$errorMessage.message, "", {
                duration: 3000,
                panelClass: this.$errorMessage.isOK ? "green" : "red",
                verticalPosition: "top",
                horizontalPosition: "center",
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationContext.prototype, "selectedCollection", {
        /**
         * The currently selected collection
         */
        get: function () {
            return this.$selectedCollection;
        },
        /**
         * The currently selected collection
         */
        set: function (collection) {
            this.$selectedCollection = collection;
            this.$images = null;
        },
        enumerable: true,
        configurable: true
    });
    /**
     *
     */
    OperationContext.prototype.clearSelection = function () {
        this.selection = null;
        this.error = null;
    };
    /**
     *
     */
    OperationContext.prototype.createCropItem = function () {
        var width;
        var height;
        var ratio = this.cropRatio === "free" ? 1 : new Fraction(this.cropRatio).valueOf();
        if (ratio > 0) {
            if (this.selectedImage["canvasWidth"] / ratio >=
                this.selectedImage["canvasHeight"]) {
                height = this.selectedImage["canvasHeight"];
                width = this.selectedImage["canvasHeight"] * ratio;
            }
            else {
                width = this.selectedImage["canvasWidth"];
                height = this.selectedImage["canvasWidth"] / ratio;
            }
        }
        else {
            height = this.selectedImage["canvasHeight"];
            width = this.selectedImage["canvasWidth"];
        }
        // let x = ratio < 0 ? 0 : (this.selectedImage["canvasWidth"] - width) / 2;
        // let y = 0;
        this.cropItem = new CropItem(new Rect(0, 0, width, height), this.selectedImage["canvasWidth"], this.selectedImage["canvasHeight"], this.cropRatio);
    };
    /**
     * Load images from collection when a new collection has been selected
     * @param collectionId
     */
    OperationContext.prototype.loadImages = function (collectionId) {
        var _this = this;
        if (!this.$collectionCache[collectionId] && this.state != State.LOADING) {
            this.state = State.LOADING;
        }
        if (!this.$collectionCache[collectionId]) {
            this.$imagesLoaded = false;
        }
        this.$images = this.$collectionCache[collectionId];
        this.dataService
            .getImagesPaged(collectionId)
            .then(function (images) {
            var imageItems = [];
            _this.selectedCollection.state = CollectionState.UNKNOWN;
            for (var i = 0; i < images.length; i++) {
                var imageItem = ImageItem.create(images[i]);
                _this.selectedCollection.updateState(imageItem);
                imageItems.push(imageItem);
            }
            if (_this.selectedCollection.state == CollectionState.UNKNOWN) {
                _this.$selectedCollection.state = CollectionState.COMPLETE;
            }
            _this.$collectionCache[collectionId] = imageItems;
            _this.$images = imageItems;
            _this.$imagesLoaded = true;
            _this.state = State.IDLE;
        })
            .catch(function (error) {
            _this.error = new ErrorMessage(ErrorMessage.LOG, "Error loading images", error);
            _this.state = State.IDLE;
        });
    };
    /**
     * Load all available collections
     */
    OperationContext.prototype.loadCollections = function () {
        var _this = this;
        this.state = State.LOADING;
        this.$collectionsLoaded = false;
        this.dataService
            .getCollections()
            .then(function (collections) {
            var collectionItems = [];
            var collectionId = sessionStorage.getItem("collection");
            for (var i = 0; i < collections.length; i++) {
                var collectionItem = CollectionItem.create(collections[i]);
                if (collectionId == collections[i].id) {
                    _this.selectedCollection = collectionItem;
                }
                collectionItems.push(collectionItem);
            }
            _this.$collections = collectionItems.sort(function (a, b) {
                return a
                    .toString()
                    .localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
            });
            _this.$groupedCollections = _this.groupBy(_this.$collections, "type");
            _this.state = State.IDLE;
        })
            .catch(function (error) {
            _this.error = new ErrorMessage(ErrorMessage.LOG, "Error loading collections", error);
            _this.state = State.IDLE;
        });
    };
    /**
     *
     * @param xs
     * @param key
     */
    OperationContext.prototype.groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    /**
     * Save
     */
    OperationContext.prototype.saveTags = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id, tags, thumbnails, cropInfo, colorInfo, length, needContext, saveToCollections, memoryContext, key, crop, exc_1, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = this.$selectedImage.id;
                        tags = this.$selectedImage.saveTags();
                        thumbnails = [];
                        cropInfo = {};
                        colorInfo = {};
                        length = JSON.stringify(tags).length;
                        needContext = this.$selectedImage.imageTags.length > 0 || this.isCropEnabled;
                        saveToCollections = [];
                        /* Bynder has a limit of 2000 chars in a metaproperty */
                        if (length > 2000) {
                            throw new ErrorMessage(ErrorMessage.INFO, "Too many tag objects", "Tag string " + length);
                        }
                        if (this.isCropEnabled) {
                            saveToCollections = this.collections
                                .filter(function (obj) {
                                return obj.selected;
                            })
                                .map(function (item) {
                                return item.id;
                            });
                            if (saveToCollections.length == 0) {
                                throw new ErrorMessage(ErrorMessage.INFO, "Please select at least one collection", "saveToCollections " + saveToCollections.length);
                            }
                        }
                        if (!needContext) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.memoryContext];
                    case 2:
                        memoryContext = _a.sent();
                        colorInfo = ImageHelper.getHistogram(memoryContext.context);
                        if (this.$selectedImage.imageTags.length > 0) {
                            thumbnails = ImageHelper.generateThumbnails(memoryContext, this.$selectedImage);
                        }
                        if (!this.isCropEnabled) return [3 /*break*/, 5];
                        key = this.$selectedImage.id + "_crop.jpg";
                        return [4 /*yield*/, ImageHelper.generateCrop(memoryContext, this.$selectedImage, this.$cropItem, this.adal.userInfo.profile.name, saveToCollections, key)];
                    case 3:
                        crop = _a.sent();
                        // async upload
                        return [4 /*yield*/, this.dataService.uploadFileToS3(crop.img, key)];
                    case 4:
                        // async upload
                        _a.sent();
                        cropInfo = crop.data;
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        exc_1 = _a.sent();
                        throw new ErrorMessage(ErrorMessage.ERROR, "Could not save image", exc_1);
                    case 7:
                        json = {
                            tags: tags,
                            thumbnails: thumbnails,
                            crop: cropInfo,
                            layoutName: this.layoutName,
                            inspirationalParent: this.inspirationalParentCheck,
                            inspirationalCrop: this.inspirationalCropCheck,
                            extraInfo: { taggedBy: this.adal.userInfo.profile.name },
                            colorInfo: colorInfo,
                            baseImage: this.selectedImage.id,
                            baseImageName: this.selectedImage.name,
                            oid: this.adal.userInfo.profile.oid,
                        };
                        return [4 /*yield*/, this.dataService.saveTagsNew(this.snackBar, id, json)];
                    case 8: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     *
     * @param imageId
     */
    OperationContext.prototype.getImage = function (imageId) {
        return this.dataService.getImage(imageId);
    };
    /**
     * Download an image from Bynder
     * @param id
     */
    OperationContext.prototype.getDownloadImageUrl = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.getDownloadImageUrl(id).subscribe(function (result) {
                resolve(result.s3_file);
            }, function (error) {
                reject(error);
            });
        });
    };
    /**
     * Visual search
     * @param base64
     */
    OperationContext.prototype.searchIVS = function (base64) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.searchIVS(base64).subscribe(function (result) {
                resolve(result);
            }, function (error) {
                reject(error);
            });
        });
    };
    /**
     * Upload file
     * @param file
     */
    OperationContext.prototype.uploadFile = function (file) {
        return this.dataService.uploadFile(file);
    };
    /**
     * Search for products
     * @param text
     */
    OperationContext.prototype.searchSPR = function (text) {
        var _this = this;
        if (text == "") {
            return new Promise(function (resolve) {
                resolve([]);
            });
        }
        return new Promise(function (resolve, reject) {
            // this.dataService.searchSPR(text)
            _this.dataService.searchPIA(text).subscribe(function (result) {
                resolve(Helpers.getProductItems(result));
            }, function (error) {
                reject(error);
            });
        });
    };
    /**
     *
     * @param text
     */
    OperationContext.prototype.searchPIA = function (text) {
        var _this = this;
        if (text == "") {
            return new Promise(function (resolve) {
                resolve([]);
            });
        }
        return new Promise(function (resolve, reject) {
            _this.dataService.searchPIA(text).subscribe(function (result) {
                resolve(Helpers.getProductItems(result));
            }, function (error) {
                reject(error);
            });
        });
    };
    /**
     * Get autocrop crops based on uploaded File
     */
    OperationContext.prototype.getAutocrops = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.getProductSuggestions(id).subscribe(function (result) {
                resolve(result.crops);
            }, function (error) {
                reject(error);
            });
        });
    };
    /**
     * Get product suggestions based on uploaded File
     */
    OperationContext.prototype.getProductSuggestions = function (id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.getProductSuggestions(id).subscribe(function (result) {
                resolve(result.products);
            }, function (error) {
                reject(error);
            });
        });
    };
    OperationContext.prototype.loadIDAMProductTags = function () {
        return __awaiter(this, void 0, void 0, function () {
            var products, img, promises;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.fetchIDAMProducts(this.selectedImage.name)];
                    case 1:
                        products = _a.sent();
                        img = {
                            width: this.selectedImage["canvasWidth"],
                            height: this.selectedImage["canvasHeight"],
                        };
                        if (!products || !Array.isArray(products)) {
                            console.log("No products found");
                            this.error = new ErrorMessage(ErrorMessage.INFO, "No products found for asset \"" + this.selectedImage.name + "\"", "");
                            return [2 /*return*/, []];
                        }
                        promises = products.map(function (d) { return __awaiter(_this, void 0, void 0, function () {
                            var type, fullArtNo, left, top, width, height, tagItem, matchedProduct;
                            return __generator(this, function (_a) {
                                type = d.product.type === "IKEAO.ItemType.ART" ? "art" : "spr";
                                fullArtNo = type + "." + d.product.no;
                                left = d.boundingBox.left * img.width;
                                top = d.boundingBox.top * img.height;
                                width = d.boundingBox.width * img.width;
                                height = d.boundingBox.height * img.height;
                                tagItem = TagItem.create({
                                    box: {
                                        l: left,
                                        t: top,
                                        w: width,
                                        h: height,
                                    },
                                    dot: {
                                        x: width / 2 + left,
                                        y: height / 2 + top,
                                    },
                                });
                                matchedProduct = this.selectedImage.imageTags.find(function (item) {
                                    var box = item.box.boundingBox();
                                    var newBox = tagItem.boundingBox();
                                    return (Math.round(box.left) === newBox.left &&
                                        Math.round(box.top) === newBox.top &&
                                        Math.round(box.width) === newBox.width &&
                                        Math.round(box.height) === newBox.height);
                                });
                                if (matchedProduct === undefined) {
                                    this.selectedImage.imageTags.push(tagItem);
                                    tagItem.isModified = true;
                                    this.dataService
                                        .searchPIA(fullArtNo)
                                        .toPromise()
                                        .then(function (result) {
                                        var rawProduct = result.products[0];
                                        if (rawProduct) {
                                            var product = ProductItem.create(__assign({}, rawProduct, { type: type }));
                                            tagItem.prd = product;
                                        }
                                    })
                                        .catch(function (err) {
                                        console.log("getProductSuggestions", err);
                                    });
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return OperationContext;
}());
export { OperationContext };
