import { JsonConverter } from "../helpers/json.converter";
import { environment } from "../../environments/environment";
export var ImageState;
(function (ImageState) {
    ImageState[ImageState["NOT_STARTED"] = 0] = "NOT_STARTED";
    ImageState[ImageState["UNFINISHED"] = 1] = "UNFINISHED";
    ImageState[ImageState["COMPLETE"] = 2] = "COMPLETE";
    ImageState[ImageState["ERROR"] = 3] = "ERROR";
})(ImageState || (ImageState = {}));
function ensureParsable(data, fallback) {
    if (fallback === void 0) { fallback = "[]"; }
    try {
        JSON.parse(data);
    }
    catch (e) {
        console.warn("Could not parse data", data);
        return fallback;
    }
    return data;
}
var ImageItem = /** @class */ (function () {
    function ImageItem(index, id, description, name, bynderId, thumbnails, $isModified, imageTags, $options, width, height, dateLoaded, state, productCount, identfiedProductCount, $isDisabled, extension, old) {
        this.index = index;
        this.id = id;
        this.description = description;
        this.name = name;
        this.bynderId = bynderId;
        this.thumbnails = thumbnails;
        this.$isModified = $isModified;
        this.imageTags = imageTags;
        this.$options = $options;
        this.width = width;
        this.height = height;
        this.dateLoaded = dateLoaded;
        this.state = state;
        this.productCount = productCount;
        this.identfiedProductCount = identfiedProductCount;
        this.$isDisabled = $isDisabled;
        this.extension = extension;
        this.old = old;
        this.old = false;
    }
    ImageItem.create = function (data) {
        var imageItem = Object.create(ImageItem.prototype);
        Object.assign(imageItem, {
            id: data.id,
            name: data.name,
            imageTags: [],
            $options: [],
            thumbnails: data.thumbnails,
            isModified: false,
            width: data.width,
            height: data.height,
            rawTags: ensureParsable(data["property_product_coordinates"]),
            dateLoaded: Date.now(),
            state: ImageState.NOT_STARTED,
            isDisabled: data.width == 0 ||
                data.height == 0 ||
                (data.extension &&
                    data.extension.length > 0 &&
                    data.extension[0].toLowerCase() != "png" &&
                    data.extension[0].toLowerCase() != "jpg" &&
                    data.extension[0].toLowerCase() != "jpeg"),
            extension: data.extension && data.extension.length > 0 ? data.extension[0] : "",
            old: false,
        });
        var tags = JsonConverter.fromString(imageItem["rawTags"], {
            width: 1,
            height: 1,
        });
        imageItem.state = ImageItem.getState(imageItem, tags);
        imageItem.productCount = tags.length;
        imageItem.identfiedProductCount = tags.filter(function (tag) { return tag.prd.id !== ""; }).length;
        return imageItem;
    };
    ImageItem.getState = function (image, tags) {
        if (image.isDisabled) {
            return ImageState.ERROR;
        }
        else if (tags.length == 0) {
            return ImageState.NOT_STARTED;
        }
        else if (tags.filter(function (tag) { return tag.prd.id !== ""; }).length != tags.length) {
            return ImageState.UNFINISHED;
        }
        return ImageState.COMPLETE;
    };
    ImageItem.prototype.deleteTag = function (tagItem) {
        var index = this.imageTags.indexOf(tagItem);
        if (index < 0) {
            return;
        }
        this.imageTags.splice(index, 1);
        this.$isModified = true;
    };
    ImageItem.prototype.parseTags = function () {
        this.imageTags = JsonConverter.fromString(this["rawTags"], {
            width: this["canvasWidth"],
            height: this["canvasHeight"],
        });
    };
    ImageItem.prototype.saveTags = function () {
        var json = JsonConverter.toJSON(this.imageTags, {
            width: this["canvasWidth"],
            height: this["canvasHeight"],
        });
        return json;
    };
    ImageItem.prototype.moveTag = function (to, from) {
        this.imageTags.splice(to, 0, this.imageTags.splice(from, 1)[0]);
        this.isModified = true;
        this.imageTags[to].isModified = true;
    };
    ImageItem.prototype.sortTagsByAreaSize = function () {
        if (!this.imageTags) {
            return;
        }
        this.imageTags.sort(function (a, b) {
            var abox = a.box.boundingBox();
            var bbox = b.box.boundingBox();
            return bbox.width * bbox.height - abox.width * abox.height;
        });
        this.imageTags.forEach(function (t) {
            t.isModified = true;
        });
    };
    Object.defineProperty(ImageItem.prototype, "isDisabled", {
        get: function () {
            return this.$isDisabled;
        },
        set: function (value) {
            this.$isDisabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageItem.prototype, "isModified", {
        get: function () {
            for (var _i = 0, _a = this.imageTags; _i < _a.length; _i++) {
                var tagItem = _a[_i];
                if (tagItem.isModified) {
                    return true;
                }
            }
            return this.$isModified;
        },
        set: function (value) {
            this.$isModified = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageItem.prototype, "thumbnail", {
        get: function () {
            var thumb = this.thumbnails.webimage;
            if (thumb === undefined) {
                return "/assets/na.png";
            }
            return (thumb.replace(environment.bynderImageCDN, environment.akamaiImageCDN) +
                "?f=s");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageItem.prototype, "options", {
        get: function () {
            return this.$options;
        },
        set: function (value) {
            this.$options = value;
        },
        enumerable: true,
        configurable: true
    });
    ImageItem.prototype.clearIsModified = function () {
        this.isModified = false;
        for (var _i = 0, _a = this.imageTags; _i < _a.length; _i++) {
            var tagItem = _a[_i];
            tagItem.isModified = false;
        }
    };
    return ImageItem;
}());
export { ImageItem };
