import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperationContext, State } from '../../services/operation.context';
import { CollectionItem, TypeEnum } from '../../models/collection.item';

@Component({
  selector: 'collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {
  constructor(private router: Router,
    public operationContext: OperationContext) { }

  ngOnInit() { }

  select(collection: CollectionItem) {
    sessionStorage.setItem('collection', collection.id);
    this.operationContext.selectedCollection = collection;
    this.router.navigate(['images']);
  }

  getImage(collection: CollectionItem) {
    return collection.thumbnail;
  }

  hasCollections() {
    return this.operationContext.collections && this.operationContext.collections.length > 0;
  }

  collectionsLoaded() {
    return this.operationContext.collectionsLoaded;
  }

  togglePanel(key) {
    let item = this.operationContext.groupedCollections[key];
    item['expanded'] = !item['expanded'];

    localStorage.setItem(`${key}_expanded`, item['expanded'].toString());
  }

  isExpanded(key) {
    let item = this.operationContext.groupedCollections[key];
    
    if (item['expanded'] === undefined) {
      item['expanded'] = localStorage.getItem(`${key}_expanded`) === "true";
    } 

    return item['expanded'];
  }

  getItemCount(key) {
    let item = this.operationContext.groupedCollections[key];

    if (item) {
      return item['length'];
    }

    return 0;
  }
}
