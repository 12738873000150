import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { ImageItem } from "../models/image.item";
import { ProductItem } from "../models/product.item";
import { CollectionItem, CollectionState } from "../models/collection.item";
import { CropItem } from "../models/renderable/crop.item";
import { CropSelection } from "../models/renderable/crop.selection";
import { ISelection } from "../models/renderable/selection";
import { ImageHelper } from "../helpers/image.helper";
import { ErrorMessage } from "../models/error.message";
import { Rect } from "../models/renderable/rect";
import { AdalService } from "adal-angular4";
import { OverlayService } from "./overlay.service";
import { Helpers } from "../helpers/common";
import { SnackBarService } from "./snackbar.service";
import { environment } from "../../environments/environment";
import { MemoryContext } from "../helpers/memory.context";
import { LogoutService } from "./logout.service";
import Fraction from "fraction.js";
import { TagItem } from "../models/renderable/tag.item";

export enum State {
  IDLE = 0x01,
  LOADING = 0x02,
  SAVING = 0x03,
}

/**
 * Global Singelton service class that can be injected in all components
 * Creates link to the data service and holds global states such as selections
 */
@Injectable()
export class OperationContext {
  private $images: ImageItem[] = null;
  private $groupedCollections: CollectionItem[] = null;
  private $collections: CollectionItem[] = null;
  private $collectionCache: { [index: string]: ImageItem[] } = {};
  private $selectedCollection: CollectionItem = null;
  private $selectedImage: ImageItem = null;
  private $tagSelection: ISelection = null;
  private $cropItem: CropItem = null;
  private $errorMessage: ErrorMessage = null;
  private $showBoxes: boolean = true;
  private $imagesLoaded: boolean = false;
  private $collectionsLoaded: boolean = false;
  private $aiEnabled: boolean = false;
  private $cropRatio: string = "9:16";
  private $state: State = State.IDLE;
  private $memoryContext: Promise<MemoryContext>;
  public layoutName: String;
  public inspirationalParentCheck: boolean = false;
  public inspirationalCropCheck: boolean = false;
  private $stateFilter: boolean[] = [true, true, true, true, true];

  constructor(
    private dataService: DataService,
    private adal: AdalService,
    private overlay: OverlayService,
    private snackBar: SnackBarService,
    private logout: LogoutService
  ) {
    this.$showBoxes = localStorage.getItem("showBoxes") === "1" ? true : false;
    this.$aiEnabled = localStorage.getItem("aiEnabled") === "1" ? true : false;
    if (localStorage.getItem("stateFilter")) {
      this.$stateFilter = localStorage
        .getItem("stateFilter")
        .split("")
        .map((c) => c === "1");
    }

    this.layoutName = "";

    const r = localStorage.getItem("cropRatioFraction");

    if (r !== null) {
      this.$cropRatio = r;
    }

    this.loadCollections();
  }

  /**
   *
   */
  get imagesLoaded(): boolean {
    return this.$imagesLoaded;
  }

  /**
   *
   */
  get collectionsLoaded(): boolean {
    return this.$collectionsLoaded;
  }

  /**
   *
   */
  get isModified(): boolean {
    return (
      (this.selectedImage && this.selectedImage.isModified) ||
      this.isCropEnabled ||
      this.inspirationalParentCheck ||
      this.inspirationalCropCheck
    );
  }

  /**
   *
   */
  get showBoxes(): boolean {
    return this.$showBoxes;
  }

  /**
   *
   */
  get cropRatio(): string {
    return this.$cropRatio;
  }

  /**
   *
   */
  get memoryContext(): Promise<MemoryContext> {
    return this.$memoryContext;
  }

  /**
   *
   */
  set memoryContext(value: Promise<MemoryContext>) {
    this.$memoryContext = value;
  }

  /**
   *
   */
  set state(value: State) {
    this.$state = value;

    switch (this.$state) {
      case State.IDLE: {
        this.overlay.hide();
        break;
      }

      case State.LOADING: {
        setTimeout(() => this.overlay.show());
        break;
      }

      case State.SAVING: {
        setTimeout(() => this.overlay.show());
        break;
      }
    }
  }

  /**
   *
   */
  get state(): State {
    return this.$state;
  }

  /**
   *
   */
  set showBoxes(value: boolean) {
    localStorage.setItem("showBoxes", value ? "1" : "0");
    this.$showBoxes = value;
  }

  /**
   *
   */
  set cropRatio(value: string) {
    localStorage.setItem("cropRatioFraction", `${value}`);
    this.$cropRatio = value;
  }

  /**
   *
   */
  get isAIEnabled(): boolean {
    return this.$aiEnabled;
  }

  get stateFilter(): boolean[] {
    return this.$stateFilter;
  }

  set stateFilter(value: boolean[]) {
    localStorage.setItem(
      "stateFilter",
      value.map((e) => (e ? "1" : "0")).join("")
    );
    this.$stateFilter = value;
  }

  /**
   *
   */
  set isAIEnabled(value: boolean) {
    localStorage.setItem("aiEnabled", value ? "1" : "0");
    this.$aiEnabled = value;
  }

  /**
   *
   */
  get isCropEnabled(): boolean {
    return this.selection instanceof CropSelection;
  }

  /**
   *
   */
  set isCropEnabled(value: boolean) {
    if (this.selectedImage === undefined) {
      return;
    }

    if (!this.selectedImage) {
      return;
    }

    if (this.isCropEnabled) {
      this.selection = null;
    } else {
      this.createCropItem();
      this.selection = new CropSelection(this.cropItem, this);
    }
  }

  /**
   *
   */
  get cropItem(): CropItem {
    return this.$cropItem;
  }

  /**
   *
   */
  set cropItem(value: CropItem) {
    this.$cropItem = value;
  }

  /**
   * Get all images
   */
  get images(): ImageItem[] {
    return this.$images;
  }

  /**
   *
   */
  get groupedCollections(): CollectionItem[] {
    return this.$groupedCollections;
  }

  /**
   * Get all collections
   */
  get collections(): CollectionItem[] {
    return this.$collections;
  }

  /**
   * The currently select image
   */
  get selectedImage(): ImageItem {
    return this.$selectedImage;
  }

  /**
   * The currently select image
   */
  set selectedImage(image: ImageItem) {
    this.$selectedImage = image;
  }

  /**
   * The image slider selection
   */
  get selection(): ISelection {
    return this.$tagSelection;
  }

  /**
   * The image slider selection
   */
  set selection(selection: ISelection) {
    this.$tagSelection = selection;
  }

  /**
   *
   */
  get error(): ErrorMessage {
    return this.$errorMessage;
  }

  /**
   * The image slider selection
   */
  set error(error: ErrorMessage) {
    this.$errorMessage = error;

    if (!this.$errorMessage) {
      return;
    }

    switch (this.$errorMessage.code) {
      case ErrorMessage.OK:
      case ErrorMessage.INFO:
        break;
      default:
        if (environment.debug) {
          console.log(
            "ERROR",
            this.$errorMessage.code,
            this.$errorMessage.details
          );
        }
    }

    if (
      this.$errorMessage.code == ErrorMessage.LOADERROR ||
      this.$errorMessage.code == ErrorMessage.LOG
    ) {
      return;
    }

    this.snackBar.show(this.$errorMessage.message, "", {
      duration: 3000,
      panelClass: this.$errorMessage.isOK ? "green" : "red",
      verticalPosition: "top",
      horizontalPosition: "center",
    });
  }

  /**
   * The currently selected collection
   */
  set selectedCollection(collection: CollectionItem) {
    this.$selectedCollection = collection;
    this.$images = null;
  }

  /**
   * The currently selected collection
   */
  get selectedCollection(): CollectionItem {
    return this.$selectedCollection;
  }

  /**
   *
   */
  clearSelection() {
    this.selection = null;
    this.error = null;
  }

  /**
   *
   */
  createCropItem() {
    let width: number;
    let height: number;

    const ratio =
      this.cropRatio === "free" ? 1 : new Fraction(this.cropRatio).valueOf();

    if (ratio > 0) {
      if (
        this.selectedImage["canvasWidth"] / ratio >=
        this.selectedImage["canvasHeight"]
      ) {
        height = this.selectedImage["canvasHeight"];
        width = this.selectedImage["canvasHeight"] * ratio;
      } else {
        width = this.selectedImage["canvasWidth"];
        height = this.selectedImage["canvasWidth"] / ratio;
      }
    } else {
      height = this.selectedImage["canvasHeight"];
      width = this.selectedImage["canvasWidth"];
    }
    // let x = ratio < 0 ? 0 : (this.selectedImage["canvasWidth"] - width) / 2;
    // let y = 0;

    this.cropItem = new CropItem(
      new Rect(0, 0, width, height),
      this.selectedImage["canvasWidth"],
      this.selectedImage["canvasHeight"],
      this.cropRatio
    );
  }

  /**
   * Load images from collection when a new collection has been selected
   * @param collectionId
   */
  loadImages(collectionId: string) {
    if (!this.$collectionCache[collectionId] && this.state != State.LOADING) {
      this.state = State.LOADING;
    }

    if (!this.$collectionCache[collectionId]) {
      this.$imagesLoaded = false;
    }

    this.$images = this.$collectionCache[collectionId];

    this.dataService
      .getImagesPaged(collectionId)
      .then((images) => {
        let imageItems = [];
        this.selectedCollection.state = CollectionState.UNKNOWN;

        for (let i = 0; i < images.length; i++) {
          let imageItem = ImageItem.create(images[i]);
          this.selectedCollection.updateState(imageItem);
          imageItems.push(imageItem);
        }

        if (this.selectedCollection.state == CollectionState.UNKNOWN) {
          this.$selectedCollection.state = CollectionState.COMPLETE;
        }

        this.$collectionCache[collectionId] = imageItems;
        this.$images = imageItems;
        this.$imagesLoaded = true;
        this.state = State.IDLE;
      })
      .catch((error) => {
        this.error = new ErrorMessage(
          ErrorMessage.LOG,
          "Error loading images",
          error
        );
        this.state = State.IDLE;
      });
  }

  /**
   * Load all available collections
   */
  loadCollections() {
    this.state = State.LOADING;
    this.$collectionsLoaded = false;

    this.dataService
      .getCollections()
      .then((collections) => {
        let collectionItems = [];
        let collectionId = sessionStorage.getItem("collection");

        for (let i = 0; i < collections.length; i++) {
          let collectionItem: CollectionItem = CollectionItem.create(
            collections[i]
          );

          if (collectionId == collections[i].id) {
            this.selectedCollection = collectionItem;
          }

          collectionItems.push(collectionItem);
        }

        this.$collections = collectionItems.sort((a, b) =>
          a
            .toString()
            .localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
        );
        this.$groupedCollections = this.groupBy(this.$collections, "type");

        this.state = State.IDLE;
      })
      .catch((error) => {
        this.error = new ErrorMessage(
          ErrorMessage.LOG,
          "Error loading collections",
          error
        );
        this.state = State.IDLE;
      });
  }

  /**
   *
   * @param xs
   * @param key
   */
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  /**
   * Save
   */
  async saveTags(): Promise<any> {
    let id = this.$selectedImage.id;
    let tags = this.$selectedImage.saveTags();
    let thumbnails = [];
    let cropInfo: any = {};
    let colorInfo: any = {};
    let length = JSON.stringify(tags).length;
    let needContext =
      this.$selectedImage.imageTags.length > 0 || this.isCropEnabled;
    let saveToCollections = [];

    /* Bynder has a limit of 2000 chars in a metaproperty */
    if (length > 2000) {
      throw new ErrorMessage(
        ErrorMessage.INFO,
        "Too many tag objects",
        "Tag string " + length
      );
    }

    if (this.isCropEnabled) {
      saveToCollections = this.collections
        .filter((obj) => {
          return obj.selected;
        })
        .map((item) => {
          return item.id;
        });

      if (saveToCollections.length == 0) {
        throw new ErrorMessage(
          ErrorMessage.INFO,
          "Please select at least one collection",
          "saveToCollections " + saveToCollections.length
        );
      }
    }

    if (needContext) {
      try {
        let memoryContext = await this.memoryContext;
        colorInfo = ImageHelper.getHistogram(memoryContext.context);

        if (this.$selectedImage.imageTags.length > 0) {
          thumbnails = ImageHelper.generateThumbnails(
            memoryContext,
            this.$selectedImage
          );
        }

        if (this.isCropEnabled) {
          let key = this.$selectedImage.id + "_crop.jpg";
          let crop = await ImageHelper.generateCrop(
            memoryContext,
            this.$selectedImage,
            this.$cropItem,
            this.adal.userInfo.profile.name,
            saveToCollections,
            key
          );

          // async upload
          await this.dataService.uploadFileToS3(crop.img, key);
          cropInfo = crop.data;
        }
      } catch (exc) {
        throw new ErrorMessage(ErrorMessage.ERROR, "Could not save image", exc);
      }
    }

    let json: any = {
      tags,
      thumbnails,
      crop: cropInfo,
      layoutName: this.layoutName,
      inspirationalParent: this.inspirationalParentCheck,
      inspirationalCrop: this.inspirationalCropCheck,
      extraInfo: { taggedBy: this.adal.userInfo.profile.name },
      colorInfo: colorInfo,
      baseImage: this.selectedImage.id,
      baseImageName: this.selectedImage.name,
      oid: this.adal.userInfo.profile.oid,
    };

    return await this.dataService.saveTagsNew(this.snackBar, id, json);

    // console.log(r);

    // return new Promise((resolve, reject) => {
    //   //resolve(new ErrorMessage(ErrorMessage.OK, 'Image successfully saved', ""));
    //   this.dataService.saveTags(id, json).subscribe(
    //     (result) => {
    //       resolve(
    //         new ErrorMessage(ErrorMessage.OK, "Image processing started.", "")
    //       );
    //     },
    //     (error) => {
    //       reject(
    //         new ErrorMessage(ErrorMessage.ERROR, "Could not save image", error)
    //       );
    //     }
    //   );
    // });
  }

  /**
   *
   * @param imageId
   */
  getImage(imageId: string): Promise<ImageItem> {
    return this.dataService.getImage(imageId);
  }

  /**
   * Download an image from Bynder
   * @param id
   */
  getDownloadImageUrl(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.getDownloadImageUrl(id).subscribe(
        (result) => {
          resolve(result.s3_file);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Visual search
   * @param base64
   */
  searchIVS(base64: string): Promise<ProductItem[]> {
    return new Promise((resolve, reject) => {
      this.dataService.searchIVS(base64).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Upload file
   * @param file
   */
  uploadFile(file): Promise<any> {
    return this.dataService.uploadFile(file);
  }

  /**
   * Search for products
   * @param text
   */
  searchSPR(text: string): Promise<ProductItem[]> {
    if (text == "") {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    return new Promise((resolve, reject) => {
      // this.dataService.searchSPR(text)
      this.dataService.searchPIA(text).subscribe(
        (result) => {
          resolve(Helpers.getProductItems(result));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   *
   * @param text
   */
  searchPIA(text: string): Promise<ProductItem[]> {
    if (text == "") {
      return new Promise((resolve) => {
        resolve([]);
      });
    }

    return new Promise((resolve, reject) => {
      this.dataService.searchPIA(text).subscribe(
        (result) => {
          resolve(Helpers.getProductItems(result));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Get autocrop crops based on uploaded File
   */
  getAutocrops(id: string): Promise<ProductItem[]> {
    return new Promise((resolve, reject) => {
      this.dataService.getProductSuggestions(id).subscribe(
        (result) => {
          resolve(result.crops);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Get product suggestions based on uploaded File
   */
  getProductSuggestions(id: string): Promise<ProductItem[]> {
    return new Promise((resolve, reject) => {
      this.dataService.getProductSuggestions(id).subscribe(
        (result) => {
          resolve(result.products);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async loadIDAMProductTags() {
    type ProductRes = {
      product: {
        name: string;
        no: string;
      } & {
        type?: string | undefined;
      };
      boundingBox: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
    };

    const products: ProductRes[] = await this.dataService.fetchIDAMProducts(
      this.selectedImage.name
    );

    const img = {
      width: this.selectedImage["canvasWidth"],
      height: this.selectedImage["canvasHeight"],
    };

    if (!products || !Array.isArray(products)) {
      console.log("No products found");
      this.error = new ErrorMessage(
        ErrorMessage.INFO,
        `No products found for asset "${this.selectedImage.name}"`,
        ""
      );
      return [];
    }
    const promises = products.map(async (d) => {
      // art

      const type = d.product.type === "IKEAO.ItemType.ART" ? "art" : "spr";

      const fullArtNo = `${type}.${d.product.no}`;

      const left = d.boundingBox.left * img.width;
      const top = d.boundingBox.top * img.height;
      const width = d.boundingBox.width * img.width;
      const height = d.boundingBox.height * img.height;

      let tagItem = TagItem.create({
        box: {
          l: left,
          t: top,
          w: width,
          h: height,
        },
        dot: {
          x: width / 2 + left,
          y: height / 2 + top,
        },
      });

      let matchedProduct = this.selectedImage.imageTags.find((item) => {
        let box = item.box.boundingBox();
        let newBox = tagItem.boundingBox();

        return (
          Math.round(box.left) === newBox.left &&
          Math.round(box.top) === newBox.top &&
          Math.round(box.width) === newBox.width &&
          Math.round(box.height) === newBox.height
        );
      });

      if (matchedProduct === undefined) {
        this.selectedImage.imageTags.push(tagItem);
        tagItem.isModified = true;

        this.dataService
          .searchPIA(fullArtNo)
          .toPromise()
          .then((result) => {
            let rawProduct = result.products[0];

            if (rawProduct) {
              let product: ProductItem = ProductItem.create({
                ...rawProduct,
                type: type,
              });
              tagItem.prd = product;
            }
          })
          .catch((err) => {
            console.log("getProductSuggestions", err);
          });
      }
    });

    return await Promise.all(promises);
  }
}
