import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subject } from 'rxjs';

/**
 * Usage
 *     const modalOptions = {
      initialState: {
        title: 'some title',
        otherData: 'some other value'
      },
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-lg'
    }

    this.bsModalRef = this.modalService.show(InfoModalComponent, modalOptions);
 */

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent {
  public result: Subject<boolean>;
  public title: string = 'Save';
  public otherData: string = '';

  constructor(public bsModalRef: BsModalRef) { }

  public ngOnInit(): void {
    this.result = new Subject();
  }

  public onConfirm(): void {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.result.next(true);
    this.bsModalRef.hide();
  }
}

