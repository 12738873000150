import { TagItem } from "../models/renderable/tag.item";

export class JsonConverter {  
  static fromString(...args): any {
    if (args[0] === undefined) {
      return [];
    }
    
    let data: TagItemJSON[] = JSON.parse(args[0]);
    let param = args[1];
    let width = param.width;
    let height = param.height;

    // convert to absolute coordinates
    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      item.box.l *= width;
      item.box.t *= height;
      item.box.w *= width;
      item.box.h *= height;
      
      item.dot.x *= width;
      item.dot.y *= height;
    }

    return TagItem.fromJSON(data);
  }

  static toJSON(...args) {
    let data: TagItem[] = args[0];
    let param = args[1];
    let width = param.width;
    let height = param.height;

    let items = [];

    for (let i = 0; i < data.length; i++) {
      let item = data[i].toJSON();

      item.box.l = (item.box.l / width).toFixed(3);
      item.box.t = (item.box.t / height).toFixed(3);
      item.box.w = (item.box.w / width).toFixed(3);
      item.box.h = (item.box.h / height).toFixed(3);
      
      item.dot.x = Math.abs(item.dot.x / width).toFixed(3);
      item.dot.y = Math.abs(item.dot.y / height).toFixed(3);

      items.push(item);
    }

    return items;
  }
}