import { AdalService } from "adal-angular4";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

const MINUTES_UNITL_AUTO_LOGOUT = environment.timeout; // in mins

@Injectable()
export class LogoutService {

  timer = null;

  constructor(public adal: AdalService, public router: Router) {
    if (environment.autologout) {
      this.reset();
      this.initListener();
    }
  }

  initListener() {
    window.addEventListener('click', () => this.reset());
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('keyup', () => this.reset());
  }

  reset() {
    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.adal.logOut();
    }, 1000 * 60 * MINUTES_UNITL_AUTO_LOGOUT);
  }
}