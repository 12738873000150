import { Context } from "./context";

export class MemoryContext extends Context {
  private $metadata: any[];

  get metadata(): any[] {
    return this.$metadata;
  }

  set metadata(value: any[]) {
    this.$metadata = value;
  }

  clone() {
    let canvas = document.createElement('canvas');
    return canvas.getContext('2d');
  }
}