var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectionHandle } from "./selection.handle";
import { Selection } from "./selection";
import { Point } from "./point";
var ResizeSelection = /** @class */ (function (_super) {
    __extends(ResizeSelection, _super);
    function ResizeSelection(item) {
        var _this = _super.call(this, item) || this;
        _this.offset = 0;
        _this.scaleX = 0;
        _this.scaleY = 0;
        _this.then = performance.now();
        _this.$selectionHandles = [];
        _this.$selectionHandles.push(new SelectionHandle(0, new Point(0, 0)));
        _this.$selectionHandles.push(new SelectionHandle(1, new Point(0.5, 0)));
        _this.$selectionHandles.push(new SelectionHandle(2, new Point(1, 0)));
        _this.$selectionHandles.push(new SelectionHandle(3, new Point(0, 0.5)));
        _this.$selectionHandles.push(new SelectionHandle(4, new Point(1, 0.5)));
        _this.$selectionHandles.push(new SelectionHandle(5, new Point(0, 1)));
        _this.$selectionHandles.push(new SelectionHandle(6, new Point(0.5, 1)));
        _this.$selectionHandles.push(new SelectionHandle(7, new Point(1, 1)));
        item.parent().focus();
        return _this;
    }
    Object.defineProperty(ResizeSelection.prototype, "selectionHandles", {
        get: function () {
            return this.$selectionHandles;
        },
        enumerable: true,
        configurable: true
    });
    ResizeSelection.prototype.renderHandle = function (context, handle) {
        var box = this.selectedItem.boundingBox();
        context.save();
        context.beginPath();
        context.transform(box.width, 0, 0, box.height, box.left - 5, box.top - 5);
        handle.render(context, box);
        context.restore();
    };
    ResizeSelection.prototype.renderContext = function (context, point, scaleX, scaleY) {
        context.save();
        context.beginPath();
        context.transform(this.currentHandle.rect.width, 0, 0, this.currentHandle.rect.height, this.currentHandle.rect.left, this.currentHandle.rect.top);
        context.translate(point.x, point.y);
        context.scale(scaleX, scaleY);
    };
    ResizeSelection.prototype.resize = function (memoryContext, x, y) {
        var context = memoryContext.context;
        this.scaleX = (this.currentHandle.rect.left - x + this.currentHandle.rect.width) / this.currentHandle.rect.width;
        this.scaleY = (this.currentHandle.rect.top - y + this.currentHandle.rect.height) / this.currentHandle.rect.height;
        switch (this.currentHandle.id) {
            case 0: {
                this.renderContext(context, new Point(1, 1), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left + this.currentHandle.rect.width - w, this.currentHandle.rect.top + this.currentHandle.rect.height - h, w, h);
                break;
            }
            case 1: {
                this.renderContext(context, new Point(0.5, 1), 1, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left, this.currentHandle.rect.top + this.currentHandle.rect.height - h, this.currentHandle.rect.width, h);
                break;
            }
            case 2: {
                this.renderContext(context, new Point(0, 1), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left, this.currentHandle.rect.top + this.currentHandle.rect.height - h, this.currentHandle.rect.width - w, h);
                break;
            }
            case 3: {
                this.renderContext(context, new Point(1, 0.5), this.scaleX, 1);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left + this.currentHandle.rect.width - w, this.currentHandle.rect.top, w, this.currentHandle.rect.height);
                break;
            }
            case 4: {
                this.renderContext(context, new Point(0.5, 1), this.scaleX, 1);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left, this.currentHandle.rect.top, this.currentHandle.rect.width - w, this.currentHandle.rect.height);
                break;
            }
            case 5: {
                this.renderContext(context, new Point(1, 0), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left + this.currentHandle.rect.width - w, this.currentHandle.rect.top, w, this.currentHandle.rect.height - h);
                break;
            }
            case 6: {
                this.renderContext(context, new Point(0.5, 0), 1, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left, this.currentHandle.rect.top, this.currentHandle.rect.width, this.currentHandle.rect.height - h);
                break;
            }
            case 7: {
                this.renderContext(context, new Point(0, 0), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var w = matrix[0] || matrix['a'];
                var h = matrix[3] || matrix['d'];
                context.restore();
                this.selectedItem.parent().update(this.currentHandle.rect.left, this.currentHandle.rect.top, this.currentHandle.rect.width - w, this.currentHandle.rect.height - h);
                break;
            }
        }
    };
    ResizeSelection.prototype.renderOutline = function (context) {
        var now = performance.now();
        var delta = now - this.then;
        var box = this.selectedItem.boundingBox();
        context.save();
        context.beginPath();
        context.rect(box.left, box.top, box.width, box.height);
        context.setLineDash([8, 8]);
        context.lineDashOffset = -this.offset;
        context.strokeStyle = "rgba(255,255,255,1)";
        context.lineWidth = 2;
        context.stroke();
        context.restore();
        if (delta > 10) {
            this.offset++;
            if (this.offset >= 16) {
                this.offset = 0;
            }
            this.then = now;
        }
    };
    ResizeSelection.prototype.render = function (context) {
        if (!this.selectedItem) {
            return;
        }
        for (var i = 0; i < this.$selectionHandles.length; i++) {
            var handle = this.$selectionHandles[i];
            this.renderHandle(context, handle);
        }
        this.renderOutline(context);
    };
    ResizeSelection.prototype.renderCursor = function (mouseDown) {
        if (mouseDown) {
            return '-webkit-grabbing';
        }
        else {
            return 'grab';
        }
    };
    return ResizeSelection;
}(Selection));
export { ResizeSelection };
