import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { OperationContext } from '../../services/operation.context';
import { TagItem } from '../../models/renderable/tag.item';
import { ProductItem } from '../../models/product.item';

const KEY_TAB = 9;

@Component({
  selector: 'tagslistitem',
  templateUrl: './tagslistitem.component.html',
  styleUrls: ['./tagslistitem.component.css']
})
export class TagsListItemComponent implements OnChanges {
  @Input() tagItem: TagItem;
  @Input() options: ProductItem[];
  @ViewChild('searchItem') searchItem: any;

  constructor(public operationContext: OperationContext) { }

  ngOnChanges(changes: SimpleChanges) {
    changes.tagItem.currentValue.focus(() => {
      if (this.searchItem === undefined) {
        return;
      }

      this.searchItem.focus();
    })
  }

  allowDrop(ev) {
    ev.preventDefault();
  }
  
  drop(ev) {
    ev.preventDefault();
    let currentIndex = this.operationContext.selectedImage.imageTags.indexOf(this.tagItem);
    var newIndex = ev.dataTransfer.getData("tagItemIndex");

    if (currentIndex < 0 || currentIndex > this.operationContext.selectedImage.imageTags.length - 1) {
      return;
    }

    if (newIndex < 0 || newIndex > this.operationContext.selectedImage.imageTags.length - 1) {
      return;
    }

    this.moveItem(currentIndex, newIndex);
  }

  drag(ev) {
    let index = this.operationContext.selectedImage.imageTags.indexOf(this.tagItem);
    ev.dataTransfer.setData("tagItemIndex", index);
    ev.dataTransfer.effectAllowed = 'move';
    ev.dataTransfer.setDragImage(ev.target.parentElement, ev.offsetX, ev.offsetY);
  }

  moveItem(to, from) {
    this.operationContext.selectedImage.moveTag(to, from);
  }

  getColor(item: any) {
    if (!item) {
      return 'lightgray';
    }

    return item.box.color;
  }

  select() {
    this.tagItem.isSelected = true;
  }

  tab(event) {
    if (this.searchItem.isOpen) {
      return;
    }
    
    if (event.keyCode == KEY_TAB) {
      this.select();
    }
  }

  delete() {
    this.operationContext.selectedImage.deleteTag(this.tagItem);
    this.operationContext.clearSelection();
  }

  toggleVisibility() {
    this.tagItem.isVisible = !this.tagItem.isVisible;
  }

  isInCropRegion() {
    if (!this.operationContext.isCropEnabled) {
      return true;
    }

    return this.operationContext.cropItem.contains(this.tagItem.dot.location);
  }
}