export class Rect {
  left: number;
  top: number;
  width: number;
  height: number;

  constructor(left: number, top: number, width: number, height: number) {
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
  }

  toString() {
    return { 
      l: this.left.toFixed(0), 
      t: this.top.toFixed(0), 
      w: this.width.toFixed(0), 
      h: this.height.toFixed(0) 
    }
  }
}