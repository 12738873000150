import { Injectable } from "@angular/core";
import { ImageItem } from "../models/image.item";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CollectionItem } from "../models/collection.item";
import { Observable } from "rxjs";
import { ProductItem } from "../models/product.item";
import { ErrorMessage } from "../models/error.message";
import { SnackBarService } from "./snackbar.service";

export function formatDate(dt: Date): string {
  return `${dt.getFullYear().toString().padStart(4, "0")}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
}

export type TicketResponse = ITicketSuccessResponse | ITicketNotFoundResponse;
export type AllTicketsResponse =
  | IAllTicketsSuccessResponse
  | ITicketNotFoundResponse;

export interface ITicketNotFoundResponse {
  StatusCode: 404;
  Message: "Not Found";
}

export interface ITicketSuccessResponse {
  StatusCode: 200;
  data: Data;
}

export interface IAllTicketsSuccessResponse {
  StatusCode: 200;
  data: AllData;
}

export interface Data {
  Item: Item;
}

export interface AllData {
  Items: Item[];
}

export interface Item {
  messages: MessageElement[];
  assetId: string;
  last_update: number;
  ticketId: string;
  started: number;
  createdBy: string;
  name: string;
}

export interface MessageElement {
  message: string[] | string | { assetName: string };
  type: string;
  group: string;
  timestamp: number;
}

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function isCompletedTicket(r: Item) {
  const newAssetStarted = r.messages.find(
    (q) => q.type === "new-asset-message-sent" && q.group == "new-asset"
  );
  const newAssetCompleted = r.messages.find(
    (q) => q.type === "new-bynder-asset-added" && q.group == "new-asset"
  );

  const updateAssetStarted = r.messages.find(
    (q) => q.type === "update-asset-message-sent" && q.group == "update-asset"
  );
  const updateAssetCompleted = r.messages.find(
    (q) => q.type === "bynder-asset-updated" && q.group == "update-asset"
  );

  const a = Boolean(newAssetStarted) === Boolean(newAssetCompleted);
  const b = Boolean(updateAssetStarted) === Boolean(updateAssetCompleted);

  return {
    completed: a && b,
    isNewAsset: a,
    isUpdatedAsset: b,
    newAssetStarted,
    newAssetCompleted,
    updateAssetStarted,
    updateAssetCompleted,
  };
}

export function isFailedTicket(r: Item): MessageElement | null {
  const failTypes = ["new-bynder-asset-failed", "update-asset-failed"];

  for (const m of r.messages) {
    for (const f of failTypes) {
      // check all fails
      if (m.type === f) {
        return m;
      }
    }
  }

  return null;
}

@Injectable()
export class DataService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param collection
   */
  async getImages(collection?: any): Promise<ImageItem[]> {
    let url = environment.url + "/media/?collectionId=" + collection;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<ImageItem[]>(url, httpOptions).toPromise();
  }

  /**
   * fetches all images in a collection, with multiple calls
   */
  async getImagesPaged(collection: string): Promise<ImageItem[]> {
    let page = 1;
    const out: ImageItem[] = [];

    while (true) {
      const r = await this.getImagesPage(collection, page);
      console.log(page, r);

      if (r.results) {
        out.push(...r.results);
      }

      if (r.isMaxCountResponse) {
        // we got maximum number of response images back
        page += 1;
      } else {
        break;
      }
    }

    return out;
  }

  async getImagesPage(
    collection: any,
    page: number
  ): Promise<{ isMaxCountResponse: boolean; results: ImageItem[] }> {
    let url =
      environment.url +
      "/media-paged/?collectionId=" +
      collection +
      "&page=" +
      String(page);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http
      .get<{ isMaxCountResponse: boolean; results: ImageItem[] }>(
        url,
        httpOptions
      )
      .toPromise();
  }

  /**
   *
   * @param id
   */
  getImage(id?: string): Promise<ImageItem> {
    let url = environment.url + "/media/asset/" + id;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<ImageItem>(url, httpOptions).toPromise<ImageItem>();
  }

  /**
   *
   */
  async getCollections(): Promise<CollectionItem[]> {
    let url = environment.url + "/collections";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<CollectionItem[]>(url, httpOptions).toPromise();
  }

  /**
   *
   */
  getDownloadImageUrl(id: string): Observable<any> {
    let url = environment.url + "/media/" + id + "/download";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions);
  }

  /**
   *
   * @param text
   */
  searchSPR(text: string): Observable<any> {
    let url = environment.url + "/search/spr/" + text;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions);
  }

  /**
   *
   * @param keyword
   */
  searchKeyword(keyword: string): Promise<any> {
    let url =
      environment.url + "/search/bynder/?parameter=keyword&value=" + keyword;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions).toPromise();
  }

  /**
   *
   * @param text
   */
  searchPIA(text: string): Observable<any> {
    let url =
      environment.url +
      "/search/pia/" +
      text +
      "?countryCode=SE&languageCode=en";

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions);
  }

  /**
   *
   * @param base64
   */
  searchIVS(base64?: string): Observable<ProductItem[]> {
    let url = environment.url + "/search/ivs";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    let params = new HttpParams().append("data", base64);

    return this.http.post<ProductItem[]>(url, params, httpOptions);
  }

  /**
   *
   */
  getProductSuggestions(id: string): Observable<any> {
    let url = environment.url + "/content/" + id;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions);
  }

  /**
   *
   * @param imageId
   * @param data
   */
  saveTags(imageId?: string, data?: string): Observable<any> {
    let url = environment.url + "/media";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    let json = {
      assetId: imageId,
      tagObject: data,
    };

    return this.http.post<any>(url, json, httpOptions);
  }

  async saveTagsNew(
    snackBar: SnackBarService,
    imageId?: string,
    data?: string
  ): Promise<any> {
    let url = environment.url + "/media";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    let json = {
      assetId: imageId,
      tagObject: data,
    };
    const showMsg = (
      panelClass: "green" | "red" | "blue",
      msg: string,
      detail: string = ""
    ) => {
      snackBar.show(msg, detail, {
        duration: 5000,
        panelClass: [panelClass, "testing"],
        verticalPosition: "top",
        horizontalPosition: "center",
      });
    };

    // const dd = await this.getAllTickets();
    // console.log(dd);
    // await sleep(10000000);
    const res = this.http.post<any>(url, json, httpOptions);

    return new Promise((resolve, reject) => {
      res.subscribe(
        async (result) => {
          console.log(result);
          showMsg("blue", "Image processing started.");

          resolve(
            new ErrorMessage(ErrorMessage.OK, "Image processing started.", "")
          );

          setTimeout(async () => {
            for (let i = 0; i < 36 * 2; i++) {
              const r = await this.checkTicket(result.TicketId);

              console.log(r);

              if (r.StatusCode === 404) {
                showMsg(
                  "red",
                  "Could not check save status" + " \n " + r.Message
                );
                return;
              }

              const isFailedCheckResult = isFailedTicket(r.data.Item);

              if (isFailedCheckResult !== null) {
                showMsg(
                  "red",
                  isFailedCheckResult.type +
                    " \n" +
                    JSON.stringify(isFailedCheckResult.message, null, 4)
                );
                return;
              }

              const checkResult = isCompletedTicket(r.data.Item);

              if (checkResult.completed) {
                return;
                // if (checkResult.isNewAsset) {
                //   const a = r.data.Item.messages.find(
                //     (q) =>
                //       q.group === "new-asset" &&
                //       q.type === "uploading-to-bynder"
                //   );

                //   let assetName = "unknown";
                //   if (
                //     a &&
                //     typeof a.message === "object" &&
                //     "assetName" in a.message
                //   ) {
                //     assetName = a.message.assetName;
                //   }

                //   const b = r.data.Item.messages.find(
                //     (q) =>
                //       q.group === "new-asset" && q.type === "adding-to-boards"
                //   );

                //   let boards = Array.isArray(b)
                //     ? b.join(", ")
                //     : JSON.stringify(b, null, 4);

                //   showMsg("green", `"${assetName}" saved.`, "");
                //   return;
                // } else if (checkResult.isUpdatedAsset) {
                //   showMsg("green", `Image saved.`, "");
                //   return;
                // }
              }

              // show last message

              // const messages = r.data.Item.messages.sort(
              //   (a, b) => a.timestamp - b.timestamp
              // );

              // showMsg(
              //   "blue",
              //   "Image processing started.\n" +
              //     messages
              //       .map(
              //         (m) =>
              //           `${this.formatDate(new Date(m.timestamp))} - ${m.type}`
              //       )
              //       .join("\n")
              // );

              await sleep(5 * 1000);
            }

            resolve(
              new ErrorMessage(
                ErrorMessage.ERROR,
                `Processing did not complete in 5 min.`,
                ""
              )
            );
          }, 0);
        },
        (error) => {
          reject(
            new ErrorMessage(ErrorMessage.ERROR, "Could not save image", error)
          );
        }
      );
    });
  }

  /**
   *
   * @param imageId
   * @param data
   */
  checkTicket(ticketId: string): Promise<TicketResponse> {
    let url = environment.url + "/media";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    let json = {
      ticketId,
    };

    const r = this.http.post<any>(url, json, httpOptions);

    return new Promise((resolve, reject) => {
      r.subscribe(resolve, reject);
    });
  }

  getAllTickets(): Promise<any> {
    let url = environment.url + "/media";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    let json = {
      getAllTicketsForUser: true,
    };

    const r = this.http.post<any>(url, json, httpOptions);

    return new Promise((resolve, reject) => {
      r.subscribe(resolve, reject);
    });
  }

  /**
   *
   * @param imageUrl
   */
  detect(imageUrl: string): Promise<any> {
    let url = environment.url + "/ai/detect";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "text/plain");

    const httpOptions = {
      headers: headers,
    };

    return this.http.post<any>(url, imageUrl, httpOptions).toPromise();
  }

  fetchIDAMProducts(name: string): Promise<any> {
    console.log("fetching products");
    const url = `${
      environment.ladanUrl
    }/api/idam/asset-products/${encodeURIComponent(name)}`;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions).toPromise();
  }

  /**
   *
   * @param imageUrl
   * @param boundingBoxes
   */
  identify(imageUrl: string, boundingBoxes: any[]): Promise<any> {
    let url = environment.url + "/ai/identify";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http
      .post<any>(
        url,
        {
          url: imageUrl,
          boundingBox: boundingBoxes,
        },
        httpOptions
      )
      .toPromise();
  }

  /**
   *
   * @param file
   */
  uploadFile(file): Promise<any> {
    let url = environment.url + "/content/upload";
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.post<any>(url, file, httpOptions).toPromise();
  }

  /**
   *
   * @param id
   */
  private getPresignedURL(key: string): Promise<any> {
    let url = environment.url + "/asset/signedurl/upload/put/" + key;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: headers,
    };

    return this.http.get<any>(url, httpOptions).toPromise<any>();
  }

  /**
   *
   * @param file
   */
  uploadFileToS3(base64, key): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let blob = await fetch(base64).then((res) => res.blob());
        let url = await this.getPresignedURL(key);
        let xhr = new XMLHttpRequest();

        xhr.open("PUT", url.signedUrl, true);

        xhr.onload = function () {
          if (xhr.readyState == 4 && xhr.status == 200) {
            resolve(
              new ErrorMessage(
                ErrorMessage.OK,
                "Cropped image successfully uploaded",
                ""
              )
            );
          } else {
            reject(
              new ErrorMessage(
                ErrorMessage.ERROR,
                "Error uploading cropped image",
                ""
              )
            );
          }
        };

        let file = new File([blob], key);
        xhr.send(file);
      } catch (exc) {
        reject(
          new ErrorMessage(
            ErrorMessage.ERROR,
            "Error uploading cropped image",
            exc
          )
        );
      }
    });
  }
}
