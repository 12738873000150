import { TagItem } from "../models/renderable/tag.item";
import { Selection } from "../models/renderable/selection";
import { CropItem } from "../models/renderable/crop.item";
import { MemoryContext } from "./memory.context";

export class Hittester {
  static hittest(memoryContext: MemoryContext, x: number, y: number, items: any[]): HittestInfo {
    let context = memoryContext.context;
    
    for (let i = 0; i < items.length; i++) {
      let item = items[i];

      if (item instanceof Selection) {
        for (let j = 0; j < item.selectionHandles.length; j++) {
          let handle = item.selectionHandles[j];

          item.renderHandle(context, handle);

          if (context.isPointInPath(x, y)) {
            return new HittestInfo(handle);
          }
        }
      } else if (item instanceof CropItem) {
        item.render(context);

        if (context.isPointInPath(x, y)) {
          return new HittestInfo(item);
        }
      } else if (item instanceof TagItem) {
        if (!item.isVisible) {
          continue;
        }

        item.dot.render(context);

        if (context.isPointInPath(x, y)) {
          return new HittestInfo(item.dot);
        }

        item.box.render(context);

        if (context.isPointInPath(x, y)) {
          return new HittestInfo(item.box);
        }
      }
    }

    return new HittestInfo(null);
  }
}

export class HittestInfo {
  element: any;

  constructor(item: any) {
    this.element = item;
  }
}