import { TagItem } from "../models/renderable/tag.item";
var JsonConverter = /** @class */ (function () {
    function JsonConverter() {
    }
    JsonConverter.fromString = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (args[0] === undefined) {
            return [];
        }
        var data = JSON.parse(args[0]);
        var param = args[1];
        var width = param.width;
        var height = param.height;
        // convert to absolute coordinates
        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            item.box.l *= width;
            item.box.t *= height;
            item.box.w *= width;
            item.box.h *= height;
            item.dot.x *= width;
            item.dot.y *= height;
        }
        return TagItem.fromJSON(data);
    };
    JsonConverter.toJSON = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var data = args[0];
        var param = args[1];
        var width = param.width;
        var height = param.height;
        var items = [];
        for (var i = 0; i < data.length; i++) {
            var item = data[i].toJSON();
            item.box.l = (item.box.l / width).toFixed(3);
            item.box.t = (item.box.t / height).toFixed(3);
            item.box.w = (item.box.w / width).toFixed(3);
            item.box.h = (item.box.h / height).toFixed(3);
            item.dot.x = Math.abs(item.dot.x / width).toFixed(3);
            item.dot.y = Math.abs(item.dot.y / height).toFixed(3);
            items.push(item);
        }
        return items;
    };
    return JsonConverter;
}());
export { JsonConverter };
