import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Subject } from "rxjs";
import {
  formatDate,
  AllTicketsResponse,
  isCompletedTicket,
  isFailedTicket,
} from "../../services/data.service";
// import { } from '../../services/data.service';

/**
 * Usage
 *     const modalOptions = {
      initialState: {
        title: 'some title',
        otherData: 'some other value'
      },
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-lg'
    }

    this.bsModalRef = this.modalService.show(InfoModalComponent, modalOptions);
 */

@Component({
  selector: "app-uploads-info-modal",
  templateUrl: "./uploads-info-modal.component.html",
  styleUrls: ["./uploads-info-modal.component.css"],
})
export class UploadsInfoModalComponent {
  public result: Subject<boolean>;
  public title: string = "Save";
  public otherData: string = "";
  public fetchData: () => Promise<AllTicketsResponse>;
  public data: Array<any> | null = null;
  public error: string | null = null;
  public showDetails: { [key: string]: boolean } = {};

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.result = new Subject();
    this.fetchData().then((d) => {
      if (d.StatusCode !== 200) {
        this.error = JSON.stringify(d, null, 4);
        return;
      }

      this.data = d.data.Items.map((d) => {
        const failed = isFailedTicket(d) !== null;
        const complete = isCompletedTicket(d).completed;

        let status = "processing";
        if (failed) {
          status = "failed";
        } else if (complete) {
          status = "complete";
        }

        const a = d.messages.map((q) => q.group);

        let ticketType = "";
        if (a.length > 0) {
          const newAsset = a.find((q) => q === "new-asset");
          // const updateAsset = a.find(q => q === "update-asset");

          // ticketType = a[0] === "update-asset" ? "update" : "crop";

          ticketType = newAsset ? "crop" : "update";
        }

        return {
          ticketId: d.ticketId,
          name: d.name,
          started: d.started,
          formattedStarted: formatDate(new Date(d.started)),
          status,
          ticketType,
          messages: d.messages
            .map((m) => {
              return {
                group: m.group,
                type: m.type,
                message: JSON.stringify(m.message, null, 4),
                formattedTimestamp: formatDate(new Date(m.timestamp)),
                timestamp: m.timestamp,
              };
            })
            .sort((a, b) => a.timestamp - b.timestamp),
        };
      }).sort((a, b) => b.started - a.started);

      console.log(this.data);
    });
  }

  public onClickRow(ticketId: string): void {
    console.log(ticketId);
    if (this.showDetails[ticketId]) {
      this.showDetails[ticketId] = false;
    } else {
      this.showDetails[ticketId] = true;
    }
  }

  public onConfirm(): void {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.result.next(true);
    this.bsModalRef.hide();
  }
}
