/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overlay.component";
var styles_OverlayComponent = [i0.styles];
var RenderType_OverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
export function View_OverlayComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_OverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i1.ɵdid(1, 49152, null, 0, i2.OverlayComponent, [], null, null)], null, null); }
var OverlayComponentNgFactory = i1.ɵccf("overlay", i2.OverlayComponent, View_OverlayComponent_Host_0, {}, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
