/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tagslist.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tagslistitem/tagslistitem.component.ngfactory";
import * as i3 from "../tagslistitem/tagslistitem.component";
import * as i4 from "../../services/operation.context";
import * as i5 from "@angular/common";
import * as i6 from "./tagslist.component";
var styles_TagsListComponent = [i0.styles];
var RenderType_TagsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsListComponent, data: {} });
export { RenderType_TagsListComponent as RenderType_TagsListComponent };
function View_TagsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tagslist-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tagListNumbering"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "tagslistitem", [], null, null, null, i2.View_TagsListItemComponent_0, i2.RenderType_TagsListItemComponent)), i1.ɵdid(4, 573440, null, 0, i3.TagsListItemComponent, [i4.OperationContext], { tagItem: [0, "tagItem"], options: [1, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.operationContext.selectedImage.options; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); }); }
function View_TagsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.operationContext.selectedImage.imageTags; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.operationContext.selectedImage; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TagsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tagslist", [], null, null, null, View_TagsListComponent_0, RenderType_TagsListComponent)), i1.ɵdid(1, 49152, null, 0, i6.TagsListComponent, [i4.OperationContext], null, null)], null, null); }
var TagsListComponentNgFactory = i1.ɵccf("tagslist", i6.TagsListComponent, View_TagsListComponent_Host_0, { tagItem: "tagItem", options: "options" }, {}, []);
export { TagsListComponentNgFactory as TagsListComponentNgFactory };
