var ErrorMessage = /** @class */ (function () {
    function ErrorMessage(code, message, details) {
        this.code = ErrorMessage.OK;
        this.message = "";
        this.details = "";
        this.code = code;
        this.message = message;
        this.details = details;
    }
    ErrorMessage.prototype.toJSON = function () {
        return { statuscode: this.code.toString(), message: this.message, details: this.details };
    };
    Object.defineProperty(ErrorMessage.prototype, "isOK", {
        get: function () {
            return this.code == ErrorMessage.OK;
        },
        enumerable: true,
        configurable: true
    });
    ErrorMessage.OK = 0x00;
    ErrorMessage.INFO = 0x01;
    ErrorMessage.ERROR = 0x02;
    ErrorMessage.LOADERROR = 0x03;
    ErrorMessage.LOG = 0x04;
    return ErrorMessage;
}());
export { ErrorMessage };
