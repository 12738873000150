var Context = /** @class */ (function () {
    function Context(width, height, canvas) {
        this.$drawingCanvas = null;
        this.$drawingContext = null;
        if (canvas) {
            this.$drawingCanvas = canvas;
        }
        else {
            this.$drawingCanvas = document.createElement('canvas');
        }
        this.$drawingContext = this.$drawingCanvas.getContext("2d");
        this.$drawingCanvas.width = width;
        this.$drawingCanvas.height = height;
    }
    Object.defineProperty(Context.prototype, "context", {
        get: function () {
            return this.$drawingContext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Context.prototype, "canvas", {
        get: function () {
            return this.$drawingCanvas;
        },
        enumerable: true,
        configurable: true
    });
    return Context;
}());
export { Context };
