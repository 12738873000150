<app-header></app-header>
<div *ngIf="imagesLoaded && hasImages; else noImages" class="container" style="max-width: 1600px; padding: 25px">
  <h3>{{operationContext.selectedCollection?.name}}</h3>
  <h6>This board contains {{operationContext.selectedCollection?.collectionCount}} images</h6>
  <div class="row center">
    <div (click)="toggleStateFilter(3)" class="checkbox-row">
      <mat-icon *ngIf="operationContext.stateFilter[3]">check_box</mat-icon>
      <mat-icon *ngIf="!operationContext.stateFilter[3]">check_box_outline_blank</mat-icon>
      <span matBadgeOverlap="false" matBadge="{{ filterImagesOnChecked(operationContext.images, 3).length || 0 }}">Not editable </span>
    </div>
    <div (click)="toggleStateFilter(2)" class="checkbox-row">
      <mat-icon class="green-icon" *ngIf="operationContext.stateFilter[2]">check_box</mat-icon>
      <mat-icon class="green-icon" *ngIf="!operationContext.stateFilter[2]">check_box_outline_blank</mat-icon>
      <span matBadgeOverlap="false" matBadge="{{ filterImagesOnChecked(operationContext.images, 2).length || 0 }}">Completed </span>
    </div>
    <div (click)="toggleStateFilter(1)" class="checkbox-row">
      <mat-icon class="yellow-icon" *ngIf="operationContext.stateFilter[1]">check_box</mat-icon>
      <mat-icon class="yellow-icon" *ngIf="!operationContext.stateFilter[1]">check_box_outline_blank</mat-icon>
      <span matBadgeOverlap="false" matBadge="{{ filterImagesOnChecked(operationContext.images, 1).length || 0 }}">Not all tags  have been identified </span>
    </div>
    <div (click)="toggleStateFilter(0)" class="checkbox-row">
      <mat-icon class="red-icon" *ngIf="operationContext.stateFilter[0]">check_box</mat-icon>
      <mat-icon class="red-icon" *ngIf="!operationContext.stateFilter[0]">check_box_outline_blank</mat-icon>
      <span matBadgeOverlap="false" matBadge="{{ filterImagesOnChecked(operationContext.images, 0).length || 0 }}">No tags yet </span>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let image of filterImages(operationContext.images)" class="col-md-3" style="margin-top: 25px;">
        <div class="card" (click)="!image?.isDisabled && select(image)"
          [ngClass]="image?.isDisabled ? 'card-disabled' : 'card-enabled'">
          <mat-icon *ngIf="image?.state == 3" class="status-icon"
            title="File of type {{image?.extension}} is not editable">error_outline</mat-icon>
          <mat-icon *ngIf="image?.state == 2" class="status-icon green-icon" title="Completed">brightness_1</mat-icon>
          <mat-icon *ngIf="image?.state == 1" class="status-icon yellow-icon" title="Not all tags have been identified">
            brightness_1</mat-icon>
          <mat-icon *ngIf="image?.state == 0" class="status-icon red-icon" title="No tags yet">brightness_1</mat-icon>
          <div class="card-img-top2">
            <div class="inspirationImage" [ngStyle]="{'background-image': 'url(' + getImage(image) + ')'}"></div>
          </div>
          <div class="card-body">
            <div class="card-body-secectable" (click)="$event.stopPropagation()">
              <h5>
                {{image?.name}}
              </h5>
            </div>
            <div class="card-body-secectable" (click)="$event.stopPropagation()">
              <p>
                {{image?.id}}
              </p>
            </div>
            <div class="body-text">
              <p>
                {{image?.description}}
              </p>
              <span>
                <b>[w x h]</b> {{image?.width}} x {{image?.height}} <b>[ratio]</b> {{ getRatio(image) }}:1
              </span>
              <br>
              <span>
                <b>[tags / products]</b> {{image?.productCount}} / {{image?.identfiedProductCount}}
              </span>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<ng-template #noImages>
  <div *ngIf="imagesLoaded" class="images-center">
    <h4>
      No images found
    </h4>
  </div>
</ng-template>
