import { Rect } from "./rect";
import { Handle } from "./handle";
import { Point } from "./point";
import { CropSelection } from "./crop.selection";

/**
 * Cursors
    // 0     1
    //        
    // 2     3
 */


export class CropSelectionHandle extends Handle {
  public point: Point;
  static LENGTH = 10;
  static WIDTH = 1;

  constructor(id: number, point: Point) {
    super(id);
    this.point = point;
  }

  public renderCursor(mouseDown: boolean) {
    switch (this.id) {
      case 0:
        return 'nw-resize';
      case 1:
        return 'ne-resize';
      case 2:
        return 'sw-resize';
      case 3:
        return 'se-resize';
    }
  }

  renderHandle(context, x, y, rect: Rect) {
    context.fillStyle = "black";
    context.lineWidth = 1;

    context.globalCompositeOperation = 'difference';

    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y *(1 / rect.height) * CropSelectionHandle.WIDTH);
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
    context.rect(this.point.x, this.point.y, -x * (1 / rect.width) * CropSelectionHandle.LENGTH, y *(1 / rect.height) * CropSelectionHandle.WIDTH);
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, -y * (1 / rect.height) * CropSelectionHandle.LENGTH);

    context.fillStyle = "white";
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y *(1 / rect.height) * CropSelectionHandle.WIDTH);
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
    context.rect(this.point.x, this.point.y, -x * (1 / rect.width) * CropSelectionHandle.LENGTH, y *(1 / rect.height) * CropSelectionHandle.WIDTH);
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, -y * (1 / rect.height) * CropSelectionHandle.LENGTH);
    
    context.fill(); 
    context.globalCompositeOperation = 'source-over';
    
    context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
  }

  public render(context: any, rect: Rect) {
    context.save();

    switch(this.id) {
      case 0: { 
        this.renderHandle(context, 1, 1, rect);
        break;
      }

      case 1: { 
        this.renderHandle(context, -1, 1, rect);
        break;
      }

      case 2: { 
        this.renderHandle(context, 1, -1, rect);
        break;
      }

      case 3: { 
        this.renderHandle(context, -1, -1, rect);
        break;
      }
    }

    context.restore();
  }
}