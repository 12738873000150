import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4'
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private adal: AdalService,
    private router: Router) {
    this.adal.init(environment.adalConfig);
  }

  ngOnInit() {
    this.adal.handleWindowCallback();

    if (this.adal.userInfo.authenticated) {
      this.router.navigate(['collections']);

      /*let collection = sessionStorage.getItem("collection");

      if (collection) {
        this.router.navigate(['images']);
      } else {
        this.router.navigate(['collections']);
      }*/
    }
  }
}
