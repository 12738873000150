/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splash.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./splash.component";
import * as i3 from "@angular/router";
import * as i4 from "adal-angular4/adal.service";
var styles_SplashComponent = [i0.styles];
var RenderType_SplashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashComponent, data: { "animation": [{ type: 7, name: "fadeInAnimation", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", top: 0, left: 0, right: 0, bottom: 0, opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: ".5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_SplashComponent as RenderType_SplashComponent };
export function View_SplashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "splash-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Log in"]))], null, null); }
export function View_SplashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash", [], [[40, "@fadeInAnimation", 0]], null, null, View_SplashComponent_0, RenderType_SplashComponent)), i1.ɵdid(1, 49152, null, 0, i2.SplashComponent, [i3.Router, i4.AdalService], null, null)], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var SplashComponentNgFactory = i1.ɵccf("app-splash", i2.SplashComponent, View_SplashComponent_Host_0, {}, {}, []);
export { SplashComponentNgFactory as SplashComponentNgFactory };
