/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tagslistitem.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../search/search.component.ngfactory";
import * as i6 from "../search/search.component";
import * as i7 from "../../services/operation.context";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../services/data.service";
import * as i10 from "./tagslistitem.component";
var styles_TagsListItemComponent = [i0.styles];
var RenderType_TagsListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsListItemComponent, data: {} });
export { RenderType_TagsListItemComponent as RenderType_TagsListItemComponent };
function View_TagsListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "white-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TagsListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["starts"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TagsListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleVisibility() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["visibility"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TagsListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleVisibility() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["visibility_off"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TagsListItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchItem: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "item-container"], ["draggable", "true"]], null, [[null, "keyup"], [null, "dragstart"], [null, "drop"], [null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.tab($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.drag($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (_co.drop($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragover" === en)) {
        var pd_3 = (_co.allowDrop($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "item-img-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "border-color": 0, "background-color": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListItemComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "item-body"], ["draggable", "true"]], null, [[null, "click"], [null, "dragstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        $event.preventDefault();
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "search", [], null, [["window", "resize"], [null, "mousedown"], ["document", "mousedown"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).clickInside($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).clickout() !== false);
        ad = (pd_2 && ad);
    } if (("document:keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_SearchComponent_0, i5.RenderType_SearchComponent)), i1.ɵdid(10, 4308992, [[1, 4], ["searchItem", 4]], 0, i6.SearchComponent, [i7.OperationContext, i8.DomSanitizer, i9.DataService], { tagItem: [0, "tagItem"], options: [1, "options"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListItemComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListItemComponent_3)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsListItemComponent_4)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item-container"; var currVal_1 = (_co.isInCropRegion() ? "not-dimmed" : "dimmed"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (("" + _co.getColor(_co.tagItem)) + ""), (("" + _co.getColor(_co.tagItem)) + "")); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.tagItem.isModified; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.tagItem; var currVal_5 = _co.options; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (_co.tagItem.prd.type == "main"); _ck(_v, 12, 0, currVal_6); _ck(_v, 14, 0); var currVal_9 = _co.tagItem.isVisible; _ck(_v, 17, 0, currVal_9); var currVal_10 = !_co.tagItem.isVisible; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var currVal_7 = i1.ɵnov(_v, 14).inline; var currVal_8 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_7, currVal_8); }); }
export function View_TagsListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tagslistitem", [], null, null, null, View_TagsListItemComponent_0, RenderType_TagsListItemComponent)), i1.ɵdid(1, 573440, null, 0, i10.TagsListItemComponent, [i7.OperationContext], null, null)], null, null); }
var TagsListItemComponentNgFactory = i1.ɵccf("tagslistitem", i10.TagsListItemComponent, View_TagsListItemComponent_Host_0, { tagItem: "tagItem", options: "options" }, {}, []);
export { TagsListItemComponentNgFactory as TagsListItemComponentNgFactory };
