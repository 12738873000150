import { ImageState, ImageItem } from "./image.item";

export enum CollectionState {
  NOT_STARTED = 0x00,
  UNFINISHED = 0x01,
  COMPLETE = 0x02,
  UNKNOWN = 0x03
}

export enum TypeEnum {
  SHOPPABLE = "Shoppable app",
  DAM = "DAM",
  OTHER = "Other"
}

export class CollectionItem {
  constructor(
    public id: string,
    public description: string,
    public name?: string,
    public cover?: any,
    public collectionCount?: number,
    private $state?: CollectionState,
    public type?: TypeEnum,
    public selected?: boolean
  ) { }

  static create(data): CollectionItem {
    let collectionItem: CollectionItem = Object.create(CollectionItem.prototype);

    Object.assign(collectionItem, {
      id: data.id,
      name: data.name,
      description: data.description || "",
      cover: data.cover,
      collectionCount: data.collectionCount,
      selected: false,
      state: CollectionState.UNKNOWN
    });

    if (collectionItem.name.toUpperCase().startsWith("SHOPPABLE")) {
      collectionItem.type = TypeEnum.SHOPPABLE;
    } else if (collectionItem.name.toUpperCase().startsWith("FY")) {
      collectionItem.type = TypeEnum.DAM;
    } else {
      collectionItem.type = TypeEnum.OTHER;
    }

    return collectionItem;
  }

  get state() {
    return this.$state;
  }

  set state(value: CollectionState) {
    this.$state = value;
  }

  get thumbnail(): string {
    let thumb = this.cover.thumbnail;

    if (thumb === undefined) {
      return '/assets/na.png';
    }

    return thumb;
  }

  toString() {
    return this.name;
  }

  updateState(imageItem: ImageItem) {
    switch(this.state) {
      case CollectionState.NOT_STARTED: {
        if (imageItem.state != ImageState.NOT_STARTED) {
          this.state = CollectionState.UNFINISHED;
        } 
        break;
      }

      case CollectionState.UNKNOWN: {
        if (imageItem.state == ImageState.UNFINISHED) {
          this.state = CollectionState.UNFINISHED;
        } else if (imageItem.state == ImageState.NOT_STARTED) {
          this.state = CollectionState.NOT_STARTED;
        } else if (imageItem.state == ImageState.COMPLETE) {
          this.state = CollectionState.COMPLETE;
        }
        break;
      }

      case CollectionState.COMPLETE: {
        if (imageItem.state != ImageState.COMPLETE) {
          this.state = CollectionState.UNFINISHED;
        }  
        break;
      }
    }
  }
}