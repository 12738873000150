import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JsonpModule } from "@angular/http";
import { RoutingModule } from "./routing.module";
import { AppComponent } from "./app.component";
import { InfoModalComponent } from "./components/info-modal/info-modal.component";
import { UploadsInfoModalComponent } from "./components/uploads-info-modal/uploads-info-modal.component";
import { DataService } from "./services/data.service";
import { SplashComponent } from "./components/splash/splash.component";
import { FormBuilder } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ModalModule } from "ngx-bootstrap/modal";
import { AppHeaderComponent } from "./components/app-header/app-header.component";
import { MaterialModule } from "./material-module";
import { ImagesComponent } from "./components/images/images.component";
import { TaggingComponent } from "./components/tagging/tagging.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { ImageSlideComponent } from "./components/slider/image-slide.component";
import { CollectionComponent } from "./components/collection/collection.component";
import { AdalService } from "adal-angular4";
import { ModalConfirmationService } from "./services/confirmation.service";
import { RendererComponent } from "./components/renderer/renderer.component";
import { OperationContext } from "./services/operation.context";
import { TagsListComponent } from "./components/tagslist/tagslist.component";
import { SearchComponent } from "./components/search/search.component";
import { TagsListItemComponent } from "./components/tagslistitem/tagslistitem.component";
import { HttpHeaderInterceptor } from "./services/http.interceptor";
import { AuthGuardService } from "./session/auth.guard";
import { TokenInterceptor } from "./session/token.interceptor";
import { LogoutService } from "./services/logout.service";
import { OverlayService } from "./services/overlay.service";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { ProgressbarComponent } from "./components/progressbar/progressbar.component";
import { SnackBarService } from "./services/snackbar.service";
import { DropDownComponent } from "./components/dropdown/dropdown.component";
import { CropDropDownComponent } from "./components/cropdropdown/cropdropdown.component";
import { SearchFieldComponent } from "./components/searchfield/searchfield.component";
import { SearchResultComponent } from "./components/searchresult/searchresult.component";

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    InfoModalComponent,
    UploadsInfoModalComponent,
    AppHeaderComponent,
    ImagesComponent,
    TaggingComponent,
    ToolbarComponent,
    ImageSlideComponent,
    CollectionComponent,
    TagsListComponent,
    TagsListItemComponent,
    RendererComponent,
    OverlayComponent,
    ProgressbarComponent,
    SearchComponent,
    DropDownComponent,
    CropDropDownComponent,
    SearchFieldComponent,
    SearchResultComponent,
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JsonpModule,
    RoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    ModalModule.forRoot(),
  ],
  providers: [
    DataService,
    OperationContext,
    FormBuilder,
    ModalConfirmationService,
    OverlayService,
    SnackBarService,
    LogoutService,
    [
      AdalService,
      AuthGuardService,
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      /*{ provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true }*/
    ],
  ],
  entryComponents: [
    ImagesComponent,
    SplashComponent,
    CollectionComponent,
    TaggingComponent,
    InfoModalComponent,
    UploadsInfoModalComponent,
    OverlayComponent,
    SearchResultComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
