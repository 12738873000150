import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) { }

  public show(message: string, ...args) {
    this.snackBar.open(message, args[0], args[1]);
  }
}