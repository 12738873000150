import { Selection } from "./selection";

const LINE_WIDTH = 2;

export class DefaultSelection extends Selection {
  private offset: number = 0;
  private then: any;

  constructor(item: any) {
    super(item);
    this.then = performance.now();

    item.parent().focus();
  }

  public render(context: any) {
    if (!this.selectedItem) {
      return;
    }

    let now = performance.now();
    let delta = now - this.then;

    context.save();
    context.beginPath();
    const bb = this.selectedItem.boundingBox(context);
    context.rect(bb.left, bb.top, bb.width, bb.height);

    //context.clip();
    context.setLineDash([8, 8]);
    context.lineDashOffset = -this.offset;
    context.strokeStyle = "rgba(255,255,255,1)";
    context.lineWidth = LINE_WIDTH;
    context.stroke();
    context.closePath();
    context.restore();

    if (delta > 10) {
      this.offset++;

      if (this.offset >= 16) {
        this.offset = 0;
      }

      this.then = now;
    }
  }

  renderCursor(mouseDown: boolean) {
    return "auto";
  }
}
