/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-slide.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../renderer/renderer.component.ngfactory";
import * as i5 from "../renderer/renderer.component";
import * as i6 from "../../services/operation.context";
import * as i7 from "../../services/data.service";
import * as i8 from "./image-slide.component";
import * as i9 from "../../services/confirmation.service";
import * as i10 from "@angular/router";
import * as i11 from "adal-angular4/adal.service";
var styles_ImageSlideComponent = [i0.styles];
var RenderType_ImageSlideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageSlideComponent, data: {} });
export { RenderType_ImageSlideComponent as RenderType_ImageSlideComponent };
export function View_ImageSlideComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { renderer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[8, "hidden", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back_ios"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "renderer", [], null, null, null, i4.View_RendererComponent_0, i4.RenderType_RendererComponent)), i1.ɵdid(5, 180224, [[1, 4], ["renderer", 4]], 0, i5.RendererComponent, [i6.OperationContext, i7.DataService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[8, "hidden", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_forward_ios"]))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled(); var currVal_1 = i1.ɵnov(_v, 2).inline; var currVal_2 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.isDisabled(); var currVal_4 = i1.ɵnov(_v, 7).inline; var currVal_5 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_ImageSlideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-slide", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ImageSlideComponent_0, RenderType_ImageSlideComponent)), i1.ɵdid(1, 114688, null, 0, i8.ImageSlideComponent, [i6.OperationContext, i9.ModalConfirmationService, i10.Router, i11.AdalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageSlideComponentNgFactory = i1.ɵccf("image-slide", i8.ImageSlideComponent, View_ImageSlideComponent_Host_0, {}, {}, []);
export { ImageSlideComponentNgFactory as ImageSlideComponentNgFactory };
