<div *ngIf="operationContext.state == 2" class="anim progressbar">
  <div class="line"></div>
  <div class="subline inc"></div>
  <div class="subline dec"></div>
</div>

<div *ngIf="operationContext.state != 2" class="progressbar">
  <div class="line hidden"></div>
  <div class="subline hidden"></div>
  <div class="subline hidden"></div>
</div>
  
  