import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperationContext } from '../../services/operation.context';
var ImagesComponent = /** @class */ (function () {
    function ImagesComponent(router, operationContext) {
        this.router = router;
        this.operationContext = operationContext;
        if (sessionStorage.getItem('collection') == null) {
            router.navigate(['collections']);
            return;
        }
        router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    }
    ImagesComponent.prototype.ngOnInit = function () {
        var collectionId = sessionStorage.getItem('collection');
        // Uncheck inspiration feed
        this.operationContext.inspirationalParentCheck = false;
        this.operationContext.inspirationalCropCheck = false;
        if (collectionId) {
            this.operationContext.loadImages(sessionStorage.getItem('collection'));
        }
    };
    ImagesComponent.prototype.toggleStateFilter = function (i) {
        var stateFilter = this.operationContext.stateFilter;
        stateFilter[i] = !stateFilter[i];
        this.operationContext.stateFilter = stateFilter;
    };
    ImagesComponent.prototype.filterImages = function (images) {
        var _this = this;
        return images.filter(function (image) {
            return _this.operationContext.stateFilter[image.state];
        });
    };
    ImagesComponent.prototype.filterImagesOnChecked = function (images, state) {
        var preFilteredImages = this.filterImages(images);
        return preFilteredImages.filter(function (image) { return image.state === state; });
    };
    ImagesComponent.prototype.select = function (image) {
        this.operationContext.clearSelection();
        this.operationContext.selectedImage = image;
        this.router.navigate(['tagging']);
    };
    ImagesComponent.prototype.getImage = function (image) {
        return image.thumbnail;
    };
    ImagesComponent.prototype.getRatio = function (image) {
        return (image.width / image.height).toFixed(2);
    };
    Object.defineProperty(ImagesComponent.prototype, "hasImages", {
        get: function () {
            return this.operationContext.images && this.operationContext.images.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImagesComponent.prototype, "imagesLoaded", {
        get: function () {
            return this.operationContext.imagesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    return ImagesComponent;
}());
export { ImagesComponent };
