/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./uploads-info-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./uploads-info-modal.component";
import * as i4 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_UploadsInfoModalComponent = [i0.styles];
var RenderType_UploadsInfoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadsInfoModalComponent, data: {} });
export { RenderType_UploadsInfoModalComponent as RenderType_UploadsInfoModalComponent };
function View_UploadsInfoModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
function View_UploadsInfoModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "details-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "details-timestamp"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "details-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "details-type"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "message-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "details-timestamp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "details-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.formattedTimestamp; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.group; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.type; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.message; _ck(_v, 12, 0, currVal_3); }); }
function View_UploadsInfoModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ticket-details"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadsInfoModalComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.messages; _ck(_v, 3, 0, currVal_0); }, null); }
function View_UploadsInfoModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "ticket-row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickRow(_v.context.$implicit.ticketId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "ticket-first-part"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "ticket-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "ticket-part-type"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "ticket-part"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadsInfoModalComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.showDetails[_v.context.$implicit.ticketId]; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.formattedStarted; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.ticketType; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.status; _ck(_v, 10, 0, currVal_3); }); }
function View_UploadsInfoModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "ticket-row-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "ticket-first-part"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "ticket-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "ticket-part-type"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "ticket-part"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Original name"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadsInfoModalComponent_3)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_UploadsInfoModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left brand"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "close-button"], ["src", "/assets/cross.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Below is a list of all create-crop and update jobs."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadsInfoModalComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadsInfoModalComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-secondary btn-width"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Close "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data === null); _ck(_v, 9, 0, currVal_1); var currVal_2 = (_co.data !== null); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_UploadsInfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-uploads-info-modal", [], null, null, null, View_UploadsInfoModalComponent_0, RenderType_UploadsInfoModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.UploadsInfoModalComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadsInfoModalComponentNgFactory = i1.ɵccf("app-uploads-info-modal", i3.UploadsInfoModalComponent, View_UploadsInfoModalComponent_Host_0, {}, {}, []);
export { UploadsInfoModalComponentNgFactory as UploadsInfoModalComponentNgFactory };
