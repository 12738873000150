import { TagItem } from "../models/renderable/tag.item";
import { JsonConverter } from "../helpers/json.converter";
import { ProductItem } from "./product.item";
import { environment } from "../../environments/environment";

export enum ImageState {
  NOT_STARTED = 0x00,
  UNFINISHED = 0x01,
  COMPLETE = 0x02,
  ERROR = 0x03,
}

function ensureParsable(data, fallback = "[]") {
  try {
    JSON.parse(data);
  } catch (e) {
    console.warn("Could not parse data", data);
    return fallback;
  }

  return data;
}

export class ImageItem {
  constructor(
    public index: number,
    public id: string,
    public description: string,
    public name?: string,
    public bynderId?: string,
    public thumbnails?: any,
    private $isModified?: boolean,
    public imageTags?: TagItem[],
    private $options?: ProductItem[],
    public width?: number,
    public height?: number,
    public dateLoaded?: number,
    public state?: ImageState,
    public productCount?: number,
    public identfiedProductCount?: number,
    public $isDisabled?: boolean,
    public extension?: string,
    public old?: boolean
  ) {
    this.old = false;
  }

  static create(data): ImageItem {
    let imageItem: ImageItem = Object.create(ImageItem.prototype);

    Object.assign(imageItem, {
      id: data.id,
      name: data.name,
      imageTags: [],
      $options: [],
      thumbnails: data.thumbnails,
      isModified: false,
      width: data.width,
      height: data.height,
      rawTags: ensureParsable(data["property_product_coordinates"]),
      dateLoaded: Date.now(),
      state: ImageState.NOT_STARTED,
      isDisabled:
        data.width == 0 ||
        data.height == 0 ||
        (data.extension &&
          data.extension.length > 0 &&
          data.extension[0].toLowerCase() != "png" &&
          data.extension[0].toLowerCase() != "jpg" &&
          data.extension[0].toLowerCase() != "jpeg"),
      extension:
        data.extension && data.extension.length > 0 ? data.extension[0] : "",
      old: false,
    });

    let tags = JsonConverter.fromString(imageItem["rawTags"], {
      width: 1,
      height: 1,
    });
    imageItem.state = ImageItem.getState(imageItem, tags);
    imageItem.productCount = tags.length;
    imageItem.identfiedProductCount = tags.filter(
      (tag) => tag.prd.id !== ""
    ).length;

    return imageItem;
  }

  static getState(image: ImageItem, tags: TagItem[]) {
    if (image.isDisabled) {
      return ImageState.ERROR;
    } else if (tags.length == 0) {
      return ImageState.NOT_STARTED;
    } else if (tags.filter((tag) => tag.prd.id !== "").length != tags.length) {
      return ImageState.UNFINISHED;
    }

    return ImageState.COMPLETE;
  }

  deleteTag(tagItem: TagItem) {
    let index = this.imageTags.indexOf(tagItem);

    if (index < 0) {
      return;
    }

    this.imageTags.splice(index, 1);
    this.$isModified = true;
  }

  parseTags() {
    this.imageTags = JsonConverter.fromString(this["rawTags"], {
      width: this["canvasWidth"],
      height: this["canvasHeight"],
    });
  }

  saveTags(): any {
    let json = JsonConverter.toJSON(this.imageTags, {
      width: this["canvasWidth"],
      height: this["canvasHeight"],
    });

    return json;
  }

  moveTag(to, from) {
    this.imageTags.splice(to, 0, this.imageTags.splice(from, 1)[0]);
    this.isModified = true;

    this.imageTags[to].isModified = true;
  }

  sortTagsByAreaSize() {
    if (!this.imageTags) {
      return;
    }

    this.imageTags.sort((a, b) => {
      const abox = a.box.boundingBox();
      const bbox = b.box.boundingBox();

      return bbox.width * bbox.height - abox.width * abox.height;
    });

    this.imageTags.forEach((t) => {
      t.isModified = true;
    });
  }

  get isDisabled(): boolean {
    return this.$isDisabled;
  }

  set isDisabled(value: boolean) {
    this.$isDisabled = value;
  }

  get isModified(): boolean {
    for (let tagItem of this.imageTags) {
      if (tagItem.isModified) {
        return true;
      }
    }

    return this.$isModified;
  }

  set isModified(value: boolean) {
    this.$isModified = value;
  }

  get thumbnail(): string {
    let thumb = this.thumbnails.webimage;

    if (thumb === undefined) {
      return "/assets/na.png";
    }

    return (
      thumb.replace(environment.bynderImageCDN, environment.akamaiImageCDN) +
      "?f=s"
    );
  }

  set options(value: ProductItem[]) {
    this.$options = value;
  }

  get options(): ProductItem[] {
    return this.$options;
  }

  clearIsModified() {
    this.isModified = false;

    for (let tagItem of this.imageTags) {
      tagItem.isModified = false;
    }
  }
}
