export class ProductItem {
  constructor(
    public id: string,
    public name?: string,
    public description?: string,
    public descriptionEN?: string,
    public imageUrl?: string,
    public score?: number,
    public selected?: boolean,
    public type?: string
  ) {}

  static create(data): ProductItem {
    let product: ProductItem = Object.create(ProductItem.prototype);

    Object.assign(product, {
      name: data.name,
      description: data.description,
      descriptionEN: data.descriptionEN || false,
      id: data.id,
      imageUrl: data.imageUrl,
      score: data.score || 0,
      selected: data.selected || false,
      type: data.type || "main",
    });

    return product;
  }

  public clone() {
    let product: ProductItem = Object.create(ProductItem.prototype);

    Object.assign(product, {
      name: this.name,
      description: this.description,
      descriptionEN: this.descriptionEN,
      id: this.id,
      imageUrl: this.imageUrl,
      score: this.score || 0,
      selected: this.selected || false,
      type: this.type || "main",
    });

    return product;
  }

  toJSON() {
    return this.id.toString();
  }

  get thumbnail(): string {
    if (!this.imageUrl) {
      return "/assets/na.png";
    }

    return this.imageUrl;
  }

  toString() {
    if (this.name && this.id) {
      return this.name + ", " + this.id;
    }

    return this.id;
  }
}
