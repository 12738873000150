import { ImageState } from "./image.item";
export var CollectionState;
(function (CollectionState) {
    CollectionState[CollectionState["NOT_STARTED"] = 0] = "NOT_STARTED";
    CollectionState[CollectionState["UNFINISHED"] = 1] = "UNFINISHED";
    CollectionState[CollectionState["COMPLETE"] = 2] = "COMPLETE";
    CollectionState[CollectionState["UNKNOWN"] = 3] = "UNKNOWN";
})(CollectionState || (CollectionState = {}));
export var TypeEnum;
(function (TypeEnum) {
    TypeEnum["SHOPPABLE"] = "Shoppable app";
    TypeEnum["DAM"] = "DAM";
    TypeEnum["OTHER"] = "Other";
})(TypeEnum || (TypeEnum = {}));
var CollectionItem = /** @class */ (function () {
    function CollectionItem(id, description, name, cover, collectionCount, $state, type, selected) {
        this.id = id;
        this.description = description;
        this.name = name;
        this.cover = cover;
        this.collectionCount = collectionCount;
        this.$state = $state;
        this.type = type;
        this.selected = selected;
    }
    CollectionItem.create = function (data) {
        var collectionItem = Object.create(CollectionItem.prototype);
        Object.assign(collectionItem, {
            id: data.id,
            name: data.name,
            description: data.description || "",
            cover: data.cover,
            collectionCount: data.collectionCount,
            selected: false,
            state: CollectionState.UNKNOWN
        });
        if (collectionItem.name.toUpperCase().startsWith("SHOPPABLE")) {
            collectionItem.type = TypeEnum.SHOPPABLE;
        }
        else if (collectionItem.name.toUpperCase().startsWith("FY")) {
            collectionItem.type = TypeEnum.DAM;
        }
        else {
            collectionItem.type = TypeEnum.OTHER;
        }
        return collectionItem;
    };
    Object.defineProperty(CollectionItem.prototype, "state", {
        get: function () {
            return this.$state;
        },
        set: function (value) {
            this.$state = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "thumbnail", {
        get: function () {
            var thumb = this.cover.thumbnail;
            if (thumb === undefined) {
                return '/assets/na.png';
            }
            return thumb;
        },
        enumerable: true,
        configurable: true
    });
    CollectionItem.prototype.toString = function () {
        return this.name;
    };
    CollectionItem.prototype.updateState = function (imageItem) {
        switch (this.state) {
            case CollectionState.NOT_STARTED: {
                if (imageItem.state != ImageState.NOT_STARTED) {
                    this.state = CollectionState.UNFINISHED;
                }
                break;
            }
            case CollectionState.UNKNOWN: {
                if (imageItem.state == ImageState.UNFINISHED) {
                    this.state = CollectionState.UNFINISHED;
                }
                else if (imageItem.state == ImageState.NOT_STARTED) {
                    this.state = CollectionState.NOT_STARTED;
                }
                else if (imageItem.state == ImageState.COMPLETE) {
                    this.state = CollectionState.COMPLETE;
                }
                break;
            }
            case CollectionState.COMPLETE: {
                if (imageItem.state != ImageState.COMPLETE) {
                    this.state = CollectionState.UNFINISHED;
                }
                break;
            }
        }
    };
    return CollectionItem;
}());
export { CollectionItem };
