import { Box } from "./box";
import { Dot } from "./dot";
import { Rect } from "./rect";
import { Point } from "./point";
import { ProductItem } from "../../models/product.item";
import { Helpers } from "../../helpers/common";
var TagItem = /** @class */ (function () {
    function TagItem(args) {
        this.box = new Box(new Rect(args.x, args.y, args.width, args.height), args.color, this);
        this.dot = new Dot(new Point(args.x + args.width, args.y + args.height), this);
        this.$isModified = false;
        this.$isSelected = false;
        this.$isVisible = true;
        this.prd = new ProductItem("", "", "", "", "", 0, false);
        this.thumbnail = "";
        this.$callback = null;
        this.identifiedProducts = [];
    }
    Object.defineProperty(TagItem.prototype, "isVisible", {
        get: function () {
            return this.$isVisible;
        },
        set: function (value) {
            this.$isVisible = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TagItem.prototype, "isModified", {
        get: function () {
            return this.$isModified;
        },
        set: function (value) {
            this.$isModified = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TagItem.prototype, "isSelected", {
        get: function () {
            return this.$isSelected;
        },
        set: function (value) {
            this.$isSelected = value;
        },
        enumerable: true,
        configurable: true
    });
    TagItem.prototype.focus = function (callback) {
        if (callback === undefined) {
            if (this.$callback) {
                this.$callback();
            }
            return;
        }
        this.$callback = callback;
    };
    TagItem.prototype.update = function (left, top, width, height) {
        this.box.updateDimension(left, top, width, height);
        this.dot.updatePosition(left + width / 2, top + height / 2);
    };
    TagItem.prototype.move = function (x, y) {
        this.dot.move(x, y);
        this.box.move(x, y);
    };
    TagItem.prototype.boundingBox = function () {
        return this.box.boundingBox();
    };
    TagItem.prototype.render = function (context, renderBox) {
        if (renderBox) {
            this.box.render(context);
        }
        if (this.boundingBox().width >= 20 || this.boundingBox().height >= 20) {
            this.dot.render(context);
        }
    };
    // toJSON is automatically used by JSON.stringify
    TagItem.prototype.toJSON = function () {
        return {
            box: this.box.toJSON(),
            dot: this.dot.toJSON(),
            prd: this.prd.toJSON(),
            t: this.prd.type === "prop" ? "p" : "m",
        };
    };
    // fromJSON is used to convert an serialized version
    // of the TagItem to an instance of the class
    TagItem.fromJSON = function (json) {
        // create an instance of the TagItem class
        var items = [];
        for (var i = 0; i < json.length; i++) {
            var tagItem = TagItem.create(json[i]);
            items.push(tagItem);
        }
        return items;
    };
    TagItem.prototype.clone = function () {
        var clone = Object.create(TagItem.prototype);
        var box = Object.create(Box.prototype);
        var dot = Object.create(Dot.prototype);
        var prd = Object.create(ProductItem.prototype);
        var rect = new Rect(this.box.boundingBox().left, this.box.boundingBox().top, this.box.boundingBox().width, this.box.boundingBox().height);
        var point = new Point(this.dot.location.x, this.dot.location.y);
        Object.assign(box, {
            rect: rect,
            color: this.box.getColor(),
            parentItem: this,
        });
        Object.assign(dot, {
            point: point,
            parentItem: clone,
        });
        Object.assign(clone, {
            prd: Object.assign(prd, {
                id: this.prd.id,
                name: this.prd.name,
                description: this.prd.description,
                imageUrl: this.prd.imageUrl,
                score: this.prd.score,
                selected: this.prd.selected,
                type: this.prd.type,
            }),
            isSelected: this.$isSelected,
            isVisible: this.$isVisible,
            isModified: this.$isModified,
            thumbnail: this.thumbnail,
            box: box,
            dot: dot,
        });
        return clone;
    };
    TagItem.create = function (data) {
        var tagItem = Object.create(TagItem.prototype);
        var box = Object.create(Box.prototype);
        var dot = Object.create(Dot.prototype);
        var prd = Object.create(ProductItem.prototype);
        var color = Helpers.getColor();
        var rect = new Rect(parseFloat(data.box.l), parseFloat(data.box.t), parseFloat(data.box.w), parseFloat(data.box.h));
        var point = new Point(parseFloat(data.dot.x), parseFloat(data.dot.y));
        Object.assign(box, {
            rect: rect,
            color: color,
            parentItem: tagItem,
        });
        Object.assign(dot, {
            point: point,
            parentItem: tagItem,
        });
        Object.assign(tagItem, {
            prd: Object.assign(prd, {
                id: data.prd === undefined ? "" : data.prd,
                name: "",
                description: "",
                imageUrl: "/assets/na.png",
                score: data.score || 0,
                selected: data.selected || false,
            }),
            isSelected: false,
            isModified: false,
            isVisible: true,
            thumbnail: "",
            box: box,
            dot: dot,
            identifiedProducts: data.identifiedProducts || [],
        });
        return tagItem;
    };
    return TagItem;
}());
export { TagItem };
