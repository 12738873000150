<app-header></app-header>
<toolbar>
  <div class="button-container-wrapper">
    <div *ngIf="hasImage() && !loadError()" class="button-container-wrapper">
      <div [ngClass]="operationContext.isCropEnabled ? 'selected' : 'not-selected'" (click)="canClick() && toggle()">
        <mat-icon class="icon" title="Cropping tool">crop</mat-icon>
      </div>
      &nbsp;
      <div [ngClass]="operationContext.showBoxes ? 'selected' : 'not-selected'" (click)="canClick() && toggleBoxes()">
        <mat-icon class="icon" title="Show / hide boxes">crop_free</mat-icon>
      </div>
    </div>
    &nbsp;
    <mat-divider *ngIf="operationContext.isCropEnabled" [vertical]="true"></mat-divider>
    <div *ngIf="operationContext.isCropEnabled">&nbsp;</div>
    <dropdown *ngIf="operationContext.isCropEnabled" class="no-selection"></dropdown>
    <cropdropdown (layoutNameChange)="onLayoutNameChange($event)" *ngIf="operationContext.isCropEnabled"
      class="no-selection"></cropdropdown>
    <input (change)="onInputChange()" *ngIf="operationContext.isCropEnabled" placeholder="ICOM layout name" type="text"
      [(ngModel)]="layoutName">
    <div *ngIf="operationContext.isCropEnabled" class="button-wrapper">
      <span><b>[w x h]</b> {{ getDimension() }} <b> [aspect ratio]</b> {{ getRatio() }}:1</span>
    </div>
    <mat-divider *ngIf="true" [vertical]="true"></mat-divider>
    <div class="checkbox-center">
      <label for="InspirationalImage"><input *ngIf="true" id="InspirationalImage"
          [(ngModel)]="operationContext.inspirationalParentCheck" type="checkbox">
        <span>&nbsp;Inspirational parent</span></label>
      <label for="InspirationalCrop"><input *ngIf="true" id="InspirationalCrop"
          [(ngModel)]="operationContext.inspirationalCropCheck" type="checkbox">
        <span>&nbsp;Inspirational crop</span></label>
    </div>
  </div>

  <div class="button-container-wrapper center">
    <div>&nbsp;</div>
    <button class="btn btn-primary button-wrapper" (click)="save($event)"
      [disabled]="!operationContext.isModified || isSaving() || loadError()">
      <mat-icon *ngIf="!isSaving()">save</mat-icon>
      <mat-icon *ngIf="isSaving()" class="glyphicon spinning">sync</mat-icon>
      <span>Save</span>
    </button>
  </div>
</toolbar>

<div class="panel-wrapper">
  <mat-expansion-panel *ngIf="hasImage()" hideToggle="true" class="mat-elevation-z0 panel-slider" [expanded]="true"
    [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="label-wrapper">
          <mat-icon>description</mat-icon>
          <h6>{{ operationContext.selectedImage?.name }} - {{ operationContext.selectedCollection?.name }}</h6>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <image-slide></image-slide>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="hasImage() && !loadError()" hideToggle="true" class="mat-elevation-z0 panel-taglist"
    [expanded]="true" [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="label-wrapper">
          <mat-icon>description</mat-icon>
          <h6>Products</h6>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="hasTags()" style="display: flex; float: left; width: 100%; justify-content: left; margin-top: 56px;">
      <button class="btn btn-primary button-wrapper" (click)="sortTagsByArea()">Sort by box area</button>
    </div>

    <tagslist></tagslist>

    <div *ngIf="canLoadIDAMData()">
      <button class="btn btn-primary button-wrapper" (click)="loadIDAMData()">Load IDAM product data</button>
    </div>
    <div *ngIf="isLoadingIDAMData">
      <mat-progress-spinner color="primary" mode="indeterminate" value="50" diameter="20">
      </mat-progress-spinner>
    </div>
  </mat-expansion-panel>
</div>

<div *ngIf="!hasImage()" class="tagslist-center">
  <h4>
    Please select an image to start tagging
  </h4>
</div>