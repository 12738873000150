/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./searchfield.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./searchfield.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../services/data.service";
var styles_SearchFieldComponent = [i0.styles];
var RenderType_SearchFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchFieldComponent, data: {} });
export { RenderType_SearchFieldComponent as RenderType_SearchFieldComponent };
export function View_SearchFieldComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), i1.ɵqud(402653184, 2, { formControl: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "field"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["input", 1]], null, 5, "input", [["class", "search-input"], ["spellcheck", "false"], ["type", "text"]], [[8, "value", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(7, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "mat-icon", [["class", "icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(16, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["search"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.inputController; _ck(_v, 7, 0, currVal_9); _ck(_v, 11, 0); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.searchActive; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.search; var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 11).inline; var currVal_11 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_10, currVal_11); var currVal_12 = i1.ɵnov(_v, 16).inline; var currVal_13 = (((i1.ɵnov(_v, 16).color !== "primary") && (i1.ɵnov(_v, 16).color !== "accent")) && (i1.ɵnov(_v, 16).color !== "warn")); _ck(_v, 15, 0, currVal_12, currVal_13); }); }
export function View_SearchFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "searchfield", [], null, null, null, View_SearchFieldComponent_0, RenderType_SearchFieldComponent)), i1.ɵdid(1, 114688, null, 0, i5.SearchFieldComponent, [i6.Router, i7.DomSanitizer, i8.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchFieldComponentNgFactory = i1.ɵccf("searchfield", i5.SearchFieldComponent, View_SearchFieldComponent_Host_0, {}, {}, []);
export { SearchFieldComponentNgFactory as SearchFieldComponentNgFactory };
