import { Rect } from "./rect";
var RADIUS = 10;
var PADDING = 5;
var Dot = /** @class */ (function () {
    function Dot(point, parent) {
        this.offset = 0;
        this.then = 0;
        this.parentItem = parent;
        this.point = point;
    }
    Dot.prototype.render = function (context) {
        if (this.then == undefined) {
            this.then = performance.now();
            this.offset = 0;
        }
        var now = performance.now();
        var delta = now - this.then;
        context.save();
        context.beginPath();
        var grd = context.createRadialGradient(this.point.x - 2, this.point.y - 2, 2, this.point.x - 2, this.point.y - 2, RADIUS + Math.cos((this.offset * Math.PI) / 180) * 1.5);
        grd.addColorStop(0, "white");
        grd.addColorStop(1, "#f0f0f0");
        context.fillStyle = grd;
        context.arc(this.point.x, this.point.y, RADIUS + Math.cos((this.offset * Math.PI) / 180) * 1.5, 0, 2 * Math.PI);
        context.shadowColor = "#333";
        context.shadowBlur = 5;
        context.shadowOffsetX = 3;
        context.shadowOffsetY = 3;
        context.fill();
        context.restore();
        if (delta > 40) {
            this.offset += 10;
            this.then = now;
        }
    };
    Dot.prototype.parent = function () {
        return this.parentItem;
    };
    Dot.prototype.canResize = function () {
        return false;
    };
    Object.defineProperty(Dot.prototype, "location", {
        get: function () {
            return this.point;
        },
        enumerable: true,
        configurable: true
    });
    Dot.prototype.boundingBox = function (context) {
        return new Rect(this.point.x - RADIUS - PADDING, this.point.y - RADIUS - PADDING, 2 * RADIUS + 2 * PADDING, 2 * RADIUS + 2 * PADDING);
    };
    Dot.prototype.move = function (x, y) {
        this.point.x -= x;
        this.point.y -= y;
        this.parentItem.isModified = true;
    };
    Dot.prototype.updatePosition = function (x, y) {
        this.point.x = x;
        this.point.y = y;
        this.parentItem.isModified = true;
    };
    Dot.prototype.toJSON = function () {
        return {
            x: this.point.x,
            y: this.point.y,
        };
    };
    Dot.prototype.renderCursor = function (mouseDown) {
        if (mouseDown) {
            return "-webkit-grabbing";
        }
        else {
            return "grab";
        }
    };
    return Dot;
}());
export { Dot };
