<div class="modal-header">
  <h4 class="modal-title pull-left brand">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <img src="/assets/cross.png" class="close-button" />
  </button>
</div>
<div class="modal-body">
  <p>Below is a list of all create-crop and update jobs.</p>

  <div *ngIf="data === null">Loading...</div>
  <div *ngIf="data !== null">
    <div class="ticket-row-header">
      <div class="ticket-first-part">
        <span class="ticket-date">Date</span
        ><span class="ticket-part-type">Type</span
        ><span class="ticket-part">Original name</span>
      </div>
      <span>Status</span>
    </div>

    <div *ngFor="let d of data">
      <div class="ticket-row" (click)="onClickRow(d.ticketId)">
        <div class="ticket-first-part">
          <span class="ticket-date">{{ d.formattedStarted }}</span
          ><span class="ticket-part-type">{{ d.ticketType }}</span
          ><span class="ticket-part">{{ d.name }}</span>
        </div>
        <span>{{ d.status }}</span>
      </div>
      <div *ngIf="showDetails[d.ticketId]">
        <div class="ticket-details">
          <div *ngFor="let m of d.messages">
            <div class="details-row">
              <div>
                <span class="details-timestamp">{{
                  m.formattedTimestamp
                }}</span>
                <span class="details-group">{{ m.group }}</span>
                <span class="details-type">{{ m.type }}</span>
              </div>
              <div class="message-section">
                <span class="details-timestamp"></span>
                <span class="details-message">{{ m.message }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-width"
    (click)="onCancel()"
  >
    Close
  </button>
</div>
