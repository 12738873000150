import { Rect } from "../models/renderable/rect";
import { TagItem } from "../models/renderable/tag.item";
import { MemoryContext } from "./memory.context";
import { ImageItem } from "../models/image.item";
import { CropItem } from "../models/renderable/crop.item";
import { JsonConverter } from "./json.converter";
import { Point } from "../models/renderable/point";
import { ImageMetadata } from "./image.metadata";
import { ErrorMessage } from "../models/error.message";
import { Histogram } from "./histogram";
import Fraction from "fraction.js";
import { MAT_CHECKBOX_REQUIRED_VALIDATOR } from "@angular/material";

export class ImageHelper {
  /**
   * Download an image and return as base64
   * @param url
   */
  static download(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch(url)
        .then(async function (res) {
          let blob = await res.blob();
          let srcURL = URL.createObjectURL(blob);
          let reader = new FileReader();
          reader.onload = () => {
            return resolve({
              srcURL: srcURL,
              buffer: new Uint8Array(reader.result as ArrayBuffer),
            });
          };

          reader.readAsArrayBuffer(blob);
        })
        .catch(function (error) {
          return reject(new ErrorMessage(ErrorMessage.ERROR, "", error));
        });
    });
  }

  /**
   *
   * @param base64URL
   */
  static createMemoryContext(data: any): Promise<MemoryContext> {
    return new Promise<MemoryContext>((resolve, reject) => {
      let img = new Image();

      img.onload = (event) => {
        let mem = new MemoryContext(img.width, img.height);
        let metadata = new ImageMetadata(data.buffer);

        mem.metadata = metadata.extractMetadata();
        mem.canvas.width = img.width;
        mem.canvas.height = img.height;
        mem.context.clearRect(0, 0, mem.canvas.width, mem.canvas.height);
        mem.context.drawImage(img, 0, 0, mem.canvas.width, mem.canvas.height);
        URL.revokeObjectURL(data.srcURL);

        return resolve(mem);
      };

      img.onerror = (event) => {
        URL.revokeObjectURL(data.srcURL);
        return reject(
          new ErrorMessage(
            ErrorMessage.ERROR,
            "Error creating memorycontext",
            new Error("").stack
          )
        );
      };

      img.src = data.srcURL;
    });
  }

  /**
   *
   * @param base64URL
   * @param memoryContext
   */
  static insertMetadata(
    base64URL: string,
    memoryContext: MemoryContext
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch(base64URL)
        .then(function (response) {
          return response.arrayBuffer();
        })
        .then(function (buffer) {
          let imageMeta = new ImageMetadata(new Uint8Array(buffer));
          let array = imageMeta.insertMetadata(memoryContext.metadata);
          let blob = new Blob([array], { type: "image/jpeg" });
          let reader = new FileReader();
          //ImageHelper.save("newimage.jpg", base64URLwMeta);

          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            console.log("done");
            resolve(reader.result);
          };
        })
        .catch(function (error) {
          reject(new ErrorMessage(ErrorMessage.ERROR, "", error));
        });
    });
  }

  /**
   * Loop through all tags of an image and generate a thumbnail
   * @param memoryContext
   * @param image
   */
  static generateThumbnails(memoryContext: MemoryContext, image: ImageItem) {
    if (!memoryContext) {
      throw new Error("MemoryContext is not valid");
    }

    let result = [];
    let height = image["canvasHeight"];
    let scale = memoryContext.canvas.height / height;

    image.imageTags.map((imageTag: TagItem) => {
      let rect: Rect = imageTag.box.boundingBox();
      let scaledRect = new Rect(
        rect.left * scale,
        rect.top * scale,
        rect.width * scale,
        rect.height * scale
      );

      let context = memoryContext.clone();

      const maxDim = 512;

      if (rect.width > rect.height) {
        context.canvas.width = maxDim; //rect.width * scale;
        context.canvas.height = (rect.height / rect.width) * maxDim; // rect.height * scale;
      } else {
        context.canvas.height = maxDim;
        context.canvas.width = (rect.width / rect.height) * maxDim;
      }

      // context.canvas.width = rect.width * scale;
      // context.canvas.height = rect.height * scale;
      context.drawImage(
        memoryContext.canvas,
        scaledRect.left,
        scaledRect.top,
        scaledRect.width,
        scaledRect.height,
        0,
        0,
        context.canvas.width,
        context.canvas.height
      );

      let base64 = context.canvas.toDataURL("image/jpeg", 0.96);
      result.push(base64);
    });

    return result;
  }

  /**
   * Finds closest full pixel crop smaller than or equal to the 'crop' parameter.
   *
   * @param crop raw scaled crop
   * @param ratio aspect ratio choosen by the user
   * @param origWidth original image width
   * @param origHeight original image height
   */
  static createAlignedCrop(
    crop: Rect,
    ratio: string,
    origWidth: number,
    origHeight: number
  ) {
    let left = Math.floor(crop.left);
    let top = Math.floor(crop.top);
    let width = Math.floor(crop.width);
    let height = Math.floor(crop.height);

    const ratioFraction =
      ratio === "free" ? new Fraction(-1) : new Fraction(ratio);

    // check how much width is misaligned
    const widthOffset = width % ratioFraction.n;

    // helper to calculate height
    const calcHeight = (w: number, f: Fraction) => f.inverse().mul(w).valueOf();

    // only check alignment with aspect ratio if not free form
    if (ratio !== "free") {
      if (widthOffset !== 0) {
        /**
         * example 3:4
         * x = width
         * y = height
         *
         * x / y = 3 / 4
         * line => y = 4 / 3 * x
         *
         */

        // aligned width
        width = width - widthOffset;

        // calculate new height
        height = calcHeight(width, ratioFraction);
      }
    }

    // check if we are inside the bounds of the original image
    // and reduce by numerator steps
    while (width > origWidth || height > origHeight) {
      width = width - ratioFraction.n;
      height = calcHeight(width, ratioFraction);

      if (width < 0 || height < 0) {
        console.warn("Invalid width or height reduction");
        return crop;
      }
    }

    while (left + width > origWidth) {
      left = left - 1;

      if (left < 0) {
        console.warn("Invalid width + left offset");
        return crop;
      }
    }

    while (top + height > origHeight) {
      top = top - 1;

      if (top < 0) {
        console.warn("Invalid top + height offset");
        return crop;
      }
    }

    return new Rect(left, top, width, height);
  }

  /**
   * Generate a crop and convert the coordinate of all dots in the cropping region
   * @param memoryContext
   * @param image
   * @param cropItem
   */
  static async generateCrop(
    memoryContext: MemoryContext,
    image: ImageItem,
    cropItem: CropItem,
    croppedBy: string,
    collections: any[],
    key: string
  ) {
    if (!memoryContext) {
      throw new Error("MemoryContext is not valid");
    }

    let height = image["canvasHeight"];
    let rect: Rect = cropItem.boundingBox();
    let scale = memoryContext.canvas.height / height;
    let scaledRect = new Rect(
      rect.left * scale,
      rect.top * scale,
      rect.width * scale,
      rect.height * scale
    );
    scaledRect = this.createAlignedCrop(
      scaledRect,
      cropItem.ratio,
      memoryContext.canvas.width,
      memoryContext.canvas.height
    );

    let tags = [];

    let context = memoryContext.clone();
    context.canvas.width = scaledRect.width;
    context.canvas.height = scaledRect.height;
    context.drawImage(
      memoryContext.canvas,
      scaledRect.left,
      scaledRect.top,
      scaledRect.width,
      scaledRect.height,
      0,
      0,
      scaledRect.width,
      scaledRect.height
    );
    let base64URL = context.canvas.toDataURL("image/jpeg", 1);

    if (memoryContext.metadata.length > 0) {
      base64URL = await ImageHelper.insertMetadata(base64URL, memoryContext);
    }

    image.imageTags.map((imageTag: TagItem) => {
      if (cropItem.contains(imageTag.dot.location) && imageTag.isVisible) {
        let clone: TagItem = imageTag.clone();
        let boxRect: Rect = clone.box.boundingBox();
        let dotPos: Point = clone.dot.location;
        clone.box.updateDimension(
          boxRect.left - rect.left,
          boxRect.top - rect.top,
          boxRect.width,
          boxRect.height
        );
        clone.dot.updatePosition(dotPos.x - rect.left, dotPos.y - rect.top);
        tags.push(clone);
      }
    });

    let jsonTags = JsonConverter.toJSON(tags, {
      width: rect.width,
      height: rect.height,
    });

    let json = {
      img: base64URL,
      data: {
        key: key,
        collections: collections,
        extraInfo: {
          cropRegion: scaledRect.toString(),
          croppedBy: croppedBy,
          original: image.id,
          originalName: image.name,
        },
        colorInfo: this.getHistogram(context),
        tags: jsonTags,
      },
    };

    return json;
  }

  static getHistogram(context: any) {
    let histogram = new Histogram(context);
    return histogram.create();
  }
}
