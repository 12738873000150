import { Component, OnInit } from '@angular/core';
import { ImageItem } from '../../models/image.item';
import { Router } from '@angular/router';
import { OperationContext, State } from '../../services/operation.context';

@Component({
  selector: 'images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  constructor(private router: Router,
    public operationContext: OperationContext) {
    if (sessionStorage.getItem('collection') == null) {
      router.navigate(['collections']);
      return;
    }
    router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    let collectionId = sessionStorage.getItem('collection');
    // Uncheck inspiration feed
    this.operationContext.inspirationalParentCheck = false;
    this.operationContext.inspirationalCropCheck = false;
    if (collectionId) {
      this.operationContext.loadImages(sessionStorage.getItem('collection'));
    }
  }

  toggleStateFilter(i) {
    const stateFilter = this.operationContext.stateFilter;
    stateFilter[i] = !stateFilter[i];
    this.operationContext.stateFilter = stateFilter;
  }

  filterImages(images: ImageItem[]) {
    return images.filter(image => {
        return this.operationContext.stateFilter[image.state];
      });
  }

  filterImagesOnChecked(images: ImageItem[], state: number) {
    const preFilteredImages = this.filterImages(images);
    return preFilteredImages.filter(image => image.state === state);
  }

  select(image: ImageItem) {
    this.operationContext.clearSelection();
    this.operationContext.selectedImage = image;
    this.router.navigate(['tagging']);
  }

  getImage(image: ImageItem) {
    return image.thumbnail;
  }

  getRatio(image: ImageItem) {
    return (image.width / image.height).toFixed(2);
  }

  get hasImages() {
    return this.operationContext.images && this.operationContext.images.length > 0;
  }

  get imagesLoaded() {
    return this.operationContext.imagesLoaded;
  }
}
