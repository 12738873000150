import { ISelectionHandle } from "./handle";
import { Handle } from "./handle";
import { MemoryContext } from "../../helpers/memory.context";

export interface ISelection {
  selectedItem?: any;
  selectionHandles?: ISelectionHandle[];
  currentHandle?: Handle;
  resize(memoryContext: MemoryContext, x: number, y: number);
  render(context: any);
}

export class Selection implements ISelection {
  private $currentHandle?: Handle;
  private $item: any;

  constructor(item: any) {
    this.$item = item;
  }

  get selectedItem(): any { return this.$item; }
  get selectionHandles(): ISelectionHandle[] { return []; }
  set currentHandle(value: Handle) { this.$currentHandle = value; }
  get currentHandle(): Handle { return this.$currentHandle; }

  resize(memoryContext: MemoryContext, x: number, y: number) { }
  render(context: any) { }
  renderHandle(context, handle) { }
  renderCursor(mouseDown: boolean) { }
}

