import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { OverlayComponent } from '../components/overlay/overlay.component';

@Injectable()
export class OverlayService {
  private overlayRef: OverlayRef = null;

  constructor(private overlay: Overlay) { }

  public show() {
    if (!this.overlayRef) {
      const positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically();

      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'transparent-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block(),
        positionStrategy
      });

      this.overlayRef = this.overlay.create(overlayConfig);
    }

    const spinnerOverlayPortal = new ComponentPortal(OverlayComponent);

    if (!this.overlayRef.hasAttached()) {
      const component = this.overlayRef.attach(spinnerOverlayPortal);
    }
  }

  public hide() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}