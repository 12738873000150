var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from "@angular/core";
import { OperationContext, State } from "../../services/operation.context";
import { CropItem } from "../../models/renderable/crop.item";
import { Hittester } from "../../helpers/hittester";
import { CropSelection } from "../../models/renderable/crop.selection";
import { DefaultSelection } from "../../models/renderable/default.selection";
import { ResizeSelection } from "../../models/renderable/resize.selection";
import { Dot } from "../../models/renderable/dot";
import { TagItem } from "../../models/renderable/tag.item";
import { Handle } from "../../models/renderable/handle";
import { Box } from "../../models/renderable/box";
import { MemoryContext } from "../../helpers/memory.context";
import { DrawingContext } from "../../helpers/drawing.context";
import { ImageItem } from "../../models/image.item";
import { DrawSelection } from "../../models/renderable/draw.selection";
import { Helpers } from "../../helpers/common";
import { ErrorMessage } from "../../models/error.message";
import { ImageHelper } from "../../helpers/image.helper";
import { DataService } from "../../services/data.service";
import { Progress } from "../../models/renderable/louise2.progress";
import { ProductItem } from "../../models/product.item";
var LEFT_BUTTON = 0x01;
var IDLE = 0x00;
var DRAWING = 0x01;
var SELECTION = 0x02;
var CROPPING = 0x03;
var RESIZING = 0x04;
var EXITING = 0x05;
var LOUISE2 = 0x06;
var RendererComponent = /** @class */ (function () {
    function RendererComponent(operationContext, dataService) {
        var _this = this;
        this.operationContext = operationContext;
        this.dataService = dataService;
        this.downX = 0;
        this.downY = 0;
        this.currentX = 0;
        this.currentY = 0;
        this.prevX = 0;
        this.prevY = 0;
        this.img = null;
        this.state = IDLE;
        this.hittestContext = null;
        this.drawingContext = null;
        this.animationId = 0;
        this.newItem = null;
        this.imageData = null;
        this.progress = null;
        this.lastTimestamp = 0;
        this.fps = 60;
        this.timestep = 1000 / this.fps;
        this.img = new Image();
        this.progress = new Progress();
        this.img.onload = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateImage()];
                    case 1:
                        _a.sent();
                        if (this.operationContext.isAIEnabled) {
                            this.state = LOUISE2;
                            this.dataService
                                .detect(this.img.src)
                                .then(function (items) {
                                if (!items) {
                                    _this.state = IDLE;
                                    return;
                                }
                                var _loop_1 = function (item) {
                                    var tagItem = TagItem.create({
                                        box: {
                                            l: item.box.left,
                                            t: item.box.top,
                                            w: item.box.width,
                                            h: item.box.height,
                                        },
                                        dot: {
                                            x: item.box.width / 2 + item.box.left,
                                            y: item.box.height / 2 + item.box.top,
                                        },
                                    });
                                    var matchedProduct = _this.operationContext.selectedImage.imageTags.find(function (item) {
                                        var box = item.box.boundingBox();
                                        var newBox = tagItem.boundingBox();
                                        return (Math.round(box.left) === newBox.left &&
                                            Math.round(box.top) === newBox.top &&
                                            Math.round(box.width) === newBox.width &&
                                            Math.round(box.height) === newBox.height);
                                    });
                                    if (matchedProduct === undefined) {
                                        _this.operationContext.selectedImage.imageTags.push(tagItem);
                                        tagItem.isModified = true;
                                    }
                                };
                                for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                                    var item = items_1[_i];
                                    _loop_1(item);
                                }
                                _this.state = IDLE;
                            })
                                .catch(function (error) {
                                console.log(error);
                            });
                        }
                        this.hittestContext = new MemoryContext(this.img.width, this.img.height);
                        this.drawingContext = new DrawingContext(this.img.width, this.img.height, this.imgCanvas.nativeElement);
                        this.animationId = window.requestAnimationFrame(function () {
                            return _this.render(0, _this.drawingContext);
                        });
                        this.operationContext.state = State.IDLE;
                        return [2 /*return*/];
                }
            });
        }); };
        this.img.onerror = function (event) {
            _this.updateImage();
            _this.operationContext.memoryContext = null;
            _this.operationContext.error = new ErrorMessage(ErrorMessage.LOADERROR, "Media not found", new Error("").stack);
            _this.operationContext.state = State.IDLE;
        };
    }
    RendererComponent.prototype.ngOnDestroy = function () {
        this.state = EXITING;
        this.operationContext.memoryContext = null;
        this.stopAnimation();
    };
    RendererComponent.prototype.initMemoryContext = function (id) {
        var _this = this;
        this.operationContext.memoryContext = new Promise(function (resolve, reject) {
            _this.operationContext
                .getDownloadImageUrl(id)
                .then(function (url) {
                return ImageHelper.download(url);
            })
                .then(function (result) {
                return ImageHelper.createMemoryContext(result);
            })
                .then(function (context) {
                resolve(context);
            })
                .catch(function (error) {
                reject(error);
            });
        });
        if (this.operationContext.memoryContext instanceof Promise) {
            this.operationContext.memoryContext.catch(function (error) {
                console.log(error);
            });
        }
    };
    RendererComponent.prototype.update = function (imageItem) {
        var _this = this;
        this.operationContext.state = State.LOADING;
        this.operationContext.memoryContext = null;
        this.initMemoryContext(imageItem.id);
        this.operationContext
            .getImage(imageItem.id)
            .then(function (imageData) {
            _this.imageData = imageData;
            _this.img.src = imageItem.thumbnail;
        })
            .catch(function (error) {
            _this.operationContext.error = new ErrorMessage(ErrorMessage.LOADERROR, "Media not found", error);
            _this.operationContext.state = State.IDLE;
        });
    };
    RendererComponent.prototype.stopAnimation = function () {
        window.cancelAnimationFrame(this.animationId);
    };
    RendererComponent.prototype.updateImage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var image;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.stopAnimation();
                        this.operationContext.selectedImage.old = true;
                        image = ImageItem.create(this.imageData);
                        return [4 /*yield*/, this.loadOptions(image).catch(function (err) { return console.log(err); })];
                    case 1:
                        _a.sent();
                        image["canvasWidth"] = this.img.width;
                        image["canvasHeight"] = this.img.height;
                        this.operationContext.clearSelection();
                        try {
                            image.parseTags();
                        }
                        catch (exc) {
                            console.log("Error parsing imagetags", exc.message);
                        }
                        this.operationContext.selectedImage = image;
                        return [2 /*return*/];
                }
            });
        });
    };
    RendererComponent.prototype.loadOptions = function (image) {
        var _this = this;
        var name = image.name;
        if (name.includes("-crop")) {
            var parts = name.split("-crop");
            name = parts[0];
        }
        return this.operationContext
            .getProductSuggestions(name)
            .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (result === undefined || !result) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all(result.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    return [2 /*return*/, this.dataService
                                            .searchPIA(item.id)
                                            .toPromise()
                                            .then(function (result) {
                                            var rawProduct = result.products[0];
                                            if (rawProduct) {
                                                var product = ProductItem.create(__assign({}, rawProduct, { type: item.type }));
                                                image.options.push(product);
                                            }
                                        })
                                            .catch(function (err) {
                                            console.log("getProductSuggestions", err);
                                        })];
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        }); });
    };
    RendererComponent.prototype.loadError = function () {
        return (this.operationContext.error &&
            this.operationContext.error.code == ErrorMessage.LOADERROR);
    };
    RendererComponent.prototype.start = function (event) {
        if (!event.target) {
            return;
        }
        if (!this.drawingContext) {
            return;
        }
        if (event.touches && event.touches.length > 1) {
            return;
        }
        var box = event.target.getBoundingClientRect();
        var scaleX = this.img.width / box.width;
        var scaleY = this.img.height / box.height;
        this.downX =
            ((event.clientX || event.targetTouches[0].clientX) - box.left) * scaleX;
        this.downY =
            ((event.clientY || event.targetTouches[0].clientY) - box.top) * scaleY;
        this.currentX = this.downX;
        this.currentY = this.downY;
        this.prevX = this.currentX;
        this.prevY = this.currentY;
        this.newItem = null;
        var items = this.operationContext.selectedImage.imageTags;
        if (this.operationContext.selection) {
            items = [this.operationContext.selection].concat(items);
        }
        if (this.operationContext.isCropEnabled) {
            items = [this.operationContext.selection, this.operationContext.cropItem];
        }
        var hittestinfo = Hittester.hittest(this.hittestContext, this.downX, this.downY, items);
        if ((event.buttons & LEFT_BUTTON) == LEFT_BUTTON || event.targetTouches) {
            if (hittestinfo.element) {
                //we are inside the bounds of an element
                if (hittestinfo.element instanceof Handle) {
                    this.operationContext.selection.currentHandle = hittestinfo.element;
                    this.operationContext.selection.currentHandle.rect =
                        this.operationContext.selection.selectedItem.boundingBox();
                    this.state = RESIZING;
                    return;
                }
                else if (hittestinfo.element instanceof CropItem) {
                    this.operationContext.selection = new CropSelection(hittestinfo.element, this.operationContext);
                    this.drawingContext.canvas.style.cursor =
                        hittestinfo.element.renderCursor(true);
                    this.state = CROPPING;
                    return;
                }
                else if (hittestinfo.element instanceof Dot) {
                    this.operationContext.selection = new DefaultSelection(hittestinfo.element);
                    this.drawingContext.canvas.style.cursor =
                        hittestinfo.element.renderCursor(true);
                    this.state = SELECTION;
                    return;
                }
                else if (hittestinfo.element instanceof Box) {
                    this.operationContext.selection = new ResizeSelection(hittestinfo.element);
                    this.state = SELECTION;
                    return;
                }
            }
        }
        if (this.operationContext.isCropEnabled) {
            return;
        }
        this.newItem = new TagItem({
            x: this.downX,
            y: this.downY,
            width: this.currentX - this.downX,
            height: this.currentY - this.downY,
            color: Helpers.getColor(),
        });
        this.operationContext.selection = new DrawSelection(this.newItem.box);
        this.state = DRAWING;
    };
    RendererComponent.prototype.move = function (event) {
        var pressed = event.buttons == 1 || event.targetTouches != undefined;
        if (!pressed) {
            this.state = IDLE;
        }
        if (!event.target) {
            return;
        }
        if (!this.drawingContext) {
            return;
        }
        if (event.touches !== undefined && event.touches.length > 1) {
            return;
        }
        event.preventDefault();
        var box = event.target.getBoundingClientRect();
        var scaleX = this.img.width / box.width;
        var scaleY = this.img.height / box.height;
        this.currentX =
            ((event.clientX || event.targetTouches[0].clientX) - box.left) * scaleX;
        this.currentY =
            ((event.clientY || event.targetTouches[0].clientY) - box.top) * scaleY;
        var items = this.operationContext.selectedImage.imageTags;
        if (this.operationContext.selection) {
            items = [this.operationContext.selection].concat(items);
            if (this.operationContext.isCropEnabled) {
                items = [
                    this.operationContext.selection,
                    this.operationContext.cropItem,
                ];
            }
            var selectedItem = this.operationContext.selection.selectedItem;
            if (this.state == DRAWING) {
                selectedItem
                    .parent()
                    .update(this.downX, this.downY, this.currentX - this.downX, this.currentY - this.downY);
            }
            else if (this.state == SELECTION) {
                if (selectedItem instanceof Dot) {
                    selectedItem.move(this.prevX - this.currentX, this.prevY - this.currentY);
                }
                else {
                    selectedItem
                        .parent()
                        .move(this.prevX - this.currentX, this.prevY - this.currentY);
                }
            }
            else if (this.state == CROPPING) {
                this.operationContext.cropItem.move(this.prevX - this.currentX, this.prevY - this.currentY, event.getModifierState("Shift"));
            }
            else if (this.state == RESIZING) {
                this.operationContext.selection.resize(this.hittestContext, this.currentX, this.currentY);
            }
        }
        if (pressed) {
            if (this.operationContext.selection) {
                this.operationContext.selection.selectedItem.renderCursor(pressed);
            }
        }
        else {
            var hittestinfo = Hittester.hittest(this.hittestContext, this.currentX, this.currentY, items);
            if (hittestinfo.element) {
                this.drawingContext.canvas.style.cursor =
                    hittestinfo.element.renderCursor(pressed);
            }
            else {
                this.drawingContext.canvas.style.cursor = "crosshair";
            }
        }
        this.prevX = this.currentX;
        this.prevY = this.currentY;
    };
    RendererComponent.prototype.end = function (event) {
        if (!event.target) {
            return;
        }
        if (this.state == DRAWING) {
            if (this.newItem.box.boundingBox().width >= 20) {
                this.operationContext.selectedImage.imageTags.push(this.newItem);
                if (this.operationContext.selection.selectedItem.canResize()) {
                    this.operationContext.selection = new ResizeSelection(this.operationContext.selection.selectedItem);
                }
            }
            else {
                this.operationContext.clearSelection();
            }
        }
        if (this.operationContext.selection) {
            this.drawingContext.canvas.style.cursor =
                this.operationContext.selection.selectedItem.renderCursor(false);
        }
        else {
            this.drawingContext.canvas.style.cursor = "crosshair";
        }
        this.state = IDLE;
    };
    RendererComponent.prototype.render = function (timestamp, ctx) {
        var _this = this;
        if (this.state == EXITING || this.operationContext.state == State.LOADING) {
            return;
        }
        this.animationId = window.requestAnimationFrame(function (timestamp) {
            return _this.render(timestamp, ctx);
        });
        if (timestamp - this.lastTimestamp < this.timestep) {
            return;
        }
        this.lastTimestamp = timestamp;
        this.draw(ctx);
    };
    RendererComponent.prototype.draw = function (ctx) {
        ctx.context.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.context.drawImage(this.img, 0, 0, ctx.canvas.width, ctx.canvas.height);
        // AI is loading, render progress
        if (this.state == LOUISE2) {
            this.progress.render(ctx.context);
            return;
        }
        for (var i = this.operationContext.selectedImage.imageTags.length - 1; i >= 0; i--) {
            if (this.operationContext.selectedImage.imageTags === undefined) {
                continue;
            }
            var item = this.operationContext.selectedImage.imageTags[i];
            if (!item.isVisible) {
                if (this.operationContext.selection &&
                    (this.operationContext.selection instanceof ResizeSelection ||
                        this.operationContext.selection instanceof DefaultSelection) &&
                    this.operationContext.selection.selectedItem.parent() === item) {
                    this.operationContext.clearSelection();
                }
                continue;
            }
            if (item.isSelected) {
                this.operationContext.selection = new ResizeSelection(item.box);
                item.isSelected = false;
            }
            item.render(ctx.context, !this.operationContext.isCropEnabled && this.operationContext.showBoxes);
        }
        if (this.operationContext.selection) {
            this.operationContext.selection.render(ctx.context);
        }
    };
    return RendererComponent;
}());
export { RendererComponent };
