<div class="modal-header">
  <h4 class="modal-title pull-left brand">{{ title }} </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <img src="/assets/cross.png" class="close-button" />
  </button>
</div>
<div class="modal-body">
  <p>{{ otherData }}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-width" (click)="onConfirm()">Yes</button>
    <button type="button" class="btn btn-secondary btn-width" (click)="onCancel()">No</button>
</div>