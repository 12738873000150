import { Rect } from "./rect";
import { Point } from "./point";
import Fraction from "fraction.js";

export class CropItem {
  public isModified: boolean;
  public isSelected: boolean;
  private rect: Rect;
  public width: number;
  public height: number;
  public readonly ratio: string;

  constructor(rect: Rect, width: number, height: number, ratio: string) {
    this.rect = rect;
    this.isModified = false;
    this.isSelected = false;
    this.width = width;
    this.height = height;
    this.ratio = ratio;
  }

  public render(context: any) {
    context.save();
    context.beginPath();
    context.rect(this.rect.left, this.rect.top, this.rect.width, this.rect.height);
    context.clip();
    context.restore();
  }

  public boundingBox(): Rect {
    return this.rect;
  }

  public move(x: number, y: number, ortho: boolean) {
    this.rect.left -= x;

    if (this.rect.left < 0) {
      this.rect.left = 0;
    } else if (this.rect.left + this.rect.width > this.width) {
      this.rect.left = this.width - this.rect.width;
    }

    if (ortho) {
      return;
    }
    
    this.rect.top -= y;

    if (this.rect.top < 0) {
      this.rect.top = 0;
    } else if (this.rect.top + this.rect.height > this.height) {
      this.rect.top = this.height - this.rect.height;
    }
  }

  public update(left: number, top: number, width: number, height: number) {    
    if (height < 0 ) {
      top = top + height;
    } 

    if (width < 0 ) {
      left = left + width;
    } 

    this.rect.left = left;
    this.rect.top = top;
    this.rect.height = Math.abs(height);
    this.rect.width = Math.abs(width);
  }

  public contains(point: Point) {
    return this.rect.left <= point.x &&
      this.rect.left + this.rect.width >= point.x &&
      this.rect.top <= point.y &&
      this.rect.top + this.rect.height >= point.y;
  }

  public renderCursor(mouseDown: boolean) {
    if (mouseDown) {
      return '-webkit-grabbing';
    } else {
      return 'grab';
    }
  }
}