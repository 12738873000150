import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { AdalService } from 'adal-angular4';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private adal: AdalService, private router: Router) {}
  
  canActivate() {
    if (this.adal.userInfo.authenticated) {
      return true;
    } else {
      this.router.navigate(['splash']);
    }

    return false;
  }
}