var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ErrorMessage } from "../models/error.message";
export function formatDate(dt) {
    return dt.getFullYear().toString().padStart(4, "0") + "-" + (dt.getMonth() + 1)
        .toString()
        .padStart(2, "0") + "-" + dt.getDate().toString().padStart(2, "0") + " " + dt
        .getHours()
        .toString()
        .padStart(2, "0") + ":" + dt.getMinutes().toString().padStart(2, "0") + ":" + dt
        .getSeconds()
        .toString()
        .padStart(2, "0");
}
function sleep(ms) {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms);
    });
}
export function isCompletedTicket(r) {
    var newAssetStarted = r.messages.find(function (q) { return q.type === "new-asset-message-sent" && q.group == "new-asset"; });
    var newAssetCompleted = r.messages.find(function (q) { return q.type === "new-bynder-asset-added" && q.group == "new-asset"; });
    var updateAssetStarted = r.messages.find(function (q) { return q.type === "update-asset-message-sent" && q.group == "update-asset"; });
    var updateAssetCompleted = r.messages.find(function (q) { return q.type === "bynder-asset-updated" && q.group == "update-asset"; });
    var a = Boolean(newAssetStarted) === Boolean(newAssetCompleted);
    var b = Boolean(updateAssetStarted) === Boolean(updateAssetCompleted);
    return {
        completed: a && b,
        isNewAsset: a,
        isUpdatedAsset: b,
        newAssetStarted: newAssetStarted,
        newAssetCompleted: newAssetCompleted,
        updateAssetStarted: updateAssetStarted,
        updateAssetCompleted: updateAssetCompleted,
    };
}
export function isFailedTicket(r) {
    var failTypes = ["new-bynder-asset-failed", "update-asset-failed"];
    for (var _i = 0, _a = r.messages; _i < _a.length; _i++) {
        var m = _a[_i];
        for (var _b = 0, failTypes_1 = failTypes; _b < failTypes_1.length; _b++) {
            var f = failTypes_1[_b];
            // check all fails
            if (m.type === f) {
                return m;
            }
        }
    }
    return null;
}
var DataService = /** @class */ (function () {
    function DataService(http) {
        this.http = http;
    }
    /**
     *
     * @param collection
     */
    DataService.prototype.getImages = function (collection) {
        return __awaiter(this, void 0, void 0, function () {
            var url, headers, httpOptions;
            return __generator(this, function (_a) {
                url = environment.url + "/media/?collectionId=" + collection;
                headers = new HttpHeaders();
                headers = headers.append("Content-Type", "application/json");
                httpOptions = {
                    headers: headers,
                };
                return [2 /*return*/, this.http.get(url, httpOptions).toPromise()];
            });
        });
    };
    /**
     * fetches all images in a collection, with multiple calls
     */
    DataService.prototype.getImagesPaged = function (collection) {
        return __awaiter(this, void 0, void 0, function () {
            var page, out, r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page = 1;
                        out = [];
                        _a.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getImagesPage(collection, page)];
                    case 2:
                        r = _a.sent();
                        console.log(page, r);
                        if (r.results) {
                            out.push.apply(out, r.results);
                        }
                        if (r.isMaxCountResponse) {
                            // we got maximum number of response images back
                            page += 1;
                        }
                        else {
                            return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, out];
                }
            });
        });
    };
    DataService.prototype.getImagesPage = function (collection, page) {
        return __awaiter(this, void 0, void 0, function () {
            var url, headers, httpOptions;
            return __generator(this, function (_a) {
                url = environment.url +
                    "/media-paged/?collectionId=" +
                    collection +
                    "&page=" +
                    String(page);
                headers = new HttpHeaders();
                headers = headers.append("Content-Type", "application/json");
                httpOptions = {
                    headers: headers,
                };
                return [2 /*return*/, this.http
                        .get(url, httpOptions)
                        .toPromise()];
            });
        });
    };
    /**
     *
     * @param id
     */
    DataService.prototype.getImage = function (id) {
        var url = environment.url + "/media/asset/" + id;
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions).toPromise();
    };
    /**
     *
     */
    DataService.prototype.getCollections = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, headers, httpOptions;
            return __generator(this, function (_a) {
                url = environment.url + "/collections";
                headers = new HttpHeaders();
                headers = headers.append("Content-Type", "application/json");
                httpOptions = {
                    headers: headers,
                };
                return [2 /*return*/, this.http.get(url, httpOptions).toPromise()];
            });
        });
    };
    /**
     *
     */
    DataService.prototype.getDownloadImageUrl = function (id) {
        var url = environment.url + "/media/" + id + "/download";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions);
    };
    /**
     *
     * @param text
     */
    DataService.prototype.searchSPR = function (text) {
        var url = environment.url + "/search/spr/" + text;
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions);
    };
    /**
     *
     * @param keyword
     */
    DataService.prototype.searchKeyword = function (keyword) {
        var url = environment.url + "/search/bynder/?parameter=keyword&value=" + keyword;
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions).toPromise();
    };
    /**
     *
     * @param text
     */
    DataService.prototype.searchPIA = function (text) {
        var url = environment.url +
            "/search/pia/" +
            text +
            "?countryCode=SE&languageCode=en";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions);
    };
    /**
     *
     * @param base64
     */
    DataService.prototype.searchIVS = function (base64) {
        var url = environment.url + "/search/ivs";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        var params = new HttpParams().append("data", base64);
        return this.http.post(url, params, httpOptions);
    };
    /**
     *
     */
    DataService.prototype.getProductSuggestions = function (id) {
        var url = environment.url + "/content/" + id;
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions);
    };
    /**
     *
     * @param imageId
     * @param data
     */
    DataService.prototype.saveTags = function (imageId, data) {
        var url = environment.url + "/media";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        var json = {
            assetId: imageId,
            tagObject: data,
        };
        return this.http.post(url, json, httpOptions);
    };
    DataService.prototype.saveTagsNew = function (snackBar, imageId, data) {
        return __awaiter(this, void 0, void 0, function () {
            var url, headers, httpOptions, json, showMsg, res;
            var _this = this;
            return __generator(this, function (_a) {
                url = environment.url + "/media";
                headers = new HttpHeaders();
                headers = headers.append("Content-Type", "application/json");
                httpOptions = {
                    headers: headers,
                };
                json = {
                    assetId: imageId,
                    tagObject: data,
                };
                showMsg = function (panelClass, msg, detail) {
                    if (detail === void 0) { detail = ""; }
                    snackBar.show(msg, detail, {
                        duration: 5000,
                        panelClass: [panelClass, "testing"],
                        verticalPosition: "top",
                        horizontalPosition: "center",
                    });
                };
                res = this.http.post(url, json, httpOptions);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        res.subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                console.log(result);
                                showMsg("blue", "Image processing started.");
                                resolve(new ErrorMessage(ErrorMessage.OK, "Image processing started.", ""));
                                setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                    var i, r, isFailedCheckResult, checkResult;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                i = 0;
                                                _a.label = 1;
                                            case 1:
                                                if (!(i < 36 * 2)) return [3 /*break*/, 5];
                                                return [4 /*yield*/, this.checkTicket(result.TicketId)];
                                            case 2:
                                                r = _a.sent();
                                                console.log(r);
                                                if (r.StatusCode === 404) {
                                                    showMsg("red", "Could not check save status" + " \n " + r.Message);
                                                    return [2 /*return*/];
                                                }
                                                isFailedCheckResult = isFailedTicket(r.data.Item);
                                                if (isFailedCheckResult !== null) {
                                                    showMsg("red", isFailedCheckResult.type +
                                                        " \n" +
                                                        JSON.stringify(isFailedCheckResult.message, null, 4));
                                                    return [2 /*return*/];
                                                }
                                                checkResult = isCompletedTicket(r.data.Item);
                                                if (checkResult.completed) {
                                                    return [2 /*return*/];
                                                    // if (checkResult.isNewAsset) {
                                                    //   const a = r.data.Item.messages.find(
                                                    //     (q) =>
                                                    //       q.group === "new-asset" &&
                                                    //       q.type === "uploading-to-bynder"
                                                    //   );
                                                    //   let assetName = "unknown";
                                                    //   if (
                                                    //     a &&
                                                    //     typeof a.message === "object" &&
                                                    //     "assetName" in a.message
                                                    //   ) {
                                                    //     assetName = a.message.assetName;
                                                    //   }
                                                    //   const b = r.data.Item.messages.find(
                                                    //     (q) =>
                                                    //       q.group === "new-asset" && q.type === "adding-to-boards"
                                                    //   );
                                                    //   let boards = Array.isArray(b)
                                                    //     ? b.join(", ")
                                                    //     : JSON.stringify(b, null, 4);
                                                    //   showMsg("green", `"${assetName}" saved.`, "");
                                                    //   return;
                                                    // } else if (checkResult.isUpdatedAsset) {
                                                    //   showMsg("green", `Image saved.`, "");
                                                    //   return;
                                                    // }
                                                }
                                                // show last message
                                                // const messages = r.data.Item.messages.sort(
                                                //   (a, b) => a.timestamp - b.timestamp
                                                // );
                                                // showMsg(
                                                //   "blue",
                                                //   "Image processing started.\n" +
                                                //     messages
                                                //       .map(
                                                //         (m) =>
                                                //           `${this.formatDate(new Date(m.timestamp))} - ${m.type}`
                                                //       )
                                                //       .join("\n")
                                                // );
                                                return [4 /*yield*/, sleep(5 * 1000)];
                                            case 3:
                                                // show last message
                                                // const messages = r.data.Item.messages.sort(
                                                //   (a, b) => a.timestamp - b.timestamp
                                                // );
                                                // showMsg(
                                                //   "blue",
                                                //   "Image processing started.\n" +
                                                //     messages
                                                //       .map(
                                                //         (m) =>
                                                //           `${this.formatDate(new Date(m.timestamp))} - ${m.type}`
                                                //       )
                                                //       .join("\n")
                                                // );
                                                _a.sent();
                                                _a.label = 4;
                                            case 4:
                                                i++;
                                                return [3 /*break*/, 1];
                                            case 5:
                                                resolve(new ErrorMessage(ErrorMessage.ERROR, "Processing did not complete in 5 min.", ""));
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, 0);
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            reject(new ErrorMessage(ErrorMessage.ERROR, "Could not save image", error));
                        });
                    })];
            });
        });
    };
    /**
     *
     * @param imageId
     * @param data
     */
    DataService.prototype.checkTicket = function (ticketId) {
        var url = environment.url + "/media";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        var json = {
            ticketId: ticketId,
        };
        var r = this.http.post(url, json, httpOptions);
        return new Promise(function (resolve, reject) {
            r.subscribe(resolve, reject);
        });
    };
    DataService.prototype.getAllTickets = function () {
        var url = environment.url + "/media";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        var json = {
            getAllTicketsForUser: true,
        };
        var r = this.http.post(url, json, httpOptions);
        return new Promise(function (resolve, reject) {
            r.subscribe(resolve, reject);
        });
    };
    /**
     *
     * @param imageUrl
     */
    DataService.prototype.detect = function (imageUrl) {
        var url = environment.url + "/ai/detect";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "text/plain");
        var httpOptions = {
            headers: headers,
        };
        return this.http.post(url, imageUrl, httpOptions).toPromise();
    };
    DataService.prototype.fetchIDAMProducts = function (name) {
        console.log("fetching products");
        var url = environment.ladanUrl + "/api/idam/asset-products/" + encodeURIComponent(name);
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions).toPromise();
    };
    /**
     *
     * @param imageUrl
     * @param boundingBoxes
     */
    DataService.prototype.identify = function (imageUrl, boundingBoxes) {
        var url = environment.url + "/ai/identify";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http
            .post(url, {
            url: imageUrl,
            boundingBox: boundingBoxes,
        }, httpOptions)
            .toPromise();
    };
    /**
     *
     * @param file
     */
    DataService.prototype.uploadFile = function (file) {
        var url = environment.url + "/content/upload";
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.post(url, file, httpOptions).toPromise();
    };
    /**
     *
     * @param id
     */
    DataService.prototype.getPresignedURL = function (key) {
        var url = environment.url + "/asset/signedurl/upload/put/" + key;
        var headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        var httpOptions = {
            headers: headers,
        };
        return this.http.get(url, httpOptions).toPromise();
    };
    /**
     *
     * @param file
     */
    DataService.prototype.uploadFileToS3 = function (base64, key) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var blob, url, xhr_1, file, exc_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch(base64).then(function (res) { return res.blob(); })];
                    case 1:
                        blob = _a.sent();
                        return [4 /*yield*/, this.getPresignedURL(key)];
                    case 2:
                        url = _a.sent();
                        xhr_1 = new XMLHttpRequest();
                        xhr_1.open("PUT", url.signedUrl, true);
                        xhr_1.onload = function () {
                            if (xhr_1.readyState == 4 && xhr_1.status == 200) {
                                resolve(new ErrorMessage(ErrorMessage.OK, "Cropped image successfully uploaded", ""));
                            }
                            else {
                                reject(new ErrorMessage(ErrorMessage.ERROR, "Error uploading cropped image", ""));
                            }
                        };
                        file = new File([blob], key);
                        xhr_1.send(file);
                        return [3 /*break*/, 4];
                    case 3:
                        exc_1 = _a.sent();
                        reject(new ErrorMessage(ErrorMessage.ERROR, "Error uploading cropped image", exc_1));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    return DataService;
}());
export { DataService };
