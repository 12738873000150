var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Context } from "./context";
var MemoryContext = /** @class */ (function (_super) {
    __extends(MemoryContext, _super);
    function MemoryContext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MemoryContext.prototype, "metadata", {
        get: function () {
            return this.$metadata;
        },
        set: function (value) {
            this.$metadata = value;
        },
        enumerable: true,
        configurable: true
    });
    MemoryContext.prototype.clone = function () {
        var canvas = document.createElement('canvas');
        return canvas.getContext('2d');
    };
    return MemoryContext;
}(Context));
export { MemoryContext };
