import { Component, OnInit, ViewChild, ElementRef, SecurityContext, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'searchfield',
  templateUrl: './searchfield.component.html',
  styleUrls: ['./searchfield.component.css']
})
export class SearchFieldComponent implements OnInit {
  @ViewChild('input') input: any;
  @ViewChild('formControl') formControl: ElementRef;

  inputController: FormControl;
  public search: string = "";
  public searchActive: boolean;

  constructor(private router: Router,
    private sanitizer: DomSanitizer,
    private dataService: DataService) { }

  ngOnInit() {
    this.search = sessionStorage.getItem('query');
    this.searchActive = sessionStorage.getItem('searchActive') === "true";
    this.inputController = new FormControl();
    this.inputController.setValue(this.search);

    this.focus();

    this.inputController.valueChanges
      .pipe(debounceTime(500))
      .subscribe(data => {
        let sanitized = this.sanitizer.sanitize(SecurityContext.HTML, data);

        sessionStorage.setItem('query', sanitized);

        if (sanitized === "") {
          return;
        }

        this.router.navigate(['searchresult'], { queryParams: { query: sanitized } });
      });
  }

  clear() {
    this.search = "";
    this.inputController.setValue(this.search);
    sessionStorage.setItem('query', this.search);
    this.router.navigate(['collections']);
  }

  toggle() {
    this.searchActive = !this.searchActive;
    sessionStorage.setItem('searchActive', this.searchActive.toString());

    if (!this.searchActive) {
      this.clear();
    } else {
      this.focus();
    }
  }

  focus() {
    setTimeout(() => {
      this.input.nativeElement.focus({
        preventScroll: true
      });
    }, 0);
  }
}
