/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./renderer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./renderer.component";
import * as i4 from "../../services/operation.context";
import * as i5 from "../../services/data.service";
var styles_RendererComponent = [i0.styles];
var RenderType_RendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RendererComponent, data: {} });
export { RenderType_RendererComponent as RenderType_RendererComponent };
function View_RendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["imgCanvas", 1]], null, 0, "canvas", [], null, [[null, "touchstart"], [null, "touchmove"], [null, "touchend"], [null, "mousedown"], [null, "mousemove"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (_co.start($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchmove" === en)) {
        var pd_1 = (_co.move($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (_co.end($event) !== false);
        ad = (pd_2 && ad);
    } if (("mousedown" === en)) {
        var pd_3 = (_co.start($event) !== false);
        ad = (pd_3 && ad);
    } if (("mousemove" === en)) {
        var pd_4 = (_co.move($event) !== false);
        ad = (pd_4 && ad);
    } if (("mouseup" === en)) {
        var pd_5 = (_co.end($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null))], null, null); }
function View_RendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "renderer-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.operationContext.error.message; _ck(_v, 2, 0, currVal_0); }); }
export function View_RendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imgCanvas: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RendererComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RendererComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadError(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loadError(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "renderer", [], null, null, null, View_RendererComponent_0, RenderType_RendererComponent)), i1.ɵdid(1, 180224, null, 0, i3.RendererComponent, [i4.OperationContext, i5.DataService], null, null)], null, null); }
var RendererComponentNgFactory = i1.ɵccf("renderer", i3.RendererComponent, View_RendererComponent_Host_0, {}, {}, []);
export { RendererComponentNgFactory as RendererComponentNgFactory };
