import { AfterViewInit } from "@angular/core";
import { OperationContext } from "../../services/operation.context";
var MAX_HEIGHT = 555;
var ITEM_HEIGHT = 111;
var DropDownComponent = /** @class */ (function () {
    function DropDownComponent(operationContext) {
        this.operationContext = operationContext;
        this.$isOpen = false;
        this.width = 400;
        this.wasInside = false;
    }
    DropDownComponent.prototype.clickInside = function ($event) {
        this.wasInside = true;
    };
    DropDownComponent.prototype.clickout = function () {
        if (!this.wasInside) {
            this.close();
        }
        this.wasInside = false;
    };
    DropDownComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var selectedColl = this.operationContext.selectedCollection;
        var isBackfillBoard = false;
        if (selectedColl.name &&
            selectedColl.name.toUpperCase().includes("BACKFILL")) {
            isBackfillBoard = true;
        }
        this.$collection = this.operationContext.collections.filter(function (item) {
            if (isBackfillBoard && item.name && item.name.toUpperCase() === 'BACKFILL_RESULT') {
                item.selected = true;
                return true;
            }
            // this.$collection = [backfill_result];
            /* Always show current board in dropdown */
            if (_this.operationContext.selectedCollection.id == item.id) {
                item.selected = true;
                return true;
            }
            else {
                item.selected = false;
                return false;
            }
        });
    };
    Object.defineProperty(DropDownComponent.prototype, "collections", {
        get: function () {
            return this.$collection;
        },
        enumerable: true,
        configurable: true
    });
    DropDownComponent.prototype.getWidth = function () {
        return this.width;
    };
    DropDownComponent.prototype.getHeight = function () {
        if (this.collections.length === 0) {
            return ITEM_HEIGHT;
        }
        if (this.collections.length > 5) {
            return MAX_HEIGHT;
        }
        return ITEM_HEIGHT * this.collections.length + 3;
    };
    DropDownComponent.prototype.getImage = function (collection) {
        return collection.thumbnail;
    };
    DropDownComponent.prototype.isOpen = function () {
        return this.$isOpen;
    };
    DropDownComponent.prototype.toggle = function (event) {
        this.$isOpen = !this.$isOpen;
    };
    DropDownComponent.prototype.close = function () {
        this.$isOpen = false;
    };
    DropDownComponent.prototype.toggleCheck = function (collection) {
        collection.selected = !collection.selected;
    };
    DropDownComponent.prototype.hasItems = function () {
        return this.collections && this.collections.length > 0;
    };
    DropDownComponent.prototype.isCurrent = function (collection) {
        return this.operationContext.selectedCollection.name == collection.name;
    };
    return DropDownComponent;
}());
export { DropDownComponent };
