var Selection = /** @class */ (function () {
    function Selection(item) {
        this.$item = item;
    }
    Object.defineProperty(Selection.prototype, "selectedItem", {
        get: function () { return this.$item; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Selection.prototype, "selectionHandles", {
        get: function () { return []; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Selection.prototype, "currentHandle", {
        get: function () { return this.$currentHandle; },
        set: function (value) { this.$currentHandle = value; },
        enumerable: true,
        configurable: true
    });
    Selection.prototype.resize = function (memoryContext, x, y) { };
    Selection.prototype.render = function (context) { };
    Selection.prototype.renderHandle = function (context, handle) { };
    Selection.prototype.renderCursor = function (mouseDown) { };
    return Selection;
}());
export { Selection };
