import { Component, OnInit } from '@angular/core';
import { ImageItem } from '../../models/image.item';
import { Router, ActivatedRoute } from '@angular/router';
import { OperationContext, State } from '../../services/operation.context';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css']
})
export class SearchResultComponent implements OnInit {
  result: ImageItem[] = [];
  $imagesLoaded = false;

  constructor(private router: Router,
    public operationContext: OperationContext,
    private route: ActivatedRoute,
    private dataService: DataService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.result = [];
      this.$imagesLoaded = false;

      let previousQuery = sessionStorage.getItem('query');

      this.dataService.searchKeyword(params.query || previousQuery)
        .then(result => {
          for (let i = 0; i < result.length; i++) {
            let imageItem = ImageItem.create(result[i]);
            this.result.push(imageItem);
          }

          this.$imagesLoaded = true;
        });
    });
  }

  select(image: ImageItem) {
    this.operationContext.clearSelection();
    this.operationContext.selectedImage = image;
    sessionStorage.removeItem("collection");
    this.operationContext.selectedCollection = null;
    this.router.navigate(['tagging']);
  }

  getImage(image: ImageItem) {
    return image.thumbnail;
  }

  getRatio(image: ImageItem) {
    return (image.width / image.height).toFixed(2);
  }

  get hasImages() {
    return this.result && this.result.length > 0;
  }

  get imagesLoaded() {
    return this.$imagesLoaded;
  }
}
