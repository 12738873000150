import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashComponent } from './components/splash/splash.component';
import { ImagesComponent } from './components/images/images.component';
import { CollectionComponent } from './components/collection/collection.component';
import { TaggingComponent } from './components/tagging/tagging.component';
import { AuthGuardService } from './session/auth.guard';
import { SearchResultComponent } from './components/searchresult/searchresult.component';

const routes: Routes = [
  { path: 'collections', component: CollectionComponent, canActivate: [AuthGuardService] },
  { path: 'images', component: ImagesComponent, canActivate: [AuthGuardService] },
  { path: 'tagging', component: TaggingComponent, canActivate: [AuthGuardService] },
  { path: 'searchresult', component: SearchResultComponent, canActivate: [AuthGuardService] },
  { path: 'splash', component: SplashComponent },
  { path: '**', redirectTo: 'splash', pathMatch: 'full', canActivate: [AuthGuardService]  },
  { path: '', redirectTo: 'splash', pathMatch: 'full', canActivate: [AuthGuardService] }
];

@NgModule({
imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
  providers: []
})

export class RoutingModule { }
