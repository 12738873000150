<progressbar></progressbar>
<div class="mat-app-background basic-container">
  <nav mat-tab-nav-bar>
    <div
      mat-tab-link
      routerLink="/collections"
      routerLinkActive
      #rla_collection="routerLinkActive"
      [active]="rla_collection.isActive"
    >
      Board view
    </div>
    <div
      *ngIf="!isDisabled()"
      mat-tab-link
      routerLink="/images"
      routerLinkActive
      #rla_image="routerLinkActive"
      [active]="rla_image.isActive"
      [disabled]="false"
    >
      <span
        *ngIf="todoCount() > 0"
        [matBadge]="todoCount()"
        matBadgeOverlap="false"
        >Images view</span
      >
      <span *ngIf="todoCount() == 0">Images view</span>
    </div>
    <div *ngIf="isDisabled()" mat-tab-link [disabled]="true">Images view</div>
    <div
      *ngIf="hasImage()"
      mat-tab-link
      routerLink="/tagging"
      routerLinkActive
      #rla_tag="routerLinkActive"
      [active]="rla_tag.isActive"
      [disabled]="false"
    >
      Tagging view
    </div>
    <div *ngIf="!hasImage()" mat-tab-link [disabled]="true">Tagging view</div>

    <div
      [hidden]="isSearchDisabled()"
      mat-tab-link
      routerLink="/searchresult"
      routerLinkActive
      #rla_search="routerLinkActive"
      [active]="rla_search.isActive"
      [disabled]="false"
    >
      Search result
    </div>
  </nav>
  <div class="comps">
    <searchfield></searchfield>
    <button mat-icon-button>
      <mat-icon class="white-icon" title="check status" (click)="infoClick()"
        >info</mat-icon
      >
    </button>
    <div class="wrapper" [matMenuTriggerFor]="menu">
      <button mat-icon-button>
        <mat-icon class="white-icon" title="{{ username }}"
          >account_circle</mat-icon
        >
      </button>
      <span class="user" title="{{ username }}">{{ username }}</span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="initUpload()">
          <span>Upload file</span>
          <input
            type="file"
            accept=".json"
            name="files[]"
            style="display: none"
            (change)="uploadFile($event)"
            #fileUpload
          />
        </button>
        <p mat-menu-item (click)="toggleAI(); $event.stopPropagation()">
          <mat-icon *ngIf="operationContext.isAIEnabled">check_box</mat-icon>
          <mat-icon *ngIf="!operationContext.isAIEnabled"
            >check_box_outline_blank</mat-icon
          >
          <span>Louise 2</span>
        </p>
        <mat-divider></mat-divider>
        <p mat-menu-item>
          <span>Crop Type</span>
        </p>
        <mat-radio-group [(ngModel)]="operationContext.cropRatio">
          <div
            mat-menu-item
            (click)="toggleCrop(); $event.stopPropagation()"
            *ngFor="let cropRatio of env.cropRatios"
          >
            <mat-radio-button [value]="cropRatio">{{
              cropRatio
            }}</mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
