import { Component, HostListener } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OperationContext, State } from "../../services/operation.context";
import { ErrorMessage } from "../../models/error.message";
import { ModalConfirmationService } from "../../services/confirmation.service";
import { ImageItem } from "../../models/image.item";
import { Rect } from "../../models/renderable/rect";
import { ImageHelper } from "../../helpers/image.helper";
import { ThrowStmt } from "@angular/compiler";

const KEY_S = 83;
const KEY_Z = 90;
const KEY_PAGE_UP = 33;
const KEY_PAGE_DOWN = 34;

@Component({
  selector: "tagging",
  templateUrl: "./tagging.component.html",
  styleUrls: ["./tagging.component.css"],
})
export class TaggingComponent {
  private layoutName;
  private inspirationalParentCheck = false;
  private inspirationalCropCheck = false;
  private isLoadingIDAMData = false;

  constructor(
    private route: ActivatedRoute,
    public operationContext: OperationContext,
    public modalService: ModalConfirmationService
  ) {}

  @HostListener("document:keydown", ["$event"]) private handleKeydown(
    event: KeyboardEvent
  ) {
    if (this.operationContext.state != State.IDLE) {
      return;
    }

    switch (event.keyCode) {
      case KEY_S: {
        if (event.ctrlKey) {
          event.preventDefault();
          this.save(event);
        }
        break;
      }

      case KEY_Z: {
        if (event.ctrlKey) {
          event.preventDefault();
          this.undo();
        }
        break;
      }

      case KEY_PAGE_UP: {
        event.preventDefault();
        this.moveDown();
        break;
      }

      case KEY_PAGE_DOWN: {
        event.preventDefault();
        this.moveUp();
        break;
      }
    }
  }

  cancel() {}

  undo() {}

  onLayoutNameChange(name) {
    console.log("Setting layoutName to", name);
    this.layoutName = name;
    this.operationContext.layoutName = name;
  }

  onInputChange() {
    console.log("Setting layoutName to", this.layoutName);
    this.operationContext.layoutName = this.layoutName;
  }

  moveDown() {
    console.log;
    let item = this.operationContext.selection.selectedItem.parent();
    let index = this.operationContext.selectedImage.imageTags.indexOf(item);

    console.log("DOWN");
    if (index >= 1) {
      this.moveItem(index - 1, index);
    }
  }

  moveUp() {
    let item = this.operationContext.selection.selectedItem.parent();
    let index = this.operationContext.selectedImage.imageTags.indexOf(item);

    console.log("UP");
    if (index < this.operationContext.selectedImage.imageTags.length - 1) {
      this.moveItem(index + 1, index);
    }
  }

  moveItem(to, from) {
    this.operationContext.selectedImage.moveTag(to, from);
  }

  sortTagsByArea() {
    this.operationContext.selectedImage.sortTagsByAreaSize();
  }

  hasTags() {
    return this.operationContext.selectedImage.imageTags.length > 0;
  }

  async save(event) {
    this.operationContext.state = State.SAVING;

    this.canSave()
      .then((canSave) => {
        if (canSave) {
          // debugger
          return this.operationContext.saveTags();
        }

        return Promise.reject();
      })
      .then((result) => {
        this.operationContext.selectedImage.dateLoaded = Date.now() + 30000;
        this.operationContext.selectedImage.clearIsModified();
        this.operationContext.selection = null;
        this.operationContext.error = result;
      })
      .catch((error) => {
        this.operationContext.error = error;
      })
      .finally(() => {
        this.operationContext.state = State.IDLE;
      });
  }

  hasImage() {
    return this.operationContext.selectedImage;
  }

  isSaving() {
    return this.operationContext.state == State.SAVING;
  }

  canClick() {
    let loadState: boolean = this.operationContext.state === State.IDLE;
    let error: boolean = this.operationContext.error === null;

    return loadState || error;
  }

  async loadIDAMData() {
    console.log("load");
    this.isLoadingIDAMData = true;
    await this.operationContext.loadIDAMProductTags();
    this.isLoadingIDAMData = false;
  }

  canLoadIDAMData() {
    // console.log("check", this.operationContext.selectedImage.imageTags);

    return (
      this.operationContext.selectedImage.imageTags.length === 0 &&
      this.operationContext.state === State.IDLE &&
      this.isLoadingIDAMData === false
    );
  }

  loadError() {
    return (
      this.operationContext.error &&
      this.operationContext.error.code == ErrorMessage.LOADERROR
    );
  }

  toggleBoxes() {
    this.operationContext.showBoxes = !this.operationContext.showBoxes;
  }

  toggle() {
    if (this.operationContext.selectedImage === undefined) {
      return;
    }

    this.operationContext.isCropEnabled = !this.operationContext.isCropEnabled;
  }

  getDimension() {
    let fullHeight = this.operationContext.selectedImage.height;
    let fullWidth = this.operationContext.selectedImage.width;
    let height = this.operationContext.selectedImage["canvasHeight"];
    let rect: Rect = this.operationContext.cropItem.boundingBox();

    let scale = fullHeight / height;
    let newWidth = rect.width * scale;
    let newHeight = rect.height * scale;

    const crop = new Rect(0, 0, newWidth, newHeight);
    const alignedCrop = ImageHelper.createAlignedCrop(
      crop,
      this.operationContext.cropRatio,
      fullWidth,
      fullHeight
    );

    // return Math.abs(newWidth).toFixed(0) + " x " + Math.abs(newHeight).toFixed(0);
    return `${alignedCrop.width} x ${alignedCrop.height}`;
  }

  getRatio() {
    let fullHeight = this.operationContext.selectedImage.height;
    let height = this.operationContext.selectedImage["canvasHeight"];
    let rect: Rect = this.operationContext.cropItem.boundingBox();

    let scale = fullHeight / height;
    let newWidth = rect.width * scale;
    let newHeight = rect.height * scale;

    return (newWidth / newHeight).toFixed(2);
  }

  async canSave(): Promise<boolean> {
    let image: ImageItem = await this.operationContext.getImage(
      this.operationContext.selectedImage.id
    );

    if (!image) {
      throw new ErrorMessage(ErrorMessage.ERROR, "Something went wrong", "");
    }

    let dateModified = new Date(image["dateModified"]);
    let dateLoaded = new Date(this.operationContext.selectedImage.dateLoaded);

    if (dateModified > dateLoaded) {
      const result = await this.modalService.confirm({
        title: "Asset modified",
        otherData: "Do you want to overwrite someone elses changes?",
      });

      return !result;
    }

    if (
      this.operationContext.selectedImage.imageTags &&
      this.operationContext.selectedImage.imageTags.filter(
        (tag) => tag.prd.id === ""
      ).length > 0
    ) {
      const result = await this.modalService.confirm({
        title: "Unselected items",
        otherData:
          "You have boxes for which no product is selected.\nThis may SEVERELY hinder users of shoppable images.\n\nSave anyway?",
      });

      return !result;
    }

    return true;
  }
}
