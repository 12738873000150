import { Component, HostListener, AfterViewInit } from "@angular/core";
import { OperationContext } from "../../services/operation.context";
import { CollectionItem } from "../../models/collection.item";

const MAX_HEIGHT = 555;
const ITEM_HEIGHT = 111;

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.css"],
})
export class DropDownComponent implements AfterViewInit {
  private $isOpen: boolean = false;
  private width: number = 400;
  private wasInside = false;
  private $collection: CollectionItem[];

  constructor(public operationContext: OperationContext) {}

  @HostListener("mousedown", ["$event"])
  clickInside($event) {
    this.wasInside = true;
  }

  @HostListener("document:mousedown")
  clickout() {
    if (!this.wasInside) {
      this.close();
    }
    this.wasInside = false;
  }

  ngAfterViewInit() {
    const selectedColl = this.operationContext.selectedCollection;
    let isBackfillBoard = false;

    if (
      selectedColl.name &&
      selectedColl.name.toUpperCase().includes("BACKFILL")
    ) {
      isBackfillBoard = true;
    }

    this.$collection = this.operationContext.collections.filter((item) => {

      if (isBackfillBoard && item.name && item.name.toUpperCase() === 'BACKFILL_RESULT') {
        item.selected = true;
        return true;
      }

      // this.$collection = [backfill_result];
      /* Always show current board in dropdown */
      if (this.operationContext.selectedCollection.id == item.id) {
        item.selected = true;
        return true;
      } else {
        item.selected = false;
        return false;
      }

    });
  }

  get collections(): CollectionItem[] {
    return this.$collection;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    if (this.collections.length === 0) {
      return ITEM_HEIGHT;
    }

    if (this.collections.length > 5) {
      return MAX_HEIGHT;
    }

    return ITEM_HEIGHT * this.collections.length + 3;
  }

  getImage(collection: CollectionItem) {
    return collection.thumbnail;
  }

  isOpen() {
    return this.$isOpen;
  }

  toggle(event) {
    this.$isOpen = !this.$isOpen;
  }

  close() {
    this.$isOpen = false;
  }

  toggleCheck(collection) {
    collection.selected = !collection.selected;
  }

  hasItems(): boolean {
    return this.collections && this.collections.length > 0;
  }

  isCurrent(collection) {
    return this.operationContext.selectedCollection.name == collection.name;
  }
}
