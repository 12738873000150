import { SelectionHandle } from "./selection.handle";
import { Rect } from "./rect";
import { ISelectionHandle } from "./handle";
import { Selection } from "./selection";
import { MemoryContext } from "../../helpers/memory.context";
import { Point } from "./point";

export class ResizeSelection extends Selection {
  private offset: number = 0;
  private then: any;
  private $selectionHandles: ISelectionHandle[];
  private scaleX: number = 0;
  private scaleY: number = 0;

  constructor(item: any) {
    super(item);
    this.then = performance.now();

    this.$selectionHandles = [];
    this.$selectionHandles.push(new SelectionHandle(0, new Point(0, 0)));
    this.$selectionHandles.push(new SelectionHandle(1, new Point(0.5, 0)));
    this.$selectionHandles.push(new SelectionHandle(2, new Point(1, 0)));
    this.$selectionHandles.push(new SelectionHandle(3, new Point(0, 0.5)));
    this.$selectionHandles.push(new SelectionHandle(4, new Point(1, 0.5)));
    this.$selectionHandles.push(new SelectionHandle(5, new Point(0, 1)));
    this.$selectionHandles.push(new SelectionHandle(6, new Point(0.5, 1)));
    this.$selectionHandles.push(new SelectionHandle(7, new Point(1, 1)));

    item.parent().focus();
  }

  public get selectionHandles(): ISelectionHandle[] {
    return this.$selectionHandles;
  }

  public renderHandle(context, handle) {
    let box = this.selectedItem.boundingBox();

    context.save();
    context.beginPath();
    context.transform(box.width, 0, 0, box.height, box.left - 5, box.top - 5);
    handle.render(context, box);
    context.restore();
  }

  renderContext(context: CanvasRenderingContext2D, point: Point, scaleX: number, scaleY: number) {
    context.save();
    context.beginPath();
    context.transform(this.currentHandle.rect.width, 0, 0, this.currentHandle.rect.height, this.currentHandle.rect.left, this.currentHandle.rect.top);
    context.translate(point.x, point.y);
    context.scale(scaleX, scaleY);
  }

  resize(memoryContext: MemoryContext, x: number, y: number) {
    let context = memoryContext.context as any;
    this.scaleX = (this.currentHandle.rect.left - x + this.currentHandle.rect.width) / this.currentHandle.rect.width;
    this.scaleY = (this.currentHandle.rect.top - y + this.currentHandle.rect.height) / this.currentHandle.rect.height;

    switch (this.currentHandle.id) {
      case 0: {
        this.renderContext(context, new Point(1, 1), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left + this.currentHandle.rect.width - w,
          this.currentHandle.rect.top + this.currentHandle.rect.height - h,
          w,
          h);
        break;
      }

      case 1: {
        this.renderContext(context, new Point(0.5, 1), 1, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top + this.currentHandle.rect.height - h,
          this.currentHandle.rect.width,
          h);
        break;
      }

      case 2: {
        this.renderContext(context, new Point(0, 1), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top + this.currentHandle.rect.height - h,
          this.currentHandle.rect.width - w,
          h);
        break;
      }

      case 3: {
        this.renderContext(context, new Point(1, 0.5), this.scaleX, 1);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left + this.currentHandle.rect.width - w,
          this.currentHandle.rect.top,
          w,
          this.currentHandle.rect.height);
        break;
      }

      case 4: {
        this.renderContext(context, new Point(0.5, 1), this.scaleX, 1);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top,
          this.currentHandle.rect.width - w,
          this.currentHandle.rect.height);
        break;
      }

      case 5: {
        this.renderContext(context, new Point(1, 0), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left + this.currentHandle.rect.width - w,
          this.currentHandle.rect.top,
          w,
          this.currentHandle.rect.height - h);
        break;
      }

      case 6: {
        this.renderContext(context, new Point(0.5, 0), 1, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top,
          this.currentHandle.rect.width,
          this.currentHandle.rect.height - h);
        break;
      }

      case 7: {
        this.renderContext(context, new Point(0, 0), this.scaleX, this.scaleY);

        let matrix: DOMMatrix = context.mozCurrentTransform || context.getTransform();
        let w = matrix[0] || matrix['a'];
        let h = matrix[3] || matrix['d'];

        context.restore();

        this.selectedItem.parent().update(
          this.currentHandle.rect.left,
          this.currentHandle.rect.top,
          this.currentHandle.rect.width - w,
          this.currentHandle.rect.height - h);
        break;
      }

    }
  }

  public renderOutline(context) {
    let now = performance.now();
    let delta = now - this.then;
    let box = this.selectedItem.boundingBox();

    context.save();
    context.beginPath();
    context.rect(box.left,
      box.top,
      box.width,
      box.height);

    context.setLineDash([8, 8]);
    context.lineDashOffset = -this.offset;
    context.strokeStyle = "rgba(255,255,255,1)";
    context.lineWidth = 2;
    context.stroke();
    context.restore();

    if (delta > 10) {
      this.offset++;

      if (this.offset >= 16) {
        this.offset = 0;
      }

      this.then = now;
    }
  }

  public render(context: any) {
    if (!this.selectedItem) {
      return;
    }

    for (let i = 0; i < this.$selectionHandles.length; i++) {
      let handle = this.$selectionHandles[i];
      this.renderHandle(context, handle);
    }

    this.renderOutline(context);
  }

  renderCursor(mouseDown: boolean) {
    if (mouseDown) {
      return '-webkit-grabbing';
    } else {
      return 'grab';
    }
  }
}