import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { OperationContext, State } from '../../services/operation.context';
import { ModalConfirmationService } from '../../services/confirmation.service';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';

const DELETE = 46;
const LEFT = 37;
const RIGHT = 39;

@Component({
  selector: 'image-slide',
  templateUrl: './image-slide.component.html',
  styleUrls: ['./image-slide.component.css']
})
export class ImageSlideComponent implements OnInit {
  @ViewChild('renderer') renderer: any;

  constructor(private operationContext: OperationContext,
    private modalService: ModalConfirmationService,
    private router: Router,
    private adal: AdalService) {
  }

  ngOnInit() {
    this.renderer.update(this.operationContext.selectedImage);
  }

  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (this.operationContext.state != State.IDLE) {
      return;
    }

    switch (event.keyCode) {
      case DELETE: {
        if (event.ctrlKey) {
          event.preventDefault();
          this.deleteItem();
        }
        break;
      }

      case LEFT: {
        event.preventDefault();
        this.previous();
        break;
      }

      case RIGHT: {
        event.preventDefault();
        this.next();
        break;
      }
    }
  }

  isDisabled() {
    return !(this.operationContext.images || this.operationContext.selectedCollection);
  }

  deleteItem() {
    if (!this.operationContext.selection) {
      return;
    }

    this.operationContext.selectedImage.deleteTag(this.operationContext.selection.selectedItem.parent());
    this.operationContext.clearSelection();
  }

  previous() {
    this.slideImages(1);
  }

  next() {
    this.slideImages(0);
  }

  async slideImages(direction) {
    // Uncheck inspiration feed
    this.operationContext.inspirationalParentCheck = false;
    this.operationContext.inspirationalCropCheck = false;

    if (this.operationContext.state != State.IDLE) {
      console.log("IDLE");
      return;
    }

    if (!this.adal.userInfo.authenticated) {
      this.router.navigate(['splash']);
      return;
    }

    if (await this.checkChanges()) {
      console.log("checkedChanges");
      return;
    }

    let enabledImageItems = this.operationContext.images
      .filter(image => {
        return this.operationContext.stateFilter[image.state];
      });

    if (
      !enabledImageItems ||
      enabledImageItems.length === 0 ||
      this.operationContext.selectedCollection == null
    ) {
      return;
    }

    let images = enabledImageItems
      .map(image => image.id);
    let index = images.indexOf(this.operationContext.selectedImage.id);

    if (direction) {
      index--;
    } else {
      index++;
    }

    if (index >= enabledImageItems.length) {
      index = 0;
    } else if (index < 0) {
      index = enabledImageItems.length - 1;
    }

    this.renderer.update(enabledImageItems[index]);
  }

  async checkChanges() {
    if (this.operationContext.isModified) {
      const result = await this.modalService.confirm({
        title: 'Unsaved changes',
        otherData: 'Do you want to continue without saving and lose all changes?'
      });

      return result;
    }

    return false;
  }
}
