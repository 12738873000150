/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toolbar.component";
var styles_ToolbarComponent = [i0.styles];
var RenderType_ToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarComponent, data: {} });
export { RenderType_ToolbarComponent as RenderType_ToolbarComponent };
export function View_ToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "toolbar-container"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "toolbar", [], null, null, null, View_ToolbarComponent_0, RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i2.ToolbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarComponentNgFactory = i1.ɵccf("toolbar", i2.ToolbarComponent, View_ToolbarComponent_Host_0, {}, {}, ["*"]);
export { ToolbarComponentNgFactory as ToolbarComponentNgFactory };
