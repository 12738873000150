<app-header></app-header>
<div *ngIf="imagesLoaded && hasImages; else noImages" class="container" style="max-width: 1600px; padding: 25px">
  <h3>Search results</h3>
  <h6>Found {{result.length}} matching images</h6>
  <div class="row">
    <div *ngFor="let image of result" class="col-md-3" style="margin-top: 25px;">
      <div class="card" (click)="!image?.isDisabled && select(image)"
        [ngClass]="image?.isDisabled ? 'card-disabled' : 'card-enabled'">
        <mat-icon *ngIf="image?.state == 3" class="status-icon"
          title="File of type {{image?.extension}} is not editable">error_outline</mat-icon>
        <mat-icon *ngIf="image?.state == 2" class="status-icon green-icon" title="Completed">brightness_1</mat-icon>
        <mat-icon *ngIf="image?.state == 1" class="status-icon yellow-icon" title="Not all tags have been identified">
          brightness_1</mat-icon>
        <mat-icon *ngIf="image?.state == 0" class="status-icon red-icon" title="No tags yet">brightness_1</mat-icon>
        <div class="card-img-top2">
          <div class="inspirationImage" [ngStyle]="{'background-image': 'url(' + getImage(image) + ')'}"></div>
        </div>
        <div class="card-body">
          <h5>
            {{image?.name}}
          </h5>
          <div class="body-text">
            <p>
              {{image?.description}}
            </p>
            <span>
              <b>[w x h]</b> {{image?.width}} x {{image?.height}} <b>[ratio]</b> {{ getRatio(image) }}:1
            </span>
            <br>
            <span>
              <b>[tags / products]</b> {{image?.productCount}} / {{image?.identfiedProductCount}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noImages>
  <div *ngIf="imagesLoaded; else searching" class="images-center">
    <h4>
      No images found
    </h4>
  </div>
</ng-template>

<ng-template #searching>
  <div *ngIf="searching" class="images-center">
    <div class="spinner">
      <mat-progress-spinner color="primary" mode="indeterminate" value="50">
      </mat-progress-spinner>
      
      <h4>
        Searching, hold on...
      </h4>
    </div>
  </div>
</ng-template>