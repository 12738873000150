import { Rect } from "./rect";

export interface ISelectionHandle {
  
}

export class Handle implements ISelectionHandle {
  private $id: number;
  private $rect: Rect;

  constructor(id: number) {
    this.$id = id;
  }

  get id() {
    return this.$id;
  }

  get rect() {
    return this.$rect;
  }

  set rect(value: Rect) {
    this.$rect = new Rect(value.left, value.top, value.width, value.height);
  }

  renderCursor(mouseDown: boolean) {}
}