var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Handle } from "./handle";
/**
 * Cursors
 *    0 = 'nw-resize';
      1 = 'n-resize';
      2 = 'ne-resize';
      3 = 'w-resize';
      4 = 'e-resize';
      5 = 'sw-resize';
      6 = 's-resize';
      7 = 'se-resize';
      8 = 'move';
      default = 'auto'

    // 0  1  2
    // 3  8  4
    // 5  6  7
 */
var SelectionHandle = /** @class */ (function (_super) {
    __extends(SelectionHandle, _super);
    function SelectionHandle(id, point) {
        var _this = _super.call(this, id) || this;
        _this.point = point;
        return _this;
    }
    SelectionHandle.prototype.renderCursor = function (mouseDown) {
        switch (this.id) {
            case 0:
                return 'nw-resize';
            case 1:
                return 'n-resize';
            case 2:
                return 'ne-resize';
            case 3:
                return 'w-resize';
            case 4:
                return 'e-resize';
            case 5:
                return 'sw-resize';
            case 6:
                return 's-resize';
            case 7:
                return 'se-resize';
            case 8:
                return 'move';
            default:
                return 'auto';
        }
    };
    SelectionHandle.prototype.resize = function (x, y) {
        //console.log('resize', this);
    };
    SelectionHandle.prototype.render = function (context, rect) {
        context.save();
        context.rect(this.point.x, this.point.y, (1 / rect.width) * 10, (1 / rect.height) * 10);
        context.fillStyle = "white";
        context.lineWidth = 1;
        context.fill();
        context.restore();
    };
    return SelectionHandle;
}(Handle));
export { SelectionHandle };
