import { ProductItem } from "../models/product.item";

export class Helpers {
  static getColor() {
    let rand = Math.random() * (255 - 20) + 20;
    return '#' + rand.toString(16).slice(-6);
  }

  static convertHex(hex, opacity) {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    let result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    return result;
  }

    /**
   * 
   * @param data 
   */
  static getProductItems(data) {
    let products: ProductItem[] = [];

    if (!data) {
      return products;
    }

    if (!data.products) {
      return products;
    }

    for (let i = 0; i < data.products.length; i++) {
      let product: ProductItem = ProductItem.create(data.products[i]);
      products.push(product);
    }

    return products;
  }
}