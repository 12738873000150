<div title="Select autocrop" (click)="toggle($event);">
  <mat-icon class="icon" >playlist_add_check</mat-icon>
  <mat-icon *ngIf="isOpen()" class="icon">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!isOpen()" class="icon">arrow_drop_down</mat-icon>
  <div *ngIf="hasItems(); else noItems" (click)="$event.stopPropagation()" [style.height.px]="getHeight()" class="dropdown-container height" [ngClass]="isOpen() ? 'open' : 'not-open'">
    <div *ngFor="let crop of autocrops; let i= index" [attr.tabindex]='i'
      [title]="crop.layoutName" class="container" (click)="select(crop)">
      <div style="padding: 10px;">
        <h6>{{ crop.layoutName }}</h6>
        <span>{{ crop.cropWidth }} x {{ crop.cropHeight }} @ {{ crop.offsetX }}, {{ crop.offsetY }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #noItems>
  <div class="dropdown-container height images-center" [ngClass]="isOpen() ? 'open' : 'not-open'" style="padding: 10px">
  <h6>No autocrops found</h6>
  </div>
</ng-template>

