<div (click)="toggle($event)">
  <form #formControl autocomplete="off">
    <mat-form-field class="full-width spinner-layout">
      <input
        class="full-width"
        matInput
        type="text"
        #input
        [value]="tagItem.prd"
        [formControl]="inputController"
        spellcheck="false"
      />
      <mat-progress-spinner
        *ngIf="operationContext.isAIEnabled && isLoading"
        diameter="20"
        color="primary"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
    </mat-form-field>

    <div
      *ngIf="hasItems()"
      class="dropdown-container height"
      [ngClass]="isOpen ? 'open' : 'not-open'"
      [style.margin-top.px]="getMarginTop()"
      [style.width.px]="getWidth()"
      [style.height.px]="getHeight()"
    >
      <div>
        <div class="header" *ngIf="suggestions.length > 0">
          <h6>{{ title }}</h6>
        </div>
        <div
          *ngFor="let option of suggestions; let i = index"
          [attr.tabindex]="i"
          (mousedown)="select(option)"
          [title]="option.description"
          [ngClass]="{ container: true, selected: isSelected(option) }"
        >
          <div
            class="inspirationImage"
            [ngStyle]="{ 'background-image': 'url(' + getImage(option) + ')' }"
          ></div>
          <div class="inner-content">
            <div>
              <p class="heading-info">
                {{ option.name }}
                <mat-icon *ngIf="option.type == 'main'">starts</mat-icon>
              </p>
              <p class="info">{{ option.id }}</p>
              <p class="info">
                {{
                  option.descriptionEN
                    ? option.descriptionEN
                    : option.description
                }}
              </p>
            </div>
            <div class="score" *ngIf="option.score > 0">
              {{ getScore(option.score) }}
            </div>
          </div>
        </div>
        <div class="header" *ngIf="tagItem.identifiedProducts.length > 0">
          <h6>Louise2 suggestions</h6>
        </div>
        <div
          *ngFor="let option of tagItem.identifiedProducts; let i = index"
          [attr.tabindex]="i"
          (mousedown)="select(option)"
          [title]="option.description"
          [ngClass]="{ container: true, selected: isSelected(option) }"
        >
          <div
            class="inspirationImage"
            [ngStyle]="{ 'background-image': 'url(' + getImage(option) + ')' }"
          ></div>
          <div class="inner-content">
            <div>
              <p class="heading-info">
                {{ option.name }}
                <mat-icon *ngIf="option.type == 'main'">starts</mat-icon>
              </p>
              <p class="info">{{ option.id }}</p>
              <p class="info">
                {{
                  option.descriptionEN
                    ? option.descriptionEN
                    : option.description
                }}
              </p>
            </div>
            <div class="score" *ngIf="option.score > 0">
              {{ getScore(option.score) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
