import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AdalService } from "adal-angular4";
import { OperationContext } from "../../services/operation.context";
import { environment } from "../../../environments/environment";
import { ModalConfirmationService } from "../../services/confirmation.service";
import { UploadsInfoModalComponent } from "../../components/uploads-info-modal/uploads-info-modal.component";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.css"],
})
export class AppHeaderComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: any;
  public username: String;
  public env: any = environment;

  constructor(
    private router: Router,
    private adalSvc: AdalService,
    public operationContext: OperationContext,
    public data: DataService,
    private modal: ModalConfirmationService
  ) {}

  ngOnInit() {
    this.username = this.adalSvc.userInfo.profile.name;
  }

  logout() {
    this.adalSvc.logOut();
  }

  async uploadFile(ev) {
    const file = ev.target.files[0];

    if (file === undefined) {
      return;
    }

    if (!file) {
      return;
    }

    this.operationContext
      .uploadFile(file)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  initUpload() {
    this.fileUpload.nativeElement.click();
  }

  isDisabled() {
    return sessionStorage.getItem("collection") == null;
  }

  hasImage() {
    return this.operationContext.selectedImage != null;
  }

  isSearchDisabled() {
    return !sessionStorage.getItem("query");
  }

  todoCount() {
    if (!this.operationContext.images) {
      return 0;
    }

    let itemsCount = this.operationContext.images.reduce(
      (accumulator, imageItem) => {
        if (imageItem.state == 0 || imageItem.state == 1) {
          return accumulator + 1;
        }
        return accumulator;
      },
      0
    );

    return itemsCount;
  }

  toggleCrop() {
    if (this.operationContext.selectedImage === undefined) {
      return;
    }

    this.operationContext.isCropEnabled = false;
  }

  toggleAI() {
    this.operationContext.isAIEnabled = !this.operationContext.isAIEnabled;
  }

  nextCropRatio(): string {
    const [newRatio] = environment.cropRatios.filter(
      (cropRatio) => !this.operationContext.cropRatio
    );
    return newRatio;
  }

  toggleCropRatio() {
    const newRatio = this.nextCropRatio();
    this.operationContext.cropRatio = newRatio;
  }

  infoClick(): void {
    console.log("hello");
    this.modal.confirm(
      {
        title: "Jobs status",
        otherData:
          "Do you want to continue without saving and lose all changes?",
        fetchData: async () => {
          const d = await this.data.getAllTickets();
          console.log(d);
          return d;
        },
      },
      UploadsInfoModalComponent
    );
  }
}
