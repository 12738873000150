var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Handle } from "./handle";
/**
 * Cursors
    // 0     1
    //
    // 2     3
 */
var CropSelectionHandle = /** @class */ (function (_super) {
    __extends(CropSelectionHandle, _super);
    function CropSelectionHandle(id, point) {
        var _this = _super.call(this, id) || this;
        _this.point = point;
        return _this;
    }
    CropSelectionHandle.prototype.renderCursor = function (mouseDown) {
        switch (this.id) {
            case 0:
                return 'nw-resize';
            case 1:
                return 'ne-resize';
            case 2:
                return 'sw-resize';
            case 3:
                return 'se-resize';
        }
    };
    CropSelectionHandle.prototype.renderHandle = function (context, x, y, rect) {
        context.fillStyle = "black";
        context.lineWidth = 1;
        context.globalCompositeOperation = 'difference';
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.WIDTH);
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
        context.rect(this.point.x, this.point.y, -x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.WIDTH);
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, -y * (1 / rect.height) * CropSelectionHandle.LENGTH);
        context.fillStyle = "white";
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.WIDTH);
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
        context.rect(this.point.x, this.point.y, -x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.WIDTH);
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.WIDTH, -y * (1 / rect.height) * CropSelectionHandle.LENGTH);
        context.fill();
        context.globalCompositeOperation = 'source-over';
        context.rect(this.point.x, this.point.y, x * (1 / rect.width) * CropSelectionHandle.LENGTH, y * (1 / rect.height) * CropSelectionHandle.LENGTH);
    };
    CropSelectionHandle.prototype.render = function (context, rect) {
        context.save();
        switch (this.id) {
            case 0: {
                this.renderHandle(context, 1, 1, rect);
                break;
            }
            case 1: {
                this.renderHandle(context, -1, 1, rect);
                break;
            }
            case 2: {
                this.renderHandle(context, 1, -1, rect);
                break;
            }
            case 3: {
                this.renderHandle(context, -1, -1, rect);
                break;
            }
        }
        context.restore();
    };
    CropSelectionHandle.LENGTH = 10;
    CropSelectionHandle.WIDTH = 1;
    return CropSelectionHandle;
}(Handle));
export { CropSelectionHandle };
