var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdalService } from "adal-angular4";
import { OperationContext } from "../../services/operation.context";
import { environment } from "../../../environments/environment";
import { ModalConfirmationService } from "../../services/confirmation.service";
import { UploadsInfoModalComponent } from "../../components/uploads-info-modal/uploads-info-modal.component";
import { DataService } from "../../services/data.service";
var AppHeaderComponent = /** @class */ (function () {
    function AppHeaderComponent(router, adalSvc, operationContext, data, modal) {
        this.router = router;
        this.adalSvc = adalSvc;
        this.operationContext = operationContext;
        this.data = data;
        this.modal = modal;
        this.env = environment;
    }
    AppHeaderComponent.prototype.ngOnInit = function () {
        this.username = this.adalSvc.userInfo.profile.name;
    };
    AppHeaderComponent.prototype.logout = function () {
        this.adalSvc.logOut();
    };
    AppHeaderComponent.prototype.uploadFile = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var file;
            return __generator(this, function (_a) {
                file = ev.target.files[0];
                if (file === undefined) {
                    return [2 /*return*/];
                }
                if (!file) {
                    return [2 /*return*/];
                }
                this.operationContext
                    .uploadFile(file)
                    .then(function (result) {
                    console.log(result);
                })
                    .catch(function (error) {
                    console.log(error);
                });
                return [2 /*return*/];
            });
        });
    };
    AppHeaderComponent.prototype.initUpload = function () {
        this.fileUpload.nativeElement.click();
    };
    AppHeaderComponent.prototype.isDisabled = function () {
        return sessionStorage.getItem("collection") == null;
    };
    AppHeaderComponent.prototype.hasImage = function () {
        return this.operationContext.selectedImage != null;
    };
    AppHeaderComponent.prototype.isSearchDisabled = function () {
        return !sessionStorage.getItem("query");
    };
    AppHeaderComponent.prototype.todoCount = function () {
        if (!this.operationContext.images) {
            return 0;
        }
        var itemsCount = this.operationContext.images.reduce(function (accumulator, imageItem) {
            if (imageItem.state == 0 || imageItem.state == 1) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);
        return itemsCount;
    };
    AppHeaderComponent.prototype.toggleCrop = function () {
        if (this.operationContext.selectedImage === undefined) {
            return;
        }
        this.operationContext.isCropEnabled = false;
    };
    AppHeaderComponent.prototype.toggleAI = function () {
        this.operationContext.isAIEnabled = !this.operationContext.isAIEnabled;
    };
    AppHeaderComponent.prototype.nextCropRatio = function () {
        var _this = this;
        var newRatio = environment.cropRatios.filter(function (cropRatio) { return !_this.operationContext.cropRatio; })[0];
        return newRatio;
    };
    AppHeaderComponent.prototype.toggleCropRatio = function () {
        var newRatio = this.nextCropRatio();
        this.operationContext.cropRatio = newRatio;
    };
    AppHeaderComponent.prototype.infoClick = function () {
        var _this = this;
        console.log("hello");
        this.modal.confirm({
            title: "Jobs status",
            otherData: "Do you want to continue without saving and lose all changes?",
            fetchData: function () { return __awaiter(_this, void 0, void 0, function () {
                var d;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.data.getAllTickets()];
                        case 1:
                            d = _a.sent();
                            console.log(d);
                            return [2 /*return*/, d];
                    }
                });
            }); },
        }, UploadsInfoModalComponent);
    };
    return AppHeaderComponent;
}());
export { AppHeaderComponent };
