export class Histogram {
  static windowSize = 5;
  private pixels = 0;
  private rgb: any = { r: 0, g: 0, b: 0 };
  private width;
  private height;
  private imageData;
  private histogram = {
    beige: 0,
    brown: 0,
    yellow: 0,
    red: 0,
    gray: 0,
    orange: 0,
    black: 0,
    blue: 0,
    green: 0,
    pink: 0,
    turquoise: 0,
    lilac: 0,
    white: 0
  };

  constructor(context) {
    try {
      this.imageData = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
      this.width = context.canvas.width;
      this.height = context.canvas.height;
    } catch (e) {

    }
  }

  calculateTable() {
    let colorTable = [];

    let colors = Object.keys(this.officialColors);
    for (let i = 0; i < colors.length; i++) {
    
      let colorName = colors[i];

      colorTable.push({
        colorName: colorName,
        rgb : this.hex2Rgb(this.officialColors[colors[i]])
      })
    }

    return colorTable;
  }

  create() {
    let rgb: any = { r: 0, g: 0, b: 0 };

    for (let y = 0; y < this.height; y += Histogram.windowSize) {
      for (let x = 0; x < this.width; x += Histogram.windowSize) {
        let data = this.imageData.data;
        let length = data.length;
        let x1 = Math.min(x, length - x);
        let y1 = Math.min(y, length - y);
        let offset = ((this.width * y1) + x1) * 4;

        let r = data[offset];
        let g = data[offset + 1];
        let b = data[offset + 2];

        this.rgb.r += r;
        this.rgb.g += g;
        this.rgb.b += b;

        let result = this.findClosestHSL(r, g, b);
        this.histogram[result.name]++;
        this.pixels++;
      }
    }

    rgb.r = Math.floor(this.rgb.r / this.pixels);
    rgb.g = Math.floor(this.rgb.g / this.pixels);
    rgb.b = Math.floor(this.rgb.b / this.pixels);

    let hsl = this.rgbToHsl([rgb.r, rgb.g, rgb.b]);
    let histogramColors = Object.keys(this.histogram);

    let result = {
      colors: histogramColors.map((item) => {
        return {
          name: item,
          score: this.histogram[item],
          percentage: ((this.histogram[item] / this.pixels) * 100).toFixed(2),
          hex: this.officialColors[item]
        }
      }).filter((item) => {
        if (parseFloat(item.percentage) > 0.5) {
          return true;
        }

        return false;
      }).sort((a, b) => { return b.score - a.score }),
      brightness: {
        brightness: hsl[2].toFixed(2),
        name: this.getBrightnessName(hsl[2]),
      }
    };

    return result;
  }

  /**
   * 
   * @param r 
   * @param g 
   * @param b 
   */
  findClosestHSL(r, g, b) {
    let hsl = this.rgbToHsl([r, g, b]);
    let colorName = 'black';
    let hue = hsl[0] * 360; // 0-360
    let saturation = hsl[1]; // 0-1
    let lumination = hsl[2]; // 0-1

    if ((hue < 10 || hue >= 330)) {
      if (lumination < 0.10) {
        colorName = 'black';
      } else if (lumination > 0.90) {
        colorName = 'white';
      } else if (saturation < 0.15) {
        colorName = 'gray';
      } else if (lumination < 0.5) {
        colorName = 'red';
      } else {
        colorName = 'pink';
      }
    } else if (hue < 38) {
      if (lumination < 0.15) {
        colorName = 'black';
      } else if (lumination > 0.85) {
        colorName = 'white';
      } else if (saturation < 0.15) {
        colorName = 'gray';
      } else if (saturation < 0.7) {
        colorName = 'brown';
      } else {
        colorName = 'orange';
      }
    } else if (hue < 66) {
      if (lumination < 0.15) {
        colorName = 'black';
      } else if (lumination > 0.85) {
        colorName = 'white';
      } else if (saturation < 0.15) {
        colorName = 'gray';
      } else if (lumination > 0.65) {
        colorName = 'beige';
      } else {
        colorName = 'yellow';
      }
    } else if (hue < 150) {
      if (lumination > 0.9) {
        colorName = 'white';
      } else if (lumination < 0.1) {
        colorName = 'black';
      } else if (saturation < 0.1) {
        colorName = 'gray';
      } else {
        colorName = 'green';
      }
    } else if (hue < 196) {
      if (lumination > 0.85) {
        colorName = 'white';
      } else if (lumination < 0.1) {
        colorName = 'black';
      } else if (saturation < 0.05) {
        colorName = 'gray';
      } else {
        colorName = 'turquoise';
      }
    } else if (hue < 260) {
      if (lumination > 0.75) {
        colorName = 'white';
      } else if (lumination < 0.15) {
        colorName = 'black';
      } else if (saturation < 0.1) {
        colorName = 'gray';
      } else {
        colorName = 'blue';
      }
    } else if (hue < 330) {
      if (lumination > 0.95) {
        colorName = 'white';
      } else if (lumination < 0.15) {
        colorName = 'black';
      } else if (saturation < 0.1) {
        colorName = 'gray';
      } else {
        colorName = 'lilac';
      }
    }

    return {
      name: colorName,
      color: this.hex2Rgb(this.officialColors[colorName])
    };
  }

  findClosestColorRGB(r, g, b, rgbTable) {
    let colorName = "black";
    let minDist = Number.MAX_VALUE;

    for (let i = 0; i < rgbTable.length; i++) {
      let rgbRef = rgbTable[i];
      let dist = Math.pow(rgbRef.rgb.r - r, 2) + Math.pow(rgbRef.rgb.g - g, 2) + Math.pow(rgbRef.rgb.b - b, 2);

      if (dist < minDist) {
        minDist = dist;
        colorName = rgbRef.colorName;
      }
    }

    return {
      name: colorName,
      color: this.hex2Rgb(this.officialColors[colorName])
    };
  }

  getBrightnessName(brightness) {
    var temp = 0;
    var nameFound = 'Light';

    for (var key in this.brightnessTable) {
      temp = this.brightnessTable[key];

      if (brightness > temp) {
        return nameFound;
      }

      nameFound = key;
    }

    return nameFound;
  }

  rgbToHsl(rgb: number[]) {
    let r = rgb[0] / 255;
    let g = rgb[1] / 255;
    let b = rgb[2] / 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2.0;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return [h, s, l];
  }

  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1.0 / 3.0);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1.0 / 3.0);
    }

    return [
      Math.min(Math.floor(r * 256), 255),
      Math.min(Math.floor(g * 256), 255),
      Math.min(Math.floor(b * 256), 255)
    ]
  }

  toHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.toHex(r) + this.toHex(g) + this.toHex(b);
  }

  hex2Rgb(hex) {
    if (hex.lastIndexOf('#') > -1) {
      hex = hex.replace(/#/, '0x');
    } else {
      hex = '0x' + hex;
    }

    let r = hex >> 16;
    let g = (hex & 0x00FF00) >> 8;
    let b = hex & 0x0000FF;

    return { r: r, g: g, b: b };
  }

  brightnessTable = {
    "Light": 1,
    "Medium": 0.67,
    "Dark": 0.33
  }

  officialColors = {
    "beige": "#F5F5DC",
    "brown": "#8B4513",
    "yellow": "#FFFF00",
    "red": "#FF0000",
    "gray": "#808080",
    "orange": "#FFA500",
    "black": "#000000",
    "blue": "#0000FF",
    "green": "#008000",
    "pink": "#FFC0CB",
    "turquoise": "#40E0D0",
    "lilac": "#C8A2C8",
    "white": "#FFFFFF"
  }
}
