import { Component} from '@angular/core';
import { OperationContext } from '../../services/operation.context';

@Component({
  selector: 'progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.css']
})
export class ProgressbarComponent {
  constructor(public operationContext: OperationContext) { }
}