import { Rect } from "./rect";
import { TagItem } from "./tag.item";
import { Helpers } from "../../helpers/common";

const LINE_WIDTH: number = 3;

export class Box {
  private rect: Rect;
  private color: String
  private parentItem: TagItem;

  constructor(rect: Rect, color: String, parent: TagItem) {
    this.rect = rect;
    this.color = color;
    this.parentItem = parent;
  }

  public render(context: any) {
    context.save();
    context.beginPath();
    context.rect(this.rect.left, this.rect.top, this.rect.width, this.rect.height);
    context.clip();
    context.strokeStyle = Helpers.convertHex(this.color, 1);
    context.lineWidth = LINE_WIDTH;
    context.stroke();
    context.fillStyle = Helpers.convertHex(this.color, 0.2);
    context.fill();
    context.restore();
  }

  public parent(): TagItem {
    return this.parentItem;
  }

  public canResize() {
    return true;
  }

  public getColor() {
    return this.color;
  }

  public boundingBox(): Rect {
    return this.rect;
  }

  public updateDimension(left: number, top: number, width: number, height: number) {
    this.rect.left = left;
    this.rect.top = top;
    this.rect.width = Math.abs(width);
    this.rect.height = Math.abs(height);

    if (width < 0 ) {
      this.rect.left = left + width;
    } 
    
    if (height < 0 ) {
      this.rect.top = top + height;
    } 

    this.parentItem.isModified = true;
  }

  public move(x: number, y: number) {
    this.rect.left -= x;
    this.rect.top -= y;

    this.parentItem.isModified = true;
  }

  public toJSON() {
    return {
      l: this.rect.left,
      t: this.rect.top,
      w: this.rect.width,
      h: this.rect.height
    }
  }

  renderCursor(mouseDown: boolean) {
    return 'auto';
  }
}