import { Rect } from "./rect";
var Handle = /** @class */ (function () {
    function Handle(id) {
        this.$id = id;
    }
    Object.defineProperty(Handle.prototype, "id", {
        get: function () {
            return this.$id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Handle.prototype, "rect", {
        get: function () {
            return this.$rect;
        },
        set: function (value) {
            this.$rect = new Rect(value.left, value.top, value.width, value.height);
        },
        enumerable: true,
        configurable: true
    });
    Handle.prototype.renderCursor = function (mouseDown) { };
    return Handle;
}());
export { Handle };
