var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Selection } from "./selection";
var LINE_WIDTH = 2;
var DefaultSelection = /** @class */ (function (_super) {
    __extends(DefaultSelection, _super);
    function DefaultSelection(item) {
        var _this = _super.call(this, item) || this;
        _this.offset = 0;
        _this.then = performance.now();
        item.parent().focus();
        return _this;
    }
    DefaultSelection.prototype.render = function (context) {
        if (!this.selectedItem) {
            return;
        }
        var now = performance.now();
        var delta = now - this.then;
        context.save();
        context.beginPath();
        var bb = this.selectedItem.boundingBox(context);
        context.rect(bb.left, bb.top, bb.width, bb.height);
        //context.clip();
        context.setLineDash([8, 8]);
        context.lineDashOffset = -this.offset;
        context.strokeStyle = "rgba(255,255,255,1)";
        context.lineWidth = LINE_WIDTH;
        context.stroke();
        context.closePath();
        context.restore();
        if (delta > 10) {
            this.offset++;
            if (this.offset >= 16) {
                this.offset = 0;
            }
            this.then = now;
        }
    };
    DefaultSelection.prototype.renderCursor = function (mouseDown) {
        return "auto";
    };
    return DefaultSelection;
}(Selection));
export { DefaultSelection };
