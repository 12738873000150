import { Component, HostListener, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { OperationContext } from '../../services/operation.context';


const MAX_HEIGHT = 400;
const ITEM_HEIGHT = 80;


@Component({
  selector: 'cropdropdown',
  templateUrl: './cropdropdown.component.html',
  styleUrls: ['./cropdropdown.component.css']
})
export class CropDropDownComponent implements AfterViewInit {
  @Output() layoutNameChange = new EventEmitter<String>();

  private $isOpen: boolean = false;
  private width: number = 400;
  private wasInside = false;
  private name;
  private autocrops = [];
  private loading = true;

  constructor(public operationContext: OperationContext) { }

  @HostListener('mousedown', ['$event'])
  clickInside($event) {
    this.wasInside = true;
  }

  @HostListener('document:mousedown')
  clickout() {
    if (!this.wasInside) {
      this.close();
    }
    this.wasInside = false;
  }

  ngAfterViewInit() {
    this.loading = true;
    this.name = this.operationContext.selectedImage.name;
    this.operationContext.getAutocrops(this.name).then( (result) => {
      this.autocrops = result;
      this.loading = false;
    });
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    if (this.loading || !this.autocrops || !this.autocrops.length || this.autocrops.length === 0) {
      return ITEM_HEIGHT;
    }

    if (this.autocrops.length > 5) {
      return MAX_HEIGHT;
    }

    return (ITEM_HEIGHT * this.autocrops.length + 3);
  }

  isOpen() {
    return this.$isOpen;
  }

  toggle(event) {
    this.$isOpen = !this.$isOpen;
  }

  close() {
    this.$isOpen = false;
  }

  select(crop) {
    console.log("Chose", crop);

    let height = this.operationContext.selectedImage["canvasHeight"];
    let width = this.operationContext.selectedImage["canvasWidth"];

    let xfactor = width / crop.originalWidth;
    let yfactor = height / crop.originalHeight; 

    this.operationContext.cropRatio = "free";
    this.operationContext.cropItem.update(crop.offsetX * xfactor, crop.offsetY * xfactor, crop.cropWidth * yfactor, crop.cropHeight * yfactor);

    this.operationContext.layoutName = crop.layoutName;
    this.layoutNameChange.emit(crop.layoutName);
  }

  hasItems(): boolean {
    return this.autocrops && this.autocrops.length > 0;
  }
}