import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { mergeMap } from "rxjs/operators";
import { AdalService } from "adal-angular4";
import { Router } from "@angular/router";
import "rxjs/add/operator/do";
import { empty } from "rxjs/observable/empty";
import { throwError } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public adal: AdalService, public router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var resource = this.adal.getResourceForEndpoint(request.url);

    if (!resource) {
      return next.handle(request);
    }
    // if the user is not authenticated then drop the request
    /*if (!this.adal.userInfo.authenticated) {
      this.router.navigate(['splash']);
      return empty();
    }*/

    return this.adal.acquireToken(resource).pipe(
      mergeMap((token) => {
        // clone the request and replace the original headers with
        // cloned headers, updated with the authorization
        var authorizedRequest = request.clone({
          headers: request.headers.set("Authorization", "Bearer " + token),
        });
        return next.handle(authorizedRequest).do(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // do stuff with response if you want
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.router.navigate(["splash"]);
                return empty();
              }
            }

            throwError(err);
          }
        );
      })
    );
  }
}
