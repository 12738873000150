import { BsModalService } from "ngx-bootstrap/modal";
import { Injectable } from "@angular/core";
import { InfoModalComponent } from "../components/info-modal/info-modal.component";

@Injectable()
export class ModalConfirmationService {
  constructor(private modalService: BsModalService) {}

  confirm(initialState, m = InfoModalComponent): Promise<boolean> {
    const modalOptions = {
      initialState: initialState,
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    };

    if (this.modalService.getModalsCount() > 0) {
      return new Promise<boolean>((resolve, reject) => {
        resolve(true);
      });
    }

    const modal = this.modalService.show(m, modalOptions);

    return new Promise<boolean>((resolve, reject) => {
      modal.content.result.subscribe((result) => resolve(result));
    });
  }
}
