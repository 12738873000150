var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CropSelectionHandle } from "./crop.handle";
import { Selection } from "./selection";
import { MemoryContext } from "../../helpers/memory.context";
import { Point } from "./point";
import Fraction from "fraction.js";
var CropSelection = /** @class */ (function (_super) {
    __extends(CropSelection, _super);
    function CropSelection(item, operationContext) {
        var _this = _super.call(this, item) || this;
        _this.scaleX = 0;
        _this.scaleY = 0;
        _this.operationContext = operationContext;
        _this.$selectionHandles = [];
        _this.$selectionHandles.push(new CropSelectionHandle(0, new Point(0, 0)));
        _this.$selectionHandles.push(new CropSelectionHandle(1, new Point(1, 0)));
        _this.$selectionHandles.push(new CropSelectionHandle(2, new Point(0, 1)));
        _this.$selectionHandles.push(new CropSelectionHandle(3, new Point(1, 1)));
        _this.cropContext = new MemoryContext(item.width, item.height);
        return _this;
    }
    Object.defineProperty(CropSelection.prototype, "selectionHandles", {
        get: function () {
            return this.$selectionHandles;
        },
        enumerable: true,
        configurable: true
    });
    CropSelection.prototype.renderContext = function (context, point, scaleX, scaleY) {
        context.save();
        context.beginPath();
        if (this.operationContext.cropRatio !== "free") {
            context.transform(this.currentHandle.rect.height *
                new Fraction(this.operationContext.cropRatio).valueOf(), 0, 0, this.currentHandle.rect.height, this.currentHandle.rect.left, this.currentHandle.rect.top);
        }
        else {
            context.transform(this.currentHandle.rect.width, 0, 0, this.currentHandle.rect.height, this.currentHandle.rect.left, this.currentHandle.rect.top);
        }
        context.translate(point.x, point.y);
        context.scale(scaleX, scaleY);
    };
    CropSelection.prototype.resize = function (memoryContext, x, y) {
        var context = memoryContext.context;
        this.scaleX = (this.currentHandle.rect.left - x + this.currentHandle.rect.width) / this.currentHandle.rect.width;
        this.scaleY = (this.currentHandle.rect.top - y + this.currentHandle.rect.height) / this.currentHandle.rect.height;
        switch (this.currentHandle.id) {
            case 0: { // Upper left corner
                this.renderContext(context, new Point(1, 1), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = matrix[3] || matrix['d'];
                var w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : matrix[0] || matrix['a'];
                context.restore();
                if (this.currentHandle.rect.left + this.currentHandle.rect.width - w < 0) {
                    break;
                }
                this.selectedItem.update(this.currentHandle.rect.left + this.currentHandle.rect.width - w, this.currentHandle.rect.top + this.currentHandle.rect.height - h, w, h);
                break;
            }
            case 1: { // Upper right corner
                this.renderContext(context, new Point(0, 1), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = matrix[3] || matrix['d'];
                var w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : this.currentHandle.rect.width - (matrix[0] || matrix['a']);
                context.restore();
                if (this.currentHandle.rect.left + w > this.cropContext.canvas.width) {
                    break;
                }
                this.selectedItem.update(this.currentHandle.rect.left, this.currentHandle.rect.top + this.currentHandle.rect.height - h, w, h);
                break;
            }
            case 2: { // Lower left corner
                this.renderContext(context, new Point(1, 0), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = this.currentHandle.rect.height - (matrix[3] || matrix['d']);
                var w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : matrix[0] || matrix['a'];
                context.restore();
                if (this.currentHandle.rect.left + this.currentHandle.rect.width - w < 0) {
                    break;
                }
                this.selectedItem.update(this.currentHandle.rect.left + this.currentHandle.rect.width - w, this.currentHandle.rect.top, w, h);
                break;
            }
            case 3: { // Lower right corner
                this.renderContext(context, new Point(0, 0), this.scaleX, this.scaleY);
                var matrix = context.mozCurrentTransform || context.getTransform();
                var h = this.currentHandle.rect.height - (matrix[3] || matrix['d']);
                var w = this.operationContext.cropRatio !== "free" ? new Fraction(this.operationContext.cropRatio).valueOf() * h : this.currentHandle.rect.width - (matrix[0] || matrix['a']);
                context.restore();
                if (this.currentHandle.rect.left + w > this.cropContext.canvas.width) {
                    break;
                }
                this.selectedItem.update(this.currentHandle.rect.left, this.currentHandle.rect.top, w, h);
                break;
            }
        }
    };
    CropSelection.prototype.renderHandle = function (context, handle) {
        var box = this.selectedItem.boundingBox();
        context.save();
        context.beginPath();
        context.transform(box.width, 0, 0, box.height, box.left, box.top);
        handle.render(context, box);
        context.restore();
    };
    CropSelection.prototype.renderCenter = function (context) {
        var box = this.selectedItem.boundingBox();
        context.save();
        context.beginPath();
        context.transform(box.width, 0, 0, box.height, box.left, box.top);
        context.save();
        context.beginPath();
        context.lineWidth = Math.abs(1 / box.width);
        context.moveTo(0.5, 0.5);
        context.lineTo(0.5, 0.5 + (1 / box.height) * CropSelectionHandle.LENGTH);
        context.strokeStyle = 'white';
        context.stroke();
        context.beginPath();
        context.lineWidth = Math.abs(1 / box.width);
        context.moveTo(0.5, 0.5);
        context.lineTo(0.5, 0.5 - (1 / box.height) * CropSelectionHandle.LENGTH);
        context.strokeStyle = 'white';
        context.stroke();
        context.beginPath();
        context.lineWidth = Math.abs(1 / box.height);
        context.moveTo(0.5, 0.5);
        context.lineTo(0.5 + (1 / box.width) * CropSelectionHandle.LENGTH, 0.5);
        context.strokeStyle = 'white';
        context.stroke();
        context.beginPath();
        context.lineWidth = Math.abs(1 / box.height);
        context.moveTo(0.5, 0.5);
        context.lineTo(0.5 - (1 / box.width) * CropSelectionHandle.LENGTH, 0.5);
        context.strokeStyle = 'white';
        context.stroke();
        context.restore();
        context.restore();
    };
    CropSelection.prototype.renderDimming = function (context) {
        var ctx = this.cropContext.context;
        ctx.save();
        ctx.beginPath();
        ctx.clearRect(0, 0, context.canvas.width, context.canvas.height);
        ctx.fillStyle = 'rgba(255,255,255,0.9)';
        ctx.fillRect(0, 0, context.canvas.width, context.canvas.height);
        ctx.clearRect(this.selectedItem.rect.left, this.selectedItem.rect.top, this.selectedItem.rect.width, this.selectedItem.rect.height);
        ctx.restore();
        context.drawImage(this.cropContext.canvas, 0, 0, this.selectedItem.width, this.selectedItem.height);
    };
    CropSelection.prototype.renderSafeZone = function (context) {
        var ctx = this.cropContext.context;
        var safeMarginX = this.selectedItem.rect.width / (375 / 40);
        var safeMarginTop = this.selectedItem.rect.height / (667 / 50);
        var safeMarginBottom = this.selectedItem.rect.height / (667 / 70);
        var safeMarginSquareX = this.selectedItem.rect.width / (375 / 120);
        var safeMarginSquareY = this.selectedItem.rect.height / (667 / 120);
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = 'white';
        this.renderRoundedRect(ctx, this.selectedItem.rect.left + safeMarginX, this.selectedItem.rect.top + safeMarginTop, this.selectedItem.rect.width - safeMarginX * 2, this.selectedItem.rect.height - safeMarginBottom - safeMarginTop, 1);
        ctx.fill();
        ctx.clearRect(this.selectedItem.rect.left, this.selectedItem.rect.top, safeMarginSquareX, safeMarginSquareY);
        ctx.globalCompositeOperation = 'xor';
        ctx.fillStyle = 'rgba(0,0,0,0.4)';
        ctx.fillRect(this.selectedItem.rect.left, this.selectedItem.rect.top, this.selectedItem.rect.width, this.selectedItem.rect.height);
        ctx.restore();
        context.globalCompositeOperation = 'multiply';
        context.drawImage(this.cropContext.canvas, 0, 0, this.selectedItem.width, this.selectedItem.height);
        context.globalCompositeOperation = 'source-over';
    };
    CropSelection.prototype.render = function (context) {
        if (!this.selectedItem) {
            return;
        }
        this.renderDimming(context);
        this.renderSafeZone(context);
        for (var i = 0; i < this.$selectionHandles.length; i++) {
            var handle = this.$selectionHandles[i];
            this.renderHandle(context, handle);
        }
        this.renderCenter(context);
    };
    CropSelection.prototype.renderRoundedRect = function (context, x, y, w, h, r) {
        if (w < 2 * r && r < 13)
            r = Math.abs(w / 2);
        if (h < 2 * r && r < 13)
            r = Math.abs(h / 2);
        context.beginPath();
        context.moveTo(x + r, y);
        context.arcTo(x + w, y, x + w, y + h, r);
        context.arcTo(x + w, y + h, x, y + h, r);
        context.arcTo(x, y + h, x, y, r);
        context.arcTo(x, y, x + w, y, r);
        context.closePath();
    };
    return CropSelection;
}(Selection));
export { CropSelection };
