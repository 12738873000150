import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInAnimation } from '../../animations/fadeIn.animation';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})

export class SplashComponent {
  constructor(private router: Router, private adalSvc: AdalService) { }

  login() {
    this.adalSvc.login();
  }
}
