import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  SecurityContext,
  ElementRef,
  HostListener,
} from "@angular/core";
import { OperationContext } from "../../services/operation.context";
import { TagItem } from "../../models/renderable/tag.item";
import { ProductItem } from "../../models/product.item";
import { FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { debounceTime } from "rxjs/operators";
import { DataService } from "../../services/data.service";

const ITEM_HEIGHT = 111;
const MAX_HEIGHT = ITEM_HEIGHT * 3;
const HEADER_HEIGHT = 30;
const PADDING = 5;

const KEY_SPACE = 32;
const KEY_UP = 38;
const KEY_DOWN = 40;

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit, AfterViewInit {
  @Input() tagItem: TagItem;
  @Input() options: ProductItem[];
  @ViewChild("input") input: any;
  @ViewChild("formControl") formControl: ElementRef;

  inputController: FormControl;
  suggestions: ProductItem[] = [];
  init: boolean = false;
  isOpen: boolean = false;
  width: number = 0;
  wasInside = false;
  title: string = "Suggested products";
  isLoading: boolean = false;

  constructor(
    public operationContext: OperationContext,
    private sanitizer: DomSanitizer,
    private dataService: DataService
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resize();
  }

  @HostListener("mousedown", ["$event"])
  clickInside($event) {
    this.wasInside = true;
  }

  @HostListener("document:mousedown")
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
    }

    this.wasInside = false;
  }

  @HostListener("document:keydown", ["$event"]) private handleKeydown(
    event: KeyboardEvent
  ) {
    if (!this.isOpen) {
      return;
    }

    switch (event.keyCode) {
      case KEY_SPACE: {
        break;
      }

      case KEY_UP: {
        break;
      }

      case KEY_DOWN: {
        break;
      }
    }
  }

  async ngOnInit() {
    this.inputController = new FormControl(this.tagItem.prd);
    this.isLoading = true;

    this.inputController.setValue(this.tagItem.prd);
    this.suggestions = this.clone(this.options);

    this.inputController.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data) => {
        if (!data) {
          this.tagItem.prd = null;
        }

        this.getProducts(data);
      });

    if (this.operationContext.isAIEnabled) {
      await sleep(1000);
      while (this.operationContext.selectedImage.old) {
        console.log("Awaiting new image loading");
        await sleep(500);
      }
      this.identifyTag(
        this.operationContext.selectedImage.thumbnail,
        this.tagItem,
        this.operationContext.selectedImage["canvasWidth"],
        this.operationContext.selectedImage["canvasHeight"]
      ).then((id) => {
        this.lookup(id);
      });
    } else {
      this.lookup(this.tagItem.prd.id);
    }
  }

  ngAfterViewInit() {
    this.resize();
    this.init = true;

    if (this.tagItem.isModified) {
      this.focus();
    }
  }

  clone(productItems: ProductItem[]) {
    let newList: ProductItem[] = [];

    productItems.map((item) => {
      newList.push(item.clone());
    });

    return newList;
  }

  lookup(id) {
    this.isLoading = false;
    this.getProductDetailsPIA(id, async (result) => {
      let name = this.operationContext.selectedImage.name;

      if (name.includes("-crop")) {
        let parts = name.split("-crop");
        name = parts[0];
      }
      if (result.length > 0) {
        let resultItem = result[0];
        let product = this.suggestions.find((productItem) => {
          return productItem.id == resultItem.id;
        });

        if (product !== undefined) {
          product.selected = true;
          this.tagItem.prd = product;
        } else {
          let aIProduct = this.tagItem.identifiedProducts.find(
            (productItem) => {
              return productItem.id == resultItem.id;
            }
          );

          if (aIProduct !== undefined) {
            aIProduct.selected = true;
            this.tagItem.prd = aIProduct;
          } else {
            resultItem.selected = true;
            this.tagItem.prd = resultItem;
            this.tagItem.identifiedProducts.push(resultItem);
          }
        }
      }
    });
  }

  async identifyTag(url, tag, width, height) {
    let rect = tag.box.boundingBox();
    let left = rect.left - PADDING < 0 ? 0 : (rect.left - PADDING) / width;
    let top = rect.top - PADDING < 0 ? 0 : (rect.top - PADDING) / height;
    let right =
      rect.left + rect.width + PADDING * 2 > width
        ? 1.0
        : (rect.left + rect.width + PADDING * 2) / width;
    let bottom =
      rect.top + rect.height + PADDING * 2 > height
        ? 1.0
        : (rect.top + rect.height + PADDING * 2) / height;

    let box = {
      left: left.toFixed(3),
      top: top.toFixed(3),
      right: right.toFixed(3),
      bottom: bottom.toFixed(3),
    };

    return this.dataService
      .identify(url, [box])
      .then(async (items) => {
        let id = tag.prd.id;

        if (!id) {
          let product = ProductItem.create({
            name: "PLEASE SELECT",
            description: "No selection has been made",
            id: "",
            imageUrl: "",
            score: 0,
            selected: true,
          });

          tag.prd = product;
          id = tag.prd.id;
        }

        if (tag.identifiedProducts.length == 0) {
          let identifiedProducts = items[0].identifiedResult;

          identifiedProducts.map((item) => {
            const globalId = `${(item.articleType as string).toLowerCase()},${
              item.itemNoGlobal
            }`;

            this.getProductDetailsPIA(globalId, (result) => {
              if (result.length > 0) {
                let productItem = result[0];
                productItem.score = item.score;

                let suggestion = this.suggestions.find((aiItem) => {
                  return aiItem.id == item.art;
                });
                if (suggestion) {
                  suggestion.score = productItem.score;
                }

                this.insertSorted(
                  tag.identifiedProducts,
                  productItem,
                  (a, b) => {
                    return b.score - a.score;
                  }
                );
              }
            });
          });
        }
        return id;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getScore(distance) {
    let x = distance * 10;
    return Math.min(10, Math.max(Math.round(x / 0.5) * 0.5, 0));
  }

  insertSorted(array, element, comparator) {
    for (
      var i = 0;
      i < array.length && comparator(array[i], element) < 0;
      i++
    ) {}
    array.splice(i, 0, element);
  }

  isSelected(option) {
    return option.selected;
  }

  resize() {
    setTimeout(() => {
      this.width = this.input.nativeElement.offsetWidth;
    }, 0);
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    if (
      this.suggestions.length === 0 &&
      this.tagItem.identifiedProducts.length === 0
    ) {
      return 0;
    }

    if (this.suggestions.length + this.tagItem.identifiedProducts.length > 3) {
      return HEADER_HEIGHT + MAX_HEIGHT;
    }

    return (
      HEADER_HEIGHT +
      ITEM_HEIGHT *
        (this.suggestions.length || this.tagItem.identifiedProducts.length) +
      2
    );
  }

  getMarginTop() {
    if (
      this.formControl.nativeElement.offsetTop +
        this.getHeight() +
        HEADER_HEIGHT >
      window.innerHeight
    ) {
      return -411;
    }

    return -15;
  }

  search(param, callback) {
    if (callback === undefined) {
      return;
    }

    if (callback) {
      this.operationContext
        .searchSPR(param)
        .then(callback)
        .catch((exc) => {
          console.log("searchSPR", exc);
        });
    }
  }

  getProductDetailsPIA(param, callback) {
    if (callback === undefined) {
      return;
    }

    if (callback) {
      this.operationContext
        .searchPIA(param)
        .then(callback)
        .catch((exc) => {
          console.log("searchPIA", exc);
          this.isLoading = false;
        });
    }
  }

  focus() {
    if (!this.init) {
      return;
    }

    setTimeout(() => {
      this.input.nativeElement.focus({
        preventScroll: true,
      });
    }, 0);
  }

  getProducts(text) {
    if (text == "") {
      this.tagItem.prd = new ProductItem("", "", "", "", "", 0, false);
      this.tagItem.isModified = true;

      let name = this.operationContext.selectedImage.name;

      if (name.includes("-crop")) {
        let parts = name.split("-crop");
        name = parts[0];
      }

      this.title = "Suggested products";
      this.suggestions = this.clone(this.options);
      return;
    }

    let sanitized = this.sanitizer.sanitize(SecurityContext.HTML, text);

    this.search(sanitized, (result) => {
      this.title = "Search results";
      this.suggestions = result;
      this.isOpen = true;
    });
  }

  getImage(product: ProductItem) {
    return product.thumbnail;
  }

  select(item) {
    this.tagItem.prd.selected = false;
    this.tagItem.prd = item;
    this.tagItem.isModified = true;
    this.tagItem.prd.selected = true;
    this.isOpen = false;
  }

  hasItems(): boolean {
    return (
      (this.suggestions && this.suggestions.length > 0) ||
      (this.tagItem.identifiedProducts &&
        this.tagItem.identifiedProducts.length > 0)
    );
  }

  toggle(event) {
    this.isOpen = !this.isOpen;

    let dropdown = event.currentTarget.querySelector(".dropdown-container");

    if (dropdown === undefined || !dropdown) {
      return;
    }

    let selected = this.suggestions.find((productItem) => {
      return productItem.selected;
    });

    if (selected) {
      let idx = this.suggestions.indexOf(selected);
      dropdown.scrollTop = idx * ITEM_HEIGHT;
    } else {
      selected = this.tagItem.identifiedProducts.find((productItem) => {
        return productItem.selected;
      });

      if (selected) {
        let idx = this.tagItem.identifiedProducts.indexOf(selected);
        dropdown.scrollTop =
          this.options.length * ITEM_HEIGHT + HEADER_HEIGHT + idx * ITEM_HEIGHT;
      }
    }
  }
}
