var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Rect } from "../models/renderable/rect";
import { MemoryContext } from "./memory.context";
import { JsonConverter } from "./json.converter";
import { ImageMetadata } from "./image.metadata";
import { ErrorMessage } from "../models/error.message";
import { Histogram } from "./histogram";
import Fraction from "fraction.js";
var ImageHelper = /** @class */ (function () {
    function ImageHelper() {
    }
    /**
     * Download an image and return as base64
     * @param url
     */
    ImageHelper.download = function (url) {
        return new Promise(function (resolve, reject) {
            fetch(url)
                .then(function (res) {
                return __awaiter(this, void 0, void 0, function () {
                    var blob, srcURL, reader;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, res.blob()];
                            case 1:
                                blob = _a.sent();
                                srcURL = URL.createObjectURL(blob);
                                reader = new FileReader();
                                reader.onload = function () {
                                    return resolve({
                                        srcURL: srcURL,
                                        buffer: new Uint8Array(reader.result),
                                    });
                                };
                                reader.readAsArrayBuffer(blob);
                                return [2 /*return*/];
                        }
                    });
                });
            })
                .catch(function (error) {
                return reject(new ErrorMessage(ErrorMessage.ERROR, "", error));
            });
        });
    };
    /**
     *
     * @param base64URL
     */
    ImageHelper.createMemoryContext = function (data) {
        return new Promise(function (resolve, reject) {
            var img = new Image();
            img.onload = function (event) {
                var mem = new MemoryContext(img.width, img.height);
                var metadata = new ImageMetadata(data.buffer);
                mem.metadata = metadata.extractMetadata();
                mem.canvas.width = img.width;
                mem.canvas.height = img.height;
                mem.context.clearRect(0, 0, mem.canvas.width, mem.canvas.height);
                mem.context.drawImage(img, 0, 0, mem.canvas.width, mem.canvas.height);
                URL.revokeObjectURL(data.srcURL);
                return resolve(mem);
            };
            img.onerror = function (event) {
                URL.revokeObjectURL(data.srcURL);
                return reject(new ErrorMessage(ErrorMessage.ERROR, "Error creating memorycontext", new Error("").stack));
            };
            img.src = data.srcURL;
        });
    };
    /**
     *
     * @param base64URL
     * @param memoryContext
     */
    ImageHelper.insertMetadata = function (base64URL, memoryContext) {
        return new Promise(function (resolve, reject) {
            fetch(base64URL)
                .then(function (response) {
                return response.arrayBuffer();
            })
                .then(function (buffer) {
                var imageMeta = new ImageMetadata(new Uint8Array(buffer));
                var array = imageMeta.insertMetadata(memoryContext.metadata);
                var blob = new Blob([array], { type: "image/jpeg" });
                var reader = new FileReader();
                //ImageHelper.save("newimage.jpg", base64URLwMeta);
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    console.log("done");
                    resolve(reader.result);
                };
            })
                .catch(function (error) {
                reject(new ErrorMessage(ErrorMessage.ERROR, "", error));
            });
        });
    };
    /**
     * Loop through all tags of an image and generate a thumbnail
     * @param memoryContext
     * @param image
     */
    ImageHelper.generateThumbnails = function (memoryContext, image) {
        if (!memoryContext) {
            throw new Error("MemoryContext is not valid");
        }
        var result = [];
        var height = image["canvasHeight"];
        var scale = memoryContext.canvas.height / height;
        image.imageTags.map(function (imageTag) {
            var rect = imageTag.box.boundingBox();
            var scaledRect = new Rect(rect.left * scale, rect.top * scale, rect.width * scale, rect.height * scale);
            var context = memoryContext.clone();
            var maxDim = 512;
            if (rect.width > rect.height) {
                context.canvas.width = maxDim; //rect.width * scale;
                context.canvas.height = (rect.height / rect.width) * maxDim; // rect.height * scale;
            }
            else {
                context.canvas.height = maxDim;
                context.canvas.width = (rect.width / rect.height) * maxDim;
            }
            // context.canvas.width = rect.width * scale;
            // context.canvas.height = rect.height * scale;
            context.drawImage(memoryContext.canvas, scaledRect.left, scaledRect.top, scaledRect.width, scaledRect.height, 0, 0, context.canvas.width, context.canvas.height);
            var base64 = context.canvas.toDataURL("image/jpeg", 0.96);
            result.push(base64);
        });
        return result;
    };
    /**
     * Finds closest full pixel crop smaller than or equal to the 'crop' parameter.
     *
     * @param crop raw scaled crop
     * @param ratio aspect ratio choosen by the user
     * @param origWidth original image width
     * @param origHeight original image height
     */
    ImageHelper.createAlignedCrop = function (crop, ratio, origWidth, origHeight) {
        var left = Math.floor(crop.left);
        var top = Math.floor(crop.top);
        var width = Math.floor(crop.width);
        var height = Math.floor(crop.height);
        var ratioFraction = ratio === "free" ? new Fraction(-1) : new Fraction(ratio);
        // check how much width is misaligned
        var widthOffset = width % ratioFraction.n;
        // helper to calculate height
        var calcHeight = function (w, f) { return f.inverse().mul(w).valueOf(); };
        // only check alignment with aspect ratio if not free form
        if (ratio !== "free") {
            if (widthOffset !== 0) {
                /**
                 * example 3:4
                 * x = width
                 * y = height
                 *
                 * x / y = 3 / 4
                 * line => y = 4 / 3 * x
                 *
                 */
                // aligned width
                width = width - widthOffset;
                // calculate new height
                height = calcHeight(width, ratioFraction);
            }
        }
        // check if we are inside the bounds of the original image
        // and reduce by numerator steps
        while (width > origWidth || height > origHeight) {
            width = width - ratioFraction.n;
            height = calcHeight(width, ratioFraction);
            if (width < 0 || height < 0) {
                console.warn("Invalid width or height reduction");
                return crop;
            }
        }
        while (left + width > origWidth) {
            left = left - 1;
            if (left < 0) {
                console.warn("Invalid width + left offset");
                return crop;
            }
        }
        while (top + height > origHeight) {
            top = top - 1;
            if (top < 0) {
                console.warn("Invalid top + height offset");
                return crop;
            }
        }
        return new Rect(left, top, width, height);
    };
    /**
     * Generate a crop and convert the coordinate of all dots in the cropping region
     * @param memoryContext
     * @param image
     * @param cropItem
     */
    ImageHelper.generateCrop = function (memoryContext, image, cropItem, croppedBy, collections, key) {
        return __awaiter(this, void 0, void 0, function () {
            var height, rect, scale, scaledRect, tags, context, base64URL, jsonTags, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!memoryContext) {
                            throw new Error("MemoryContext is not valid");
                        }
                        height = image["canvasHeight"];
                        rect = cropItem.boundingBox();
                        scale = memoryContext.canvas.height / height;
                        scaledRect = new Rect(rect.left * scale, rect.top * scale, rect.width * scale, rect.height * scale);
                        scaledRect = this.createAlignedCrop(scaledRect, cropItem.ratio, memoryContext.canvas.width, memoryContext.canvas.height);
                        tags = [];
                        context = memoryContext.clone();
                        context.canvas.width = scaledRect.width;
                        context.canvas.height = scaledRect.height;
                        context.drawImage(memoryContext.canvas, scaledRect.left, scaledRect.top, scaledRect.width, scaledRect.height, 0, 0, scaledRect.width, scaledRect.height);
                        base64URL = context.canvas.toDataURL("image/jpeg", 1);
                        if (!(memoryContext.metadata.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, ImageHelper.insertMetadata(base64URL, memoryContext)];
                    case 1:
                        base64URL = _a.sent();
                        _a.label = 2;
                    case 2:
                        image.imageTags.map(function (imageTag) {
                            if (cropItem.contains(imageTag.dot.location) && imageTag.isVisible) {
                                var clone = imageTag.clone();
                                var boxRect = clone.box.boundingBox();
                                var dotPos = clone.dot.location;
                                clone.box.updateDimension(boxRect.left - rect.left, boxRect.top - rect.top, boxRect.width, boxRect.height);
                                clone.dot.updatePosition(dotPos.x - rect.left, dotPos.y - rect.top);
                                tags.push(clone);
                            }
                        });
                        jsonTags = JsonConverter.toJSON(tags, {
                            width: rect.width,
                            height: rect.height,
                        });
                        json = {
                            img: base64URL,
                            data: {
                                key: key,
                                collections: collections,
                                extraInfo: {
                                    cropRegion: scaledRect.toString(),
                                    croppedBy: croppedBy,
                                    original: image.id,
                                    originalName: image.name,
                                },
                                colorInfo: this.getHistogram(context),
                                tags: jsonTags,
                            },
                        };
                        return [2 /*return*/, json];
                }
            });
        });
    };
    ImageHelper.getHistogram = function (context) {
        var histogram = new Histogram(context);
        return histogram.create();
    };
    return ImageHelper;
}());
export { ImageHelper };
