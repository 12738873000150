<div title="Select collections" (click)="toggle($event);">
  <mat-icon class="icon" >playlist_add_check</mat-icon>
  <mat-icon *ngIf="isOpen()" class="icon">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!isOpen()" class="icon">arrow_drop_down</mat-icon>
  <div *ngIf="hasItems(); else noItems" (click)="$event.stopPropagation()" [style.height.px]="getHeight()" class="dropdown-container height" [ngClass]="isOpen() ? 'open' : 'not-open'">
    <div *ngFor="let collection of collections; let i= index" [attr.tabindex]='i'
      [title]="collection.description" class="container" (click)="toggleCheck(collection)">
      <mat-icon *ngIf="collection.selected">check_box</mat-icon>
      <mat-icon *ngIf="!collection.selected">check_box_outline_blank</mat-icon>
      <div class="inspirationImage" [ngStyle]="{'background-image': 'url(' + getImage(collection) + ')'}"></div>
      <div style="padding: 10px;">
        <h6>{{ collection.name }}</h6>
        <span>{{ collection.description }}</span>
      </div>
      <mat-icon class="done" *ngIf="isCurrent(collection)">done</mat-icon>
    </div>
  </div>
</div>

<ng-template #noItems>
  <div class="dropdown-container height images-center" [ngClass]="isOpen() ? 'open' : 'not-open'" style="padding: 10px">
  <h6>No collections found</h6>
  </div>
</ng-template>

